/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: Date | string; output: string; }
  Duration: { input: string; output: string; }
  JSON: { input: any; output: any; }
  SemVer: { input: string; output: string; }
  Upload: { input: unknown; output: unknown; }
};

export type AcceptInviteInput = {
  email: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  invite: Scalars['String']['input'];
};

export type AddClientInput = {
  email: Scalars['String']['input'];
};

export type Address = WithDates & WithId & {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  location?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AddressInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type AnswerInput = {
  answer: Scalars['JSON']['input'];
  consultationId?: InputMaybe<Scalars['Int']['input']>;
  questionId: Scalars['Int']['input'];
};

export type Avatar = {
  __typename?: 'Avatar';
  url?: Maybe<Scalars['String']['output']>;
};

/** This is the type for barcharts. */
export type BarchartItem = {
  __typename?: 'BarchartItem';
  /** This is the key for the key-value pair. */
  name: Scalars['String']['output'];
  /** This is the value for the key-value pair. */
  value: Scalars['Int']['output'];
};

export type BillingInformation = WithDates & WithId & {
  __typename?: 'BillingInformation';
  accountNumber: Scalars['String']['output'];
  address: Address;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type BillingInformationInput = {
  accountNumber: Scalars['String']['input'];
  address: AddressInput;
  notificationEmail?: InputMaybe<Scalars['String']['input']>;
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  allDay: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  scheduledFrom: Scalars['DateTime']['output'];
  scheduledTo: Scalars['DateTime']['output'];
  type: CalendarEventTypes;
};

export type CalendarEventInput = {
  allDay: Scalars['Boolean']['input'];
  returnAsFullCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledFrom: Scalars['DateTime']['input'];
  scheduledTo: Scalars['DateTime']['input'];
  type: Scalars['String']['input'];
};

export type CalendarEventTypes =
  | 'availability'
  | 'consultation_appointment'
  | 'consultation_intro'
  | 'online'
  | 'unknown_type';

export type CalendarFormats = CalendarEvent | FullCalendarEvent;

export type CancelConsultationInput = {
  /** The id of the consultation to be cancelled */
  id: Scalars['Int']['input'];
  /** A message for the other party in the consultation */
  message: Scalars['String']['input'];
};

export type CashTransaction = {
  __typename?: 'CashTransaction';
  amount: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  mollieTransaction?: Maybe<Scalars['String']['output']>;
  status: CashTransactionStatusType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** These are the statuses from Mollie (https://docs.mollie.com/payments/status-changes) with the addition of 'new' which we use for fresh transactions. */
export type CashTransactionStatusType =
  | 'authorized'
  | 'canceled'
  | 'expired'
  | 'failed'
  | 'new'
  | 'open'
  | 'paid'
  | 'pending'
  | 'refunded';

export type ChimeAttendeeInfo = {
  __typename?: 'ChimeAttendeeInfo';
  AttendeeId: Scalars['String']['output'];
  ExternalUserId: Scalars['String']['output'];
  JoinToken: Scalars['String']['output'];
};

export type ChimeMediaPlacement = {
  __typename?: 'ChimeMediaPlacement';
  AudioFallbackUrl: Scalars['String']['output'];
  AudioHostUrl: Scalars['String']['output'];
  ScreenDataUrl: Scalars['String']['output'];
  ScreenSharingUrl: Scalars['String']['output'];
  ScreenViewingUrl: Scalars['String']['output'];
  SignalingUrl: Scalars['String']['output'];
};

export type ChimeMeetingInfo = {
  __typename?: 'ChimeMeetingInfo';
  ExternalMeetingId?: Maybe<Scalars['String']['output']>;
  MediaPlacement: ChimeMediaPlacement;
  MediaRegion: Scalars['String']['output'];
  MeetingId: Scalars['String']['output'];
};

export type ChoosePasswordV2Input = {
  /** A user's e-mail address */
  email: Scalars['String']['input'];
  /** The value the password should be set to. */
  newPassword: Scalars['String']['input'];
  /** A token that was generated by the resetPasswordV2 mutation and email */
  resetToken: Scalars['String']['input'];
};

export type Client = {
  __typename?: 'Client';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  human?: Maybe<Human>;
  humanId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inviteToken?: Maybe<Scalars['String']['output']>;
  professional?: Maybe<Professional>;
  professionalId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ClientStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ClientEdge = {
  __typename?: 'ClientEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<ClientFormats>;
};

export type ClientFormats = Client | RestrictedClient;

export type ClientStatus =
  | 'CURRENT'
  | 'DECLINED'
  | 'INVITED'
  | 'ONLY_INTRO'
  | 'PAST'
  | 'PENDING';

export type ClientsConnection = {
  __typename?: 'ClientsConnection';
  edges?: Maybe<Array<Maybe<ClientEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ConfirmConsultationInput = {
  email: Scalars['String']['input'];
  uuid: Scalars['String']['input'];
};

export type Consultation = {
  __typename?: 'Consultation';
  acceptedByHuman?: Maybe<Scalars['Boolean']['output']>;
  acceptedByProfessional?: Maybe<Scalars['Boolean']['output']>;
  bloomupFee?: Maybe<Scalars['Float']['output']>;
  cashTransaction?: Maybe<CashTransaction>;
  createdAt: Scalars['Date']['output'];
  creditTransactions: Array<CreditTransaction>;
  duration: Scalars['Duration']['output'];
  expectedReimbursementDate?: Maybe<Scalars['Date']['output']>;
  fromMatching: Scalars['Boolean']['output'];
  human?: Maybe<Human>;
  humanId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  message?: Maybe<Message>;
  professional?: Maybe<Professional>;
  professionalFee?: Maybe<Scalars['Float']['output']>;
  professionalId?: Maybe<Scalars['Int']['output']>;
  requestedBy?: Maybe<RequestedByType>;
  scheduledFrom: Scalars['DateTime']['output'];
  scheduledTo: Scalars['DateTime']['output'];
  status: ConsultationStatus;
  type?: Maybe<ConsultationType>;
  updatedAt: Scalars['Date']['output'];
  userRequested: Scalars['Boolean']['output'];
  uuid: Scalars['String']['output'];
};

export type ConsultationEdge = {
  __typename?: 'ConsultationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Consultation;
};

export type ConsultationFilter = {
  status?: InputMaybe<Array<ConsultationStatus>>;
};

export type ConsultationStatus =
  | 'ACCEPTED'
  | 'CANCELLED'
  | 'CANCELLED_BY_HUMAN'
  | 'CANCELLED_BY_PROFESSIONAL'
  | 'DONE'
  | 'IN_PROGRESS'
  | 'NO_SHOW_HUMAN'
  | 'NO_SHOW_PROFESSIONAL'
  | 'REQUESTED'
  | 'WAITING';

export type ConsultationType =
  | 'APPOINTMENT'
  | 'CHECKIN'
  | 'INTRO';

export type ConsultationsConnection = {
  __typename?: 'ConsultationsConnection';
  edges: Array<ConsultationEdge>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ContinueTrackInput = {
  id: Scalars['String']['input'];
  /**
   * When provided will link the current user to the provided professional for the track.
   * Should be part of the Input for the frist checkin node, but for now is here and optional.
   */
  professionalId?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  values?: InputMaybe<Scalars['JSON']['input']>;
};

export type CopyCalendarEventsInput = {
  copyTo: Scalars['DateTime']['input'];
  from: Scalars['DateTime']['input'];
  onlyType?: InputMaybe<Scalars['String']['input']>;
  returnAsFullCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  to: Scalars['DateTime']['input'];
};

export type CreateConsultationInput = {
  fromMatching: Scalars['Boolean']['input'];
  matchedHumanId?: InputMaybe<Scalars['Int']['input']>;
  otherPartyId: Scalars['Int']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  scheduledFrom: Scalars['DateTime']['input'];
  scheduledTo: Scalars['DateTime']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
  type: ConsultationType;
};

export type CreditTransaction = {
  __typename?: 'CreditTransaction';
  amount: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
};

export type DeleteCalendarEventsInput = {
  ids: Array<InputMaybe<Scalars['Int']['input']>>;
};

/** This is the type for well being gauge charts. */
export type DifferenceGaugeResult = {
  __typename?: 'DifferenceGaugeResult';
  /** This is the average first score. */
  averageFirstScore: Scalars['Float']['output'];
  /** This is the average improvement. */
  averageImprovement: Scalars['Float']['output'];
  /** This is the average latest score. */
  averageLatestScore: Scalars['Float']['output'];
};

export type DistributeListNodeContent = {
  __typename?: 'DistributeListNodeContent';
  amountToDistribute: Scalars['Int']['output'];
  body: MultiLanguageString;
  buttonType: NodeButtonType;
  headerImage?: Maybe<MultiLanguageString>;
  id: Scalars['String']['output'];
  options: Array<NodeOption>;
  progress: Scalars['Float']['output'];
};

export type Enable2FaInput = {
  secret: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type EndOfTrackNodeContent = {
  __typename?: 'EndOfTrackNodeContent';
  id: Scalars['String']['output'];
};

export type EntryPoints =
  | 'after_consultation'
  | 'after_intro'
  | 'after_last_planned_consultation'
  | 'before_consultation'
  | 'during_self_help'
  | 'end_of_treatment'
  | 'in_app_notification';

export type EntryPointsInput = {
  entrypoint: EntryPoints;
};

export type ExtendedProps = {
  __typename?: 'ExtendedProps';
  status?: Maybe<Scalars['String']['output']>;
  type: CalendarEventTypes;
};

export type FullCalendarEvent = {
  __typename?: 'FullCalendarEvent';
  allDay: Scalars['Boolean']['output'];
  end: Scalars['DateTime']['output'];
  extendedProps: ExtendedProps;
  id: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

/** This is the type for funnelcharts. */
export type FunnelItem = {
  __typename?: 'FunnelItem';
  /** This is the key for the key-value pair. */
  name: Scalars['String']['output'];
  /** This is the value for the key-value pair. */
  value: Scalars['Int']['output'];
};

export type GrowthStep = {
  __typename?: 'GrowthStep';
  id: Scalars['String']['output'];
  title: MultiLanguageString;
};

/** The persona of an individual human/client on the platform. Reference attached user for persona agnostic fields */
export type Human = WithId & {
  __typename?: 'Human';
  address?: Maybe<Address>;
  /** @deprecated Prefer to access on user object */
  age?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Prefer to access on user object */
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * If this human can pay for their consultations with credits this is true. e.g. active org contract for videos
   * When false it means the user has to pay for scheduled consultations themselves.
   */
  canPayWithCredits: Scalars['Boolean']['output'];
  contactForFeedback: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  /**
   * If the user is attached to a organization that specifies a maximum credits per human value, this returns the remaining amount for this user in the contract period.
   * Returns null when this value doesn't exist e.g. no org, no current contract, current contract doesn't limit individual usage
   */
  creditsLeft?: Maybe<Scalars['Float']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Prefer to access on user object */
  email: Scalars['String']['output'];
  /** @deprecated Prefer to access on user object */
  firstName?: Maybe<Scalars['String']['output']>;
  healthInsurance?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** @deprecated Prefer to access on user object */
  lastName?: Maybe<Scalars['String']['output']>;
  onboardedAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   * The organization this human is a member of.
   * This will return null if the user is not linked to an organization with a current contract.
   */
  organization?: Maybe<Organization>;
  /** @deprecated Prefer to access on the organization object */
  organizationId?: Maybe<Scalars['Int']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  /** @deprecated Prefer to access on user object */
  phone?: Maybe<Scalars['String']['output']>;
  /**
   * The preferred language for this user. He / she will see all text presented in this language.
   * This is also the language that will be the default language used in consultations.
   * @deprecated Prefer to access on user object
   */
  preferredLanguage: Language;
  preferredProfessionalGender?: Maybe<Scalars['String']['output']>;
  /** @deprecated Prefer to access on user object */
  timezone: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user account for this human */
  user: User;
  /** @deprecated Prefer to access on user object */
  uuid: Scalars['String']['output'];
};

export type HumanInput = {
  address?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  healthInsurance?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  onboardedAt?: InputMaybe<Scalars['DateTime']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  /** The gender of a professional that a Human prefers to work with. */
  preferredProfessionalGender?: InputMaybe<Scalars['String']['input']>;
  /** Professionals that are linked to this Human. */
  professionals?: InputMaybe<Array<Scalars['Int']['input']>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type HumanWithTokens = {
  __typename?: 'HumanWithTokens';
  accessToken: Scalars['String']['output'];
  human?: Maybe<Human>;
  refreshToken: Scalars['String']['output'];
};

export type Language =
  | 'en'
  | 'frBe'
  | 'nl'
  | 'nlBe';

/** This is the type for line graphs. */
export type LineGraphItem = {
  __typename?: 'LineGraphItem';
  /** This is the x value for the line chart. */
  x: Scalars['String']['output'];
  /** This is the y value for the line chart. */
  y: Scalars['Int']['output'];
};

export type LoginFailureReason =
  | 'INCORRECT_2FA'
  | 'INCORRECT_CREDENTIALS'
  | 'NEEDS_2FA';

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  /**
   * the accessToken is a JWT that contains the role_type and user_id (UUID) of the user, if login succeeds.
   * The user_id can be used to fetch the associated Professional / Patient.
   */
  accessToken: Scalars['String']['output'];
  /**
   * the refreshToken is a JWT that can be used to refresh the accessToken if it expires
   * without having to login again.
   */
  refreshToken: Scalars['String']['output'];
};

export type LoginV2FailureResponse = {
  __typename?: 'LoginV2FailureResponse';
  /** The reason here is a value from an enum that indicates the reason why why the login failed. */
  reason: LoginFailureReason;
};

export type LoginV2Input = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token2FA?: InputMaybe<Scalars['String']['input']>;
};

export type LoginV2Response = LoginV2FailureResponse | TokenResponse;

export type LoginWithSsoInput = {
  authorizationCode: Scalars['String']['input'];
  mindlabBusinessId?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type Match = {
  __typename?: 'Match';
  availability: Array<Maybe<Scalars['String']['output']>>;
  isOnline: Scalars['Boolean']['output'];
  professional: Professional;
  score: Scalars['Int']['output'];
};

export type MatchedHuman = {
  __typename?: 'MatchedHuman';
  age?: Maybe<Scalars['Int']['output']>;
  chosenProfessionalId?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  preferredProfessionalGender?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Date']['output'];
  userUuid?: Maybe<Scalars['String']['output']>;
  /** @deprecated We dont use this anymore, since april 2021 */
  worries?: Maybe<Scalars['String']['output']>;
};

export type MatchedHumanInput = {
  age?: InputMaybe<Scalars['Int']['input']>;
  chosenProfessionalId?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  preferredProfessionalGender?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export type MatchedHumanWorriesInput = {
  /** Specialties should be an array of uuids of SpecialtiesThemeCombo's */
  specialtiesThemeComboIds: Array<Scalars['String']['input']>;
};

export type Matches = {
  __typename?: 'Matches';
  matches?: Maybe<Array<Match>>;
};

export type Message = {
  __typename?: 'Message';
  body?: Maybe<Scalars['String']['output']>;
  consultationId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fromProfessional?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MessageInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  fromProfessional?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MetabaseDashboard = {
  __typename?: 'MetabaseDashboard';
  dashboardId?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type MobileStatus =
  | 'FORCE_UPDATE'
  | 'SUPPORTED'
  | 'WARN_UPDATE';

export type MultiLanguageBoolean = {
  __typename?: 'MultiLanguageBoolean';
  en: Scalars['Boolean']['output'];
  fr: Scalars['Boolean']['output'];
  nl: Scalars['Boolean']['output'];
};

export type MultiLanguageString = {
  __typename?: 'MultiLanguageString';
  en?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<Scalars['String']['output']>;
  nl?: Maybe<Scalars['String']['output']>;
};

/** MUTATIONS */
export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<Client>;
  answerQuestion?: Maybe<Scalars['Boolean']['output']>;
  /** Initialises the track for the currently signed in user. */
  beginTrack: Track;
  /** Cancels the specified consultation and notififies relevant parties */
  cancelConsultation: Consultation;
  /** Sets a new password for an existing user based off email and token, returns true when password was set successfully */
  choosePasswordV2: Scalars['Boolean']['output'];
  /**
   * Registers more info for a new Human and returns the new entity. For this to happen,
   * The user should have logged in (so a token is present)
   */
  completeHumanRegistration?: Maybe<Human>;
  confirmConsultation?: Maybe<Consultation>;
  copyCalendarEvents?: Maybe<Array<Maybe<CalendarFormats>>>;
  createCalendarEvent?: Maybe<CalendarFormats>;
  createConsultation?: Maybe<Consultation>;
  createPermanentHuman?: Maybe<LoginResponse>;
  deleteCalendarEvents?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Mutation to enable 2FA. Input should be a secret and a token. The response will be a boolean indicating whether te enabling succeeded. */
  enable2FA: Scalars['Boolean']['output'];
  inviteClient?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Adds the currently logged in user to the organization specified by the input email address, or code.
   * Uses the same matching rules as the `organizationByEmailOrCode` query
   */
  joinOrganizationByEmailOrCode?: Maybe<User>;
  joinOrganizationByEmailOrCodeV2?: Maybe<Scalars['Boolean']['output']>;
  /** Query to handle a login. Input should be a user's email and password, the response will be a JSON Web Token */
  login: LoginResponse;
  /** Mutation to handle a login. Input should be a user's email, password and 2fa token, the response will either be a jwt token or a failure code */
  loginV2: LoginV2Response;
  /** Mutation to log a user in using an SSO authorization code */
  loginWithSSO: LoginV2Response;
  notifyOtherParty?: Maybe<Scalars['Boolean']['output']>;
  payForConsultation?: Maybe<Scalars['String']['output']>;
  /** Query to refresh a user's accessToken. Input should be the user's refreshToken. Response will be a new accessToken */
  refreshAccessToken: Scalars['String']['output'];
  /** Query to refresh a user's tokens. Input should be the user's refreshToken. Response will be a new accessToken, new refreshToken and new mindLabToken */
  refreshAccessTokenV2: TokenResponse;
  /** Creates a new Human and returns access tokens for the associated user. */
  registerHuman: LoginResponse;
  /** Creates a new Human and returns access tokens for the associated user. */
  registerHumanV2: TokenResponse;
  /**
   * Registers more info for a new Human and returns the new entity. For this to happen,
   * the user needs to be invited by a professional, and should have an invite token present in the
   * url.
   */
  registerHumanWithInvite?: Maybe<HumanWithTokens>;
  /** Registers a Professional */
  registerProfessional?: Maybe<Scalars['Boolean']['output']>;
  /** Mutation to reauest the secrets to set up 2FA. Input should be a secret and a qrcode. The response are the secrets needed to setup 2fa. */
  request2FASecrets: Secrets2Fa;
  /** Creates reimbursement information */
  requestReimbursement?: Maybe<Reimbursement>;
  rescheduleConsultation: Consultation;
  /** Mutation to reset a user's password. Input should be a user's email. The response is a boolean indicating whether the reset process has been started. */
  resetPassword: Scalars['Boolean']['output'];
  /** Sends an email with links to the app stores, and returns true on success. */
  sendAppInvite: Scalars['Boolean']['output'];
  /** Updates the language for the currently logged in user. Will only work when authenticated. */
  setCurrentUserLanguage: User;
  startMatching: StartMatchingResponse;
  startVideoCall?: Maybe<VideoCall>;
  /** Submits the current node of the track, and returns the NodeContent for the next node. */
  submitNodeV2: TypedNodeContent;
  updateCalendarEvent?: Maybe<CalendarFormats>;
  updateConsultation?: Maybe<Consultation>;
  /** Updates a Human and returns the updated entity. */
  updateHuman?: Maybe<Human>;
  updateMatchedHuman?: Maybe<MatchedHuman>;
  updateMatchedHumanWorries?: Maybe<MatchedHuman>;
  /** Updates a Professional and returns the updated entity */
  updateProfessional?: Maybe<Professional>;
  /** Updates the specialties of a Professional and returns the adjusted professional. */
  updateProfessionalSpecialties?: Maybe<Professional>;
  userJoinedConsultation: Consultation;
  /** Sets a Professionals status to verified and returns the updated entity */
  verifyProfessional?: Maybe<Scalars['Boolean']['output']>;
};


/** MUTATIONS */
export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};


/** MUTATIONS */
export type MutationAnswerQuestionArgs = {
  input: AnswerInput;
};


/** MUTATIONS */
export type MutationBeginTrackArgs = {
  trackId: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationCancelConsultationArgs = {
  input: CancelConsultationInput;
};


/** MUTATIONS */
export type MutationChoosePasswordV2Args = {
  input: ChoosePasswordV2Input;
};


/** MUTATIONS */
export type MutationCompleteHumanRegistrationArgs = {
  input: HumanInput;
};


/** MUTATIONS */
export type MutationConfirmConsultationArgs = {
  input: ConfirmConsultationInput;
};


/** MUTATIONS */
export type MutationCopyCalendarEventsArgs = {
  input: CopyCalendarEventsInput;
};


/** MUTATIONS */
export type MutationCreateCalendarEventArgs = {
  input: CalendarEventInput;
};


/** MUTATIONS */
export type MutationCreateConsultationArgs = {
  input: CreateConsultationInput;
};


/** MUTATIONS */
export type MutationCreatePermanentHumanArgs = {
  input: PermanentHumanInput;
};


/** MUTATIONS */
export type MutationDeleteCalendarEventsArgs = {
  input: DeleteCalendarEventsInput;
};


/** MUTATIONS */
export type MutationEnable2FaArgs = {
  input: Enable2FaInput;
};


/** MUTATIONS */
export type MutationInviteClientArgs = {
  input: AddClientInput;
};


/** MUTATIONS */
export type MutationJoinOrganizationByEmailOrCodeArgs = {
  emailOrCode: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationJoinOrganizationByEmailOrCodeV2Args = {
  emailOrCode: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationLoginArgs = {
  credentials: LoginInput;
};


/** MUTATIONS */
export type MutationLoginV2Args = {
  input: LoginV2Input;
};


/** MUTATIONS */
export type MutationLoginWithSsoArgs = {
  input: LoginWithSsoInput;
};


/** MUTATIONS */
export type MutationNotifyOtherPartyArgs = {
  consultationId: Scalars['String']['input'];
  isProfessional: Scalars['Boolean']['input'];
};


/** MUTATIONS */
export type MutationPayForConsultationArgs = {
  input: PaymentInput;
};


/** MUTATIONS */
export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationRefreshAccessTokenV2Args = {
  refreshToken: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationRegisterHumanArgs = {
  input: RegisterHumanInput;
};


/** MUTATIONS */
export type MutationRegisterHumanV2Args = {
  input: RegisterHumanInput;
};


/** MUTATIONS */
export type MutationRegisterHumanWithInviteArgs = {
  input: HumanInput;
  inviteToken: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationRegisterProfessionalArgs = {
  input: ProfessionalRegistrationInput;
};


/** MUTATIONS */
export type MutationRequest2FaSecretsArgs = {
  password: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationRequestReimbursementArgs = {
  input: ReimbursementInput;
};


/** MUTATIONS */
export type MutationRescheduleConsultationArgs = {
  input: RescheduleConsultationInput;
};


/** MUTATIONS */
export type MutationResetPasswordArgs = {
  email: Scalars['String']['input'];
  fromMindlab?: InputMaybe<Scalars['Boolean']['input']>;
};


/** MUTATIONS */
export type MutationSendAppInviteArgs = {
  humanId: Scalars['Int']['input'];
};


/** MUTATIONS */
export type MutationSetCurrentUserLanguageArgs = {
  language: Language;
};


/** MUTATIONS */
export type MutationStartMatchingArgs = {
  input: StartMatchingInput;
};


/** MUTATIONS */
export type MutationStartVideoCallArgs = {
  consultationUuid: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationSubmitNodeV2Args = {
  input: ContinueTrackInput;
  trackId: Scalars['String']['input'];
};


/** MUTATIONS */
export type MutationUpdateCalendarEventArgs = {
  input: UpdateCalendarEventInput;
};


/** MUTATIONS */
export type MutationUpdateConsultationArgs = {
  input: UpdateConsultationInput;
};


/** MUTATIONS */
export type MutationUpdateHumanArgs = {
  input: HumanInput;
};


/** MUTATIONS */
export type MutationUpdateMatchedHumanArgs = {
  input: MatchedHumanInput;
};


/** MUTATIONS */
export type MutationUpdateMatchedHumanWorriesArgs = {
  input: MatchedHumanWorriesInput;
};


/** MUTATIONS */
export type MutationUpdateProfessionalArgs = {
  input: ProfessionalInput;
};


/** MUTATIONS */
export type MutationUpdateProfessionalSpecialtiesArgs = {
  input: ProfessionalSpecialtiesInput;
};


/** MUTATIONS */
export type MutationUserJoinedConsultationArgs = {
  consultationId: Scalars['Int']['input'];
};


/** MUTATIONS */
export type MutationVerifyProfessionalArgs = {
  id: Scalars['Int']['input'];
};

export type NodeApplicableQuestion = {
  __typename?: 'NodeApplicableQuestion';
  question: MultiLanguageString;
  slider: NodeSlider;
};

export type NodeButtonType =
  | 'continue'
  | 'start';

export type NodeCategoryQuestion = {
  __typename?: 'NodeCategoryQuestion';
  options: Array<NodeOption>;
  question: MultiLanguageString;
};

/**
 * The generic version of the content for a node.
 * New nodes should have their own types, removing the need for the `content` field here.
 */
export type NodeContent = {
  __typename?: 'NodeContent';
  content: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  professional?: Maybe<ProfessionalForTrack>;
  type: Scalars['String']['output'];
};

export type NodeOption = {
  __typename?: 'NodeOption';
  id: Scalars['String']['output'];
  text: MultiLanguageString;
};

export type NodeSituation = {
  __typename?: 'NodeSituation';
  id: Scalars['String']['output'];
  image: Scalars['String']['output'];
};

export type NodeSlider = {
  __typename?: 'NodeSlider';
  endLabel: MultiLanguageString;
  startLabel: MultiLanguageString;
  steps: Scalars['Int']['output'];
};

export type Notification = {
  body: Scalars['String']['input'];
  deep_link?: InputMaybe<Scalars['String']['input']>;
  icon?: InputMaybe<Scalars['String']['input']>;
  renotify?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
};

export type OptionListNodeContent = {
  __typename?: 'OptionListNodeContent';
  body: MultiLanguageString;
  buttonType: NodeButtonType;
  headerImage?: Maybe<MultiLanguageString>;
  id: Scalars['String']['output'];
  maxSelected?: Maybe<Scalars['Int']['output']>;
  minSelected: Scalars['Int']['output'];
  options: Array<NodeOption>;
  progress: Scalars['Float']['output'];
};

export type Organization = {
  __typename?: 'Organization';
  code?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  contractDashboardUrl?: Maybe<MultiLanguageString>;
  creditsPerHuman?: Maybe<Scalars['Int']['output']>;
  emailRegex?: Maybe<Scalars['String']['output']>;
  ggtDashboardUrl?: Maybe<MultiLanguageString>;
  ggtEnabled: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  interestDashboardUrl?: Maybe<MultiLanguageString>;
  logoUrl: MultiLanguageString;
  mentalWellbeingDashboardUrl?: Maybe<MultiLanguageString>;
  mindlabBusinessId?: Maybe<Scalars['String']['output']>;
  /** Whether this organization currently has access to mindlab */
  mindlabEnabled: Scalars['Boolean']['output'];
  name: MultiLanguageString;
  /** Markdown formatted information about this organization to show professionals */
  profile: MultiLanguageString;
  qualityDashboardUrl?: Maybe<MultiLanguageString>;
  slug: Scalars['String']['output'];
  ssoEnabled: Scalars['Boolean']['output'];
  usageDashboardUrl?: Maybe<MultiLanguageString>;
  videoEnabled: Scalars['Boolean']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentInput = {
  cashTransactionId: Scalars['Int']['input'];
};

export type PaywallNodeContent = {
  __typename?: 'PaywallNodeContent';
  id: Scalars['String']['output'];
};

export type PermanentHumanInput = {
  contactForFeedback: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  healthInsurance?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};

export type Practice = WithDates & WithId & {
  __typename?: 'Practice';
  address?: Maybe<Address>;
  chatTherapy?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  offlineTherapy?: Maybe<Scalars['Boolean']['output']>;
  onlineTherapy?: Maybe<Scalars['Boolean']['output']>;
  phoneTherapy?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PracticeInput = {
  address?: InputMaybe<AddressInput>;
  chatTherapy?: InputMaybe<Scalars['Boolean']['input']>;
  offlineTherapy?: InputMaybe<Scalars['Boolean']['input']>;
  onlineTherapy?: InputMaybe<Scalars['Boolean']['input']>;
  phoneTherapy?: InputMaybe<Scalars['Boolean']['input']>;
};

/** The persona of an individual professional on the platform. Reference attached user for persona agnostic fields */
export type Professional = WithId & {
  __typename?: 'Professional';
  /** @deprecated Prefer to access on user object */
  age?: Maybe<Scalars['Int']['output']>;
  availability?: Maybe<Array<Scalars['String']['output']>>;
  /** Link to an avatar image */
  avatar?: Maybe<Avatar>;
  billingInformation?: Maybe<BillingInformation>;
  /** @deprecated Prefer to access on user object */
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  clientStop?: Maybe<Scalars['Boolean']['output']>;
  /** Clients are humans that are linked to this professional */
  clients: ProfessionalHumanConnection;
  cmNumber?: Maybe<Scalars['String']['output']>;
  consultationLanguages: Array<Maybe<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Prefer to access on user object */
  email: Scalars['String']['output'];
  /** Experience of this professional in years. */
  experienceSince?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated Prefer to access on user object */
  firstName?: Maybe<Scalars['String']['output']>;
  forBgt: Scalars['Boolean']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** Whether or not this professional is a 'firstline' professional. */
  isFirstLine?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Prefer to access on user object */
  lastName?: Maybe<Scalars['String']['output']>;
  /**
   * Files that are linked to this professional
   * #TODO: Add File type
   */
  licenseFiles?: Maybe<Array<Maybe<UserFile>>>;
  onboardedAt?: Maybe<Scalars['DateTime']['output']>;
  organizationId?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Prefer to access on user object */
  phone?: Maybe<Scalars['String']['output']>;
  practice?: Maybe<Practice>;
  /**
   * The preferred language for this user. He / she will see all text presented in this language.
   * This is also the language that will be the default language used in consultations.
   * @deprecated Prefer to access on user object
   */
  preferredLanguage: Language;
  /**
   * Short text in which the professional describes him/herself. JSON string that must be parsed.
   * @deprecated Replaced by profileDescription
   */
  profile?: Maybe<Scalars['String']['output']>;
  /** Short text in which the professional describes him/herself. */
  profileDescription: MultiLanguageString;
  roleType?: Maybe<Scalars['String']['output']>;
  /** An array containing a professional's specialities. */
  specialties: Array<Maybe<SpecialtiesThemeCombo>>;
  /** Duration of one consultation in minutes. */
  standardDuration?: Maybe<Scalars['Int']['output']>;
  /**
   * # QUESTION: Do we define this in $/h, or $/consultation?
   * Rate a professional charges per hour.
   */
  standardRate?: Maybe<Scalars['Float']['output']>;
  /** Whether or not this professional offers supervision. */
  supervision?: Maybe<Scalars['Boolean']['output']>;
  /** Types of therapy that are offered by this professional. */
  therapyTypes?: Maybe<Scalars['String']['output']>;
  /** @deprecated Prefer to access on user object */
  timezone: Scalars['String']['output'];
  /** The training this professional has had. */
  training?: Maybe<Scalars['String']['output']>;
  /** The type of this professional. */
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The user account for this professional */
  user: User;
  /** @deprecated Prefer to access on user object */
  uuid: Scalars['String']['output'];
  /** Date and time at which this professional was verified by the BloomUp Team. */
  verifiedAt?: Maybe<Scalars['DateTime']['output']>;
  visumNumber?: Maybe<Scalars['String']['output']>;
  /** This professional's website. */
  website?: Maybe<Scalars['String']['output']>;
};


/** The persona of an individual professional on the platform. Reference attached user for persona agnostic fields */
export type ProfessionalAvailabilityArgs = {
  timeInMinutes?: InputMaybe<Scalars['Int']['input']>;
};

export type ProfessionalEdge = {
  __typename?: 'ProfessionalEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Professional>;
};

export type ProfessionalForTrack = {
  __typename?: 'ProfessionalForTrack';
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

/** The connection type between a human and a professional */
export type ProfessionalHumanConnection = {
  __typename?: 'ProfessionalHumanConnection';
  edges: Array<ProfessionalHumanEdge>;
};

/** The connection edge between a human and a professional */
export type ProfessionalHumanEdge = {
  __typename?: 'ProfessionalHumanEdge';
  node: Human;
};

export type ProfessionalInput = {
  acceptedConsent?: InputMaybe<Scalars['Boolean']['input']>;
  avatar?: InputMaybe<Scalars['Upload']['input']>;
  billingInformation?: InputMaybe<BillingInformationInput>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  clientStop?: InputMaybe<Scalars['Boolean']['input']>;
  cmNumber?: InputMaybe<Scalars['String']['input']>;
  consultationLanguages?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  experienceSince?: InputMaybe<Scalars['DateTime']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isFirstLine?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
  onboardedAt?: InputMaybe<Scalars['DateTime']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  practice?: InputMaybe<PracticeInput>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Scalars['String']['input']>;
  specialties?: InputMaybe<Scalars['String']['input']>;
  standardDuration?: InputMaybe<Scalars['Int']['input']>;
  standardRate?: InputMaybe<Scalars['Float']['input']>;
  supervision?: InputMaybe<Scalars['Boolean']['input']>;
  therapyTypes?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  training?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  visumNumber?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ProfessionalRegistrationInput = {
  acceptedConsent?: InputMaybe<Scalars['Boolean']['input']>;
  consultationLanguages?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  licenseFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  training?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ProfessionalSpecialtiesInput = {
  /** Specialties should be an array of uuids of SpecialtiesThemeCombo's */
  specialtiesThemeComboIds: Array<Scalars['String']['input']>;
};

export type ProfessionalsConnection = {
  __typename?: 'ProfessionalsConnection';
  edges?: Maybe<Array<Maybe<ProfessionalEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ProfessionalsForCurrentHumanInput = {
  withAvailability?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProfessionalsInput = {
  adminsIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  adminsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  verifiedIncluded?: InputMaybe<Scalars['Boolean']['input']>;
  verifiedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Query = {
  __typename?: 'Query';
  allConsultationsForHuman?: Maybe<ConsultationsConnection>;
  allConsultationsForProfessional?: Maybe<ConsultationsConnection>;
  /**
   * Returns the tracks available in the provided language with the chosen type.
   * If no language is provided it will return based on the currently logged in user.
   * If no type is provided it will return all the available tracks disregarding type.
   * Track order will depend on the currently logged in user
   */
  availableTracks: Array<Track>;
  calendarEvents?: Maybe<Array<Maybe<CalendarFormats>>>;
  cashTransactionsByConsultationId?: Maybe<Array<Maybe<CashTransaction>>>;
  checkIfHumanIsAClient?: Maybe<Scalars['Boolean']['output']>;
  clientsForProfessional?: Maybe<ClientsConnection>;
  consultation?: Maybe<Consultation>;
  /** Returns the list of consultations that the logged in professional has with the specified human */
  consultationsForHumanAndProfessional?: Maybe<ConsultationsConnection>;
  /** Returns the list of consultations that the logged in human has with the specified professional */
  consultationsForProfessionalAndHuman?: Maybe<ConsultationsConnection>;
  currentMatchedHuman?: Maybe<MatchedHuman>;
  /** Returns the node content for the logged in user and specified track. */
  currentNodeContentV2: TypedNodeContent;
  /** Returns the currently logged in user. If tokens are invalid or not provided this will return null */
  currentUser?: Maybe<User>;
  findMatches?: Maybe<Matches>;
  getQuestionById?: Maybe<Question>;
  getSSOUrl: Scalars['String']['output'];
  /** Returns a patient with the specified Id */
  human?: Maybe<Human>;
  /**
   * This query returns the slug of a given org id.
   * If the org id is missing, global data will be returned.
   */
  landingPageUniqueVisitors: Array<LineGraphItem>;
  lastCashTransactionByConsultationId?: Maybe<CashTransaction>;
  metabaseDashboards?: Maybe<Array<Maybe<MetabaseDashboard>>>;
  mindlabUser?: Maybe<User>;
  mindlabUsers?: Maybe<Array<Maybe<User>>>;
  /** Gets the status of a given mobile app version. */
  mobileVersionStatus: MobileStatus;
  /** Returns a list of consultations for the logged in professionals with optional pagination and filtering */
  myProfessionalConsultations: ConsultationsConnection;
  /** Verifies if the provided code is linked to an organisation, or if the arg is an email, if it's domain matches an organisation */
  organizationByEmailOrCode?: Maybe<Organization>;
  organizationById?: Maybe<Organization>;
  organizationBySlug?: Maybe<Organization>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  /**
   * Returns a professional with the specified Id, if no id is specified, returns the currently logged in
   * professional
   */
  professional?: Maybe<Professional>;
  professionalExists?: Maybe<Scalars['Boolean']['output']>;
  /** Returns all professionals */
  professionals?: Maybe<ProfessionalsConnection>;
  professionalsForBgt?: Maybe<Array<Maybe<Professional>>>;
  /** Returns all professionals for a logged in human */
  professionalsForCurrentHuman?: Maybe<ProfessionalsConnection>;
  questionForUserAtEntryPoint?: Maybe<Question>;
  sendNotification?: Maybe<Scalars['String']['output']>;
  specialties?: Maybe<Array<Maybe<SpecialtiesThemeCombo>>>;
  /** Returns a specific track by id */
  track: Track;
  /** Returns a specific track artifact by id */
  trackArtifact: TrackArtifact;
  /** Returns all tracks for which the currently logged in user has at least one trackArtifact */
  tracksWithArtifacts: Array<Track>;
  /**
   * This query returns the total of employees with an n amount of consults.
   * e.g.: 10 employees with 1 consult, 5 employees with 2 consults, etc.
   * If the org id is missing, global data will be returned.
   */
  videoConsultBarchartEmployeesWithAnAmountOfConsults: Array<BarchartItem>;
  /**
   * This query returns the average consult per employee.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardAverageConsultPerEmployee: Scalars['Float']['output'];
  /**
   * This query returns consults that have been canceled.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardCancelledConsults: Scalars['Int']['output'];
  /**
   * This query returns consults that have been completed.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardCompletedConsults: Scalars['Int']['output'];
  /**
   * This query returns consults that have been canceled.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardNoShowConsults: Scalars['Int']['output'];
  /**
   * This query returns a name and a value for the funnel chart.
   * If the org id is missing, global data will be returned.
   */
  videoConsultFunnel: Array<FunnelItem>;
  /**
   * This query returns the wellbeing challenges list
   * ordered from most chosen challenge to least chosen challenge, limited to 10.
   * If the org id is missing, global data will be returned.
   */
  wellbeingChallenges: Array<WellbeingChallengesResult>;
  /**
   * This query returns the wellbeing value(s) for the energy gauge chart.
   * If the org id is missing, global data will be returned.
   */
  wellbeingEnergyGauge: DifferenceGaugeResult;
  /**
   * This query returns the wellbeing value(s) for the productivity gauge chart.
   * If the org id is missing, global data will be returned.
   */
  wellbeingProductivityGauge: DifferenceGaugeResult;
  /**
   * This query returns the wellbeing value(s) for the stress gauge chart.
   * If the org id is missing, global data will be returned.
   */
  wellbeingStressGauge: DifferenceGaugeResult;
};


export type QueryAvailableTracksArgs = {
  language?: InputMaybe<Language>;
  type?: InputMaybe<TrackType>;
};


export type QueryCalendarEventsArgs = {
  asFullCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryCashTransactionsByConsultationIdArgs = {
  consultationId: Scalars['Int']['input'];
};


export type QueryCheckIfHumanIsAClientArgs = {
  humanId: Scalars['Int']['input'];
};


export type QueryConsultationArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryConsultationsForHumanAndProfessionalArgs = {
  professionalId: Scalars['Int']['input'];
};


export type QueryConsultationsForProfessionalAndHumanArgs = {
  humanId: Scalars['Int']['input'];
};


export type QueryCurrentNodeContentV2Args = {
  trackId: Scalars['String']['input'];
};


export type QueryGetQuestionByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetSsoUrlArgs = {
  mindlabBusinessId?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};


export type QueryHumanArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLandingPageUniqueVisitorsArgs = {
  endDate: Scalars['Date']['input'];
  organizationID: Scalars['Int']['input'];
  startDate: Scalars['Date']['input'];
};


export type QueryLastCashTransactionByConsultationIdArgs = {
  consultationId: Scalars['Int']['input'];
};


export type QueryMindlabUserArgs = {
  mindlabUserId: Scalars['String']['input'];
};


export type QueryMindlabUsersArgs = {
  mindlabUserIds: Array<Scalars['String']['input']>;
};


export type QueryMobileVersionStatusArgs = {
  version: Scalars['SemVer']['input'];
};


export type QueryMyProfessionalConsultationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ConsultationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ConsultationOrder>;
};


export type QueryOrganizationByEmailOrCodeArgs = {
  emailOrCode: Scalars['String']['input'];
};


export type QueryOrganizationByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOrganizationBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryProfessionalArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProfessionalExistsArgs = {
  email: Scalars['String']['input'];
};


export type QueryProfessionalsArgs = {
  input: ProfessionalsInput;
};


export type QueryProfessionalsForCurrentHumanArgs = {
  input?: InputMaybe<ProfessionalsForCurrentHumanInput>;
};


export type QueryQuestionForUserAtEntryPointArgs = {
  input: EntryPointsInput;
};


export type QuerySendNotificationArgs = {
  beamName: Scalars['String']['input'];
  message: Notification;
};


export type QueryTrackArgs = {
  id: Scalars['String']['input'];
};


export type QueryTrackArtifactArgs = {
  id: Scalars['String']['input'];
};


export type QueryVideoConsultBarchartEmployeesWithAnAmountOfConsultsArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVideoConsultCardAverageConsultPerEmployeeArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVideoConsultCardCancelledConsultsArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVideoConsultCardCompletedConsultsArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVideoConsultCardNoShowConsultsArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVideoConsultFunnelArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWellbeingChallengesArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWellbeingEnergyGaugeArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWellbeingProductivityGaugeArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWellbeingStressGaugeArgs = {
  organizationID?: InputMaybe<Scalars['Int']['input']>;
};

export type Question = {
  __typename?: 'Question';
  category: QuestionsCategory;
  component?: Maybe<QuestionsComponent>;
  id: Scalars['Int']['output'];
  nextQuestionId?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  questionType: QuestionsType;
  title: Scalars['String']['output'];
  translationKey?: Maybe<Scalars['String']['output']>;
};

export type QuestionsCategory =
  | 'b2b'
  | 'call_quality'
  | 'end_of_treatment'
  | 'match_quality'
  | 'nps'
  | 'professional_quality'
  | 'share_with_bloomup';

export type QuestionsComponent =
  | 'checkbox'
  | 'dropdown'
  | 'radio'
  | 'toggle';

export type QuestionsType =
  | 'likert'
  | 'multi_select'
  | 'open'
  | 'single_select'
  | 'slider'
  | 'yes_no'
  | 'yes_no_maybe';

export type RegisterHumanInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  fromMindlab?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  mindlabNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  organizationCode?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  preferredLanguage: Language;
};

export type Reimbursement = WithDates & WithId & {
  __typename?: 'Reimbursement';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  nationalId?: Maybe<Scalars['String']['output']>;
  reimbursementSubmission?: Maybe<ReimbursementSubmission>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReimbursementInput = {
  consultationUuid: Scalars['String']['input'];
  nationalId: Scalars['String']['input'];
};

export type ReimbursementSubmission = WithDates & WithId & {
  __typename?: 'ReimbursementSubmission';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RequestedByType =
  | 'HUMAN'
  | 'PROFESSIONAL';

/** Input object for rescheduling a consultation. */
export type RescheduleConsultationInput = {
  id: Scalars['Int']['input'];
  message: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Float']['input']>;
  scheduledFrom: Scalars['DateTime']['input'];
  scheduledTo: Scalars['DateTime']['input'];
};

export type RestrictedClient = {
  __typename?: 'RestrictedClient';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  human?: Maybe<Human>;
  humanId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  professionalId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ClientStatus>;
};

export type Secrets2Fa = {
  __typename?: 'Secrets2FA';
  qrCode: Scalars['String']['output'];
  secret: Scalars['String']['output'];
};

export type SituationApplicationNodeContent = {
  __typename?: 'SituationApplicationNodeContent';
  applicableQuestion: NodeApplicableQuestion;
  buttonType: NodeButtonType;
  categoryQuestion: NodeCategoryQuestion;
  id: Scalars['String']['output'];
  situations: Array<NodeSituation>;
};

export type SortDirection =
  | 'asc'
  | 'desc';

export type SpecialtiesThemeCombo = {
  __typename?: 'SpecialtiesThemeCombo';
  id: Scalars['String']['output'];
  primaryColor: Scalars['String']['output'];
  secondaryColor: Scalars['String']['output'];
  subcategories: Array<Maybe<SubCategory>>;
  theme: Scalars['String']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
  translationKey: Scalars['String']['output'];
};

export type StartMatchingInput = {
  extraInfo?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type StartMatchingResponse = {
  __typename?: 'StartMatchingResponse';
  matchedHumanId: Scalars['Int']['output'];
  matchingToken: Scalars['String']['output'];
};

export type SubCategory = {
  __typename?: 'SubCategory';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
  translationKey: Scalars['String']['output'];
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  /**
   * the accessToken is a JWT that contains the role_type and user_id (UUID) of the user, if login succeeds.
   * The user_id can be used to fetch the associated Professional / Patient.
   */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** the mindlabToken is a JWT that is used for the mindlab rest-api. The response can be found at https://github.com/we-are-coding/mindlab-backend/blob/main/src/api/AuthEndpoint.ts . */
  mindlabToken?: Maybe<Scalars['String']['output']>;
  /**
   * the refreshToken is a JWT that can be used to refresh the accessToken if it expires
   * without having to login again.
   */
  refreshToken?: Maybe<Scalars['String']['output']>;
};

export type Track = {
  __typename?: 'Track';
  artifacts: Array<TrackArtifact>;
  beta: MultiLanguageBoolean;
  currentUserCompleted: Scalars['Boolean']['output'];
  currentUserProfessional?: Maybe<Professional>;
  currentUserStarted: Scalars['Boolean']['output'];
  description: MultiLanguageString;
  detailLink: MultiLanguageString;
  duration: Scalars['Duration']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  sections: Array<TrackSection>;
  title: MultiLanguageString;
};

export type TrackArtifact = {
  __typename?: 'TrackArtifact';
  content: MultiLanguageString;
  headerImage: MultiLanguageString;
  id: Scalars['String']['output'];
  title: MultiLanguageString;
};

export type TrackSection = {
  __typename?: 'TrackSection';
  duration: Scalars['Duration']['output'];
  id: Scalars['String']['output'];
  imageUrl: Scalars['String']['output'];
  steps: Array<GrowthStep>;
  title: MultiLanguageString;
  type: TrackSectionType;
};

export type TrackSectionType =
  | 'phase'
  | 'videoCall';

export type TrackType =
  | 'assessment'
  | 'exercise'
  | 'growthTrack';

/** The content for a given node of the track */
export type TypedNodeContent = DistributeListNodeContent | EndOfTrackNodeContent | NodeContent | OptionListNodeContent | PaywallNodeContent | SituationApplicationNodeContent | WaitNodeContent;

export type UpdateCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  returnAsFullCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConsultationInput = {
  fromMatching?: InputMaybe<Scalars['Boolean']['input']>;
  humanCallQuality?: InputMaybe<Scalars['Int']['input']>;
  humanNps?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<MessageInput>;
  price?: InputMaybe<Scalars['Float']['input']>;
  professionalCallQuality?: InputMaybe<Scalars['Int']['input']>;
  scheduledFrom?: InputMaybe<Scalars['DateTime']['input']>;
  scheduledTo?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<ConsultationStatus>;
  therapistWithoutBloomup?: InputMaybe<Scalars['Boolean']['input']>;
  userRequested?: InputMaybe<Scalars['Boolean']['input']>;
  uuid: Scalars['String']['input'];
};

/** Every account is a user, see attached human or professional for persona specifc details */
export type User = {
  __typename?: 'User';
  /** The organization this user is a admin of. Will be null when this user is not a admin of an organization */
  adminOfOrganization?: Maybe<Organization>;
  /** User's age based on date of birth. */
  age?: Maybe<Scalars['Int']['output']>;
  /** User's date of birth. */
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date at which the user was created */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** User's first e-mail address. */
  email: Scalars['String']['output'];
  /** User's first name. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The human persona for this user. Will be null when this user does not act as a human */
  human?: Maybe<Human>;
  id: Scalars['String']['output'];
  /** User's last name. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Boolean that indicates whether the client wants to receive newsletters or not. */
  mindlabNewsletter?: Maybe<Scalars['Boolean']['output']>;
  /** Id that identifies the user within the mindlab database */
  mindlabUserId?: Maybe<Scalars['String']['output']>;
  /** Whether or not a user has completed (Professional / Human) onboarding. */
  onboardedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User's phone number. */
  phone?: Maybe<Scalars['String']['output']>;
  /**
   * The preferred language for this user. He / she will see all text presented in this language.
   * This is also the language that will be the default language used in consultations.
   */
  preferredLanguage: Language;
  /** The professional persona for this user. Will be null when this user does not act as a professional */
  professional?: Maybe<Professional>;
  timezone: Scalars['String']['output'];
  twoFactorAuthEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type UserFile = {
  __typename?: 'UserFile';
  collectionName: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type VideoCall = {
  __typename?: 'VideoCall';
  attendeeInfo: Array<ChimeAttendeeInfo>;
  meetingInfo: ChimeMeetingInfo;
};

export type WaitNodeContent = {
  __typename?: 'WaitNodeContent';
  amountOfMinutes: Scalars['Int']['output'];
  forCheckIn: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

/** This file contains datastructures that are common in multiple types */
export type WithDates = {
  /** Time and date when this user was created. */
  createdAt: Scalars['DateTime']['output'];
  /** Time and date of the last update to this user. */
  updatedAt: Scalars['DateTime']['output'];
};

export type WithId = {
  /** The database ID of this entity */
  id: Scalars['Int']['output'];
};

export type ConsultationOrder = {
  scheduledFrom: SortDirection;
};

/** This is the type for wellbeing challenges. */
export type WellbeingChallengesResult = {
  __typename?: 'wellbeingChallengesResult';
  /** This is the count (times chosen by users) of the challenge. */
  count: Scalars['Int']['output'];
  /** This is the id of the challenge. */
  id: Scalars['Int']['output'];
  /** This is the name (translation key) of the challenge. */
  name: Scalars['String']['output'];
};

export type JoinOrgByEmailOrCodeHomeMutationVariables = Exact<{
  emailOrCode: Scalars['String']['input'];
}>;


export type JoinOrgByEmailOrCodeHomeMutation = { __typename?: 'Mutation', joinOrganizationByEmailOrCodeV2?: boolean | null };

export type LoginMutationVariables = Exact<{
  input: LoginV2Input;
}>;


export type LoginMutation = { __typename?: 'Mutation', loginV2: { __typename?: 'LoginV2FailureResponse', reason: LoginFailureReason } | { __typename?: 'TokenResponse', mindlabToken?: string | null, accessToken?: string | null, refreshToken?: string | null } };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  fromMindlab?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ChoosePasswordMutationVariables = Exact<{
  input: ChoosePasswordV2Input;
}>;


export type ChoosePasswordMutation = { __typename?: 'Mutation', choosePasswordV2: boolean };

export type Request2FaSecretsMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;


export type Request2FaSecretsMutation = { __typename?: 'Mutation', request2FASecrets: { __typename?: 'Secrets2FA', qrCode: string, secret: string } };

export type Enable2FaMutationVariables = Exact<{
  input: Enable2FaInput;
}>;


export type Enable2FaMutation = { __typename?: 'Mutation', enable2FA: boolean };

export type JoinOrgByEmailOrCodeProfileMutationVariables = Exact<{
  emailOrCode: Scalars['String']['input'];
}>;


export type JoinOrgByEmailOrCodeProfileMutation = { __typename?: 'Mutation', joinOrganizationByEmailOrCodeV2?: boolean | null };

export type RegisterHumanMutationVariables = Exact<{
  input: RegisterHumanInput;
}>;


export type RegisterHumanMutation = { __typename?: 'Mutation', registerHumanV2: { __typename?: 'TokenResponse', accessToken?: string | null, refreshToken?: string | null, mindlabToken?: string | null } };

export type GetSsoUrlQueryQueryVariables = Exact<{
  mindlabBusinessId: Scalars['String']['input'];
}>;


export type GetSsoUrlQueryQuery = { __typename?: 'Query', getSSOUrl: string };

export type LoginWithSsoMutationVariables = Exact<{
  input: LoginWithSsoInput;
}>;


export type LoginWithSsoMutation = { __typename?: 'Mutation', loginWithSSO: { __typename?: 'LoginV2FailureResponse' } | { __typename?: 'TokenResponse', mindlabToken?: string | null } };

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshAccessTokenMutation = { __typename?: 'Mutation', refreshAccessTokenV2: { __typename?: 'TokenResponse', accessToken?: string | null, refreshToken?: string | null } };


export const JoinOrgByEmailOrCodeHomeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"joinOrgByEmailOrCodeHome"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"joinOrganizationByEmailOrCodeV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"emailOrCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}}}]}]}}]} as unknown as DocumentNode<JoinOrgByEmailOrCodeHomeMutation, JoinOrgByEmailOrCodeHomeMutationVariables>;
export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginV2Input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TokenResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mindlabToken"}},{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"LoginV2FailureResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reason"}}]}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fromMindlab"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"fromMindlab"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fromMindlab"}}}]}]}}]} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChoosePasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"choosePassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ChoosePasswordV2Input"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"choosePasswordV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<ChoosePasswordMutation, ChoosePasswordMutationVariables>;
export const Request2FaSecretsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Request2FASecrets"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"request2FASecrets"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"qrCode"}},{"kind":"Field","name":{"kind":"Name","value":"secret"}}]}}]}}]} as unknown as DocumentNode<Request2FaSecretsMutation, Request2FaSecretsMutationVariables>;
export const Enable2FaDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Enable2FA"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Enable2FAInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enable2FA"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<Enable2FaMutation, Enable2FaMutationVariables>;
export const JoinOrgByEmailOrCodeProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"joinOrgByEmailOrCodeProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"joinOrganizationByEmailOrCodeV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"emailOrCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"emailOrCode"}}}]}]}}]} as unknown as DocumentNode<JoinOrgByEmailOrCodeProfileMutation, JoinOrgByEmailOrCodeProfileMutationVariables>;
export const RegisterHumanDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RegisterHuman"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RegisterHumanInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"registerHumanV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}},{"kind":"Field","name":{"kind":"Name","value":"mindlabToken"}}]}}]}}]} as unknown as DocumentNode<RegisterHumanMutation, RegisterHumanMutationVariables>;
export const GetSsoUrlQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSSOUrlQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mindlabBusinessId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getSSOUrl"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mindlabBusinessId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mindlabBusinessId"}}}]}]}}]} as unknown as DocumentNode<GetSsoUrlQueryQuery, GetSsoUrlQueryQueryVariables>;
export const LoginWithSsoDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"loginWithSSO"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginWithSSOInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginWithSSO"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TokenResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"mindlabToken"}}]}}]}}]}}]} as unknown as DocumentNode<LoginWithSsoMutation, LoginWithSsoMutationVariables>;
export const RefreshAccessTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RefreshAccessToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshAccessTokenV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"refreshToken"},"value":{"kind":"Variable","name":{"kind":"Name","value":"refreshToken"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"accessToken"}},{"kind":"Field","name":{"kind":"Name","value":"refreshToken"}}]}}]}}]} as unknown as DocumentNode<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;