import { Sidebar } from "../Sidebar";
import React from "react";
import { ChallengeForm } from "./ChallengeForm";

export function CreateChallenge() {
  return (
    <Sidebar>
      <ChallengeForm challenge={null} />
    </Sidebar>
  );
}
