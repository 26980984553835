import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

export function QuarterlyReportFooter() {
  const styles = StyleSheet.create({
    footer: {
      width: "100%",
      height: "10%",
      textAlign: "center",
      backgroundColor: "#1F3B49",
      marginTop: "auto",
      color: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontWeight: "normal",
    },
  });

  return (
    <View style={styles.footer}>
      <Text>info@mindlab.be</Text>
    </View>
  );
}
