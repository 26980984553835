import React from "react";
import { useTranslation } from "react-i18next";
import { User } from "./api/UserApi";
import { Lesson } from "./api/LessonsApi";
import { pdf } from "@react-pdf/renderer";
import { Certificate } from "./Certificate";
import { Module } from "./api/ModulesApi";
import { toast } from "react-toastify";
import { getCorrectUrl } from "./Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";

export function UserDashboardCertificatesBox({
  module,
  user,
  progress,
  lessons,
}: {
  module: Module;
  user?: User;
  progress: number;
  lessons: Lesson[];
}) {
  const { i18n } = useTranslation("user-dashboard");

  function downloadCertificate() {
    const toastId = toast.loading("Creating certificate...");

    setTimeout(async () => {
      const blob = await pdf(
        <Certificate
          name={`${user?.firstName} ${user?.lastName}`}
          module={
            i18n.language === "nl"
              ? module.titleNL
              : i18n.language === "en"
              ? module.titleEN
              : module.titleFR
          }
        />
      ).toBlob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Certificate ${
        i18n.language === "nl"
          ? module.titleNL
          : i18n.language === "en"
          ? module.titleEN
          : module.titleFR
      }.pdf`;
      link.click();

      toast.dismiss(toastId);
    }, 1);
  }

  return lessons.length !== 0 && progress >= lessons.length ? (
    <div className="certificate-item flex pointer">
      <div
        className="coloured-border"
        style={{
          backgroundColor: `${
            module.skills ? "var(--yellow)" : "var(--regular-blue)"
          }`,
        }}
      />
      <div
        className="certificate-content flex"
        onClick={() => {
          downloadCertificate();
        }}
      >
        <div className="module-img">
          <img
            className="img"
            src={getCorrectUrl(module.image)}
            alt={module.titleNL}
          />
        </div>
        <div className="certificate-title">
          {i18n.language === "nl"
            ? module.titleNL
            : i18n.language === "en"
            ? module.titleEN
            : module.titleFR}
        </div>
        <FontAwesomeIcon
          className="ml-auto certificate-download"
          icon={faDownload}
        />
      </div>
    </div>
  ) : null;
}
