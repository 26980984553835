import React from "react";
import { useTranslation } from "react-i18next";

export function PrivacyPolicy() {
  const { i18n } = useTranslation();

  if (i18n.language === "fr") {
    return (
      <div className="container">
        <h1>Politique de confidentialité</h1>
        <h3>Cookies</h3>
        <p>Mindlab by BloomUp n’utilise pas de cookies.</p>
        <h4>Cookies fonctionnels</h4>
        <ol>
          <li>Aucun cookie fonctionnel n’est utilisé.</li>
        </ol>
        <h4>Cookies de marketing</h4>
        <ol>
          <li>Aucun cookie de marketing n’est utilisé.</li>
        </ol>
        <h4>Cookies statistiques</h4>
        <ol>
          <li>1. Aucun cookie statistique n’est utilisé.</li>
        </ol>
        <h3>Données</h3>
        <p>
          Lors de la création d’un compte, de la visite du site web et de la
          réalisation d’exercices, plusieurs détails sont pris en compte.
          Ceux-ci peuvent être supprimés à tout moment. Tout ce qui concerne
          exercices déjà réalisés se trouve dans la toolbox (boîte à outils).
          Toutes les autres données peuvent être récupérées via le bouton
          intégré dans les paramètres.
        </p>
        <h4>Types de données :</h4>
        <ol>
          <li>Code d’entreprise</li>
          <li>Choix de la newsletter</li>
          <li>Exercices réalisés</li>
          <li>Tests complétés</li>
          <li>Dernière date de visite sur la plate-forme</li>
          <li>Dernier module/dernière leçon consulté(e)</li>
        </ol>
        <h3>Partage des données</h3>
        <p>
          Les informations personnelles ne sont jamais partagées avec des tiers.
          Les informations anonymes et générales relatives aux utilisateurs
          peuvent être utilisées pour établir des rapports à usage interne ou
          externe. Ces informations ne contiennent <strong>jamais</strong> de
          données personnelles :
        </p>
        <ol>
          <li>Nombre de membres actifs</li>
          <li>Nombre d’utilisateurs par module</li>
          <li>Délai d’exécution moyen par module</li>
        </ol>
        <h3>Sous-traitants</h3>
        <ol>
          <li>
            Mailerlite : pour l’envoi des newsletters. Pour ce faire, nous
            partageons les adresses e-mail des utilisateurs. Lors de la création
            d’un compte, les utilisateurs peuvent décider de s’abonner à la
            newsletter et donc, de recevoir des e-mails de marketing. Les
            utilisateurs peuvent se désabonner à tout moment via le lien
            figurant dans l’e-mail lui-même ou via les paramètres du compte sur
            la plate-forme de Mindlab by BloomUp.
          </li>
          <li>Hetzner : notre hébergeur.</li>
        </ol>
        <h3>Responsable du traitement</h3>
        <ol>
          <li>
            Mindlab by BloomUp : étant donné que la décision finale concernant
            la manière et la raison pour laquelle les données personnelles sont
            traitées repose sur Mindlab by BloomUp, Mindlab by BloomUp est
            considéré comme le Responsable du traitement. Mindlab by BloomUp a
            également développé une procédure pour la notification de fuites de
            données si un tel cas devait se produire et afin que tous les
            utilisateurs puissent être informés en temps utile, conformément à
            la loi.
          </li>
        </ol>
        <h3>Droits et obligations</h3>
        <ol>
          <li>
            Sous réserve des exceptions légales, le contenu et la documentation
            d’un module ne peuvent être reproduits, traduits, adaptés ou
            enregistrés, en tout ou en partie, sous quelque forme ou par quelque
            moyen que ce soit, de même que le contenu et la documentation d’un
            module ne peuvent être communiqués ou transmis au public, en tout ou
            en partie, sous quelque forme ou par quelque moyen que ce soit (tels
            que, mais sans s’y limiter, des moyens électroniques et mécaniques)
            sans le consentement préalable et écrit de l’ayant droit ou de
            MINDLAB by BloomUp.
          </li>
        </ol>
        <h3>Sécurité</h3>
        <p>
          Tous les mots de passe des utilisateurs sont stockés sous forme
          cryptée, conformément aux normes les plus récentes. En cas d’éventuel
          problème de données, il est impossible de récupérer les mots de passe
          des utilisateurs. L’accès au serveur lui-même est également limité.
          Une clé privée (+ mot de passe) est utilisée et seules les personnes
          autorisées ont accès à ces données.
        </p>
        <h4>Administrateurs</h4>
        <p>
          Seul un nombre limité de personnes a accès à un compte administrateur.
          Ces dernières ont accès aux informations suivantes :
        </p>
        <ol>
          <li>Les utilisateurs actifs</li>
          <li>Les entreprises actives</li>
          <li>
            3. Les informations générales relatives aux utilisateurs telles que
            le nom, l’adresse e-mail, l’adresse...{" "}
          </li>
        </ol>
        <p>Les personnes suivantes ont accès à un compte administrateur :</p>
        <ol>
          <li>Sare De Caster (fondatrice)</li>
          <li>Jan Lazeure (cofondateur)</li>
        </ol>
      </div>
    );
  }

  return (
    <div className="container">
      <h1>Privacyverklaring</h1>
      <h3>Cookies</h3>
      <p>Mindlab by BloomUp maakt geen gebruik van cookies.</p>
      <h4>Functionele cookies</h4>
      <ol>
        <li>Er worden geen functionele cookies gebruikt</li>
      </ol>
      <h4>Marketingcookies</h4>
      <ol>
        <li>Er worden geen marketing cookies gebruikt</li>
      </ol>
      <h4>Statistische cookies</h4>
      <ol>
        <li>Er worden geen statistische cookies gebruikt</li>
      </ol>
      <h3>Gegevens</h3>
      <p>
        Bij het maken van een account, het bezoeken van de website en het
        invullen van oefeningen, is met verschillende details rekening gehouden.
        Deze kunnen op elk gewenst moment worden verwijderd. Alles in verband
        met oefeningen wat ooit is gemaakt, kan worden gevonden in de toolbox.
        Alle andere gegevens kunnen worden opgehaald via de ingebouwde knop bij
        de instellingen.
      </p>
      <h4>Soorten gegevens:</h4>
      <ol>
        <li>Bedrijfscode</li>
        <li>Keuze nieuwsbrief</li>
        <li>Ingevulde oefeningen</li>
        <li>Ingevulde testen</li>
        <li>Laatste datum van bezoek op het platform</li>
        <li>Laatst bekeken module/les</li>
      </ol>
      <h3>Delen van gegevens</h3>
      <p>
        Persoonlijke informatie wordt nooit gedeeld met derden. Geanonimiseerde
        en algemene gebruikersinformatie kan worden gebruikt voor rapportage
        voor voor intern of extern gebruik. Deze informatie bevat{" "}
        <strong>nooit</strong> persoonsgegevens, maar bevat wel de gegevens:{" "}
      </p>
      <ol>
        <li>Aantal actieve leden</li>
        <li>Aantal gebruikers per module</li>
        <li>Gemiddelde doorlooptijd per module</li>
      </ol>
      <h3>Subverwerkers</h3>
      <ol>
        <li>
          Mailerlite: Voor de nieuwsbrieven te versturen. Hiermee delen we de
          emailadressen van de gebruikers. Bij aanmaken van een account kunnen
          gebruikers kiezen om zich in te schrijven voor de nieuwsbrief en dus
          marketing mails willen ontvangen. De gebruikers kunnen zich ten alle
          tijde uitschrijven via de link in de mail zelf of via de account
          instellingen opp het platform van Mindlab.
        </li>
        <li>Hetzner: Onze hosting.</li>
      </ol>
      <h3>Verwerkingsverantwoordelijke</h3>
      <ol>
        <li>
          Mindlab: aangezien Mindlab de eindbeslissing heeft over hoe en waarom
          persoonsgegevens worden verwerkt, wordt dus Mindlag als
          Verwerkingsverantwoordelijke gezien. Mindlab heeft dan ook een
          procedure uitgewerkt voor het melden van datalekken mocht dit ooit
          voorkomen en zodat alle gebruikers volgens de wetgeving tijdig
          verwittigd kunnen worden.
        </li>
      </ol>
      <h3>Rechten en plichten</h3>
      <ol>
        <li>
          Behoudens de wettelijke uitzonderingen, mag de inhoud en de
          documentatie van een module niet, geheel of gedeeltelijk,
          verveelvoudigd, vertaald, aangepast of opgeslagen worden in enige vorm
          of op enige wijze, noch mag de inhoud en de documentatie van een
          module geheel of gedeeltelijk, medegedeeld of doorgegeven worden aan
          het publiek in enige vorm of op enige wijze (zoals, maar niet beperkt
          tot, elektronische en mechanische wijze) zonder voorafgaande en
          schriftelijke toestemming van de rechthebbende of MINDLAB.
        </li>
      </ol>
      <h3>Veiligheid</h3>
      <p>
        Alle gebruikerswachtwoorden worden versleuteld opgeslagen, volgens de
        nieuwste normen. Bij een mogelijk data probleem, kan men de wachtwoorden
        van de gebruikers niet achterhalen. De toegang tot de server zelf is ook
        beperkt. Een privésleutel (+ wachtwoord) wordt gebruikt en alleen
        gelegitimeerde personen hebben deze gegevens.{" "}
      </p>
      <h4>Beheerders</h4>
      <p>
        Slechts een beperkt aantal mensen hebben toegang tot een admin account.
        Zij toegang tot de volgende informatie:
      </p>
      <ol>
        <li>De actieve users</li>
        <li>De actieve bedrijven</li>
        <li>
          Algemeen informatie van gebruikers zoals naam, e-mailadres, adres …
        </li>
      </ol>
      <p>De volgende mensen hebben toegang tot een beheerdersaccount:</p>
      <ol>
        <li>Sare De Caster (oprichter)</li>
        <li>Jan Lazeure (mede oprichter)</li>
      </ol>
    </div>
  );
}
