export enum FaceScanResultType {
  HEART_RATE = 1,
  ACUTE_MENTAL_STRESS_SCORE = 2,
  MENTAL_HEALTH_RISK = 3,
  RESPIRATORY_RATE = 4,
  ANS_BALANCE = 5,
  MORNING_READINESS_SCORE = 6,
  HEART_RATE_VARIABILITY = 7,
  RESONANT_BREATHING_SCORE = 8,
}
