import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { getLessonsByModuleAndSubject, Lesson } from "./api/LessonsApi";
import { ToolboxHeader } from "./ToolboxHeader";
import { getModules, Module } from "./api/ModulesApi";
import Certificaat from "./img/certificaat.png";
import {
  getCurrentUser,
  getModuleProgress,
  ModuleProgress,
  User,
} from "./api/UserApi";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { getSubjects } from "./api/SubjectsApi";
import { Certificate } from "./Certificate";
import { Loading } from "./Loading";
import { useTranslation } from "react-i18next";

export function ToolboxCertificates() {
  const [lessons, setLessons] = useState<Record<string, Lesson[]>>({});
  const [modules, setModules] = useState<Module[]>([]);
  const [progresses, setProgresses] = useState<
    Record<string, ModuleProgress[]>
  >({});
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getModules().then(async (result) => {
      setModules(result);

      const tmpProgresses: Record<string, ModuleProgress[]> = {};
      const tmpLessons: Record<string, Lesson[]> = {};

      for (const module of result) {
        const progress = await getModuleProgress(module.id);

        const subjects = await getSubjects(module.id);

        const lessonsList = [];
        for (const subject of subjects) {
          const lessonsResponse = await getLessonsByModuleAndSubject(
            module.id,
            subject.id
          );
          lessonsList.push(...lessonsResponse);
        }

        tmpProgresses[module.id] = progress;
        tmpLessons[module.id] = lessonsList;
      }

      setProgresses(tmpProgresses);
      setLessons(tmpLessons);
      setLoading(false);
    });

    getCurrentUser().then((r) => {
      setUser(r);
    });
  });

  return (
    <ToolboxHeader>
      <div className="container crisishulp w-100">
        <div className="tools">
          <div className="bread">
            <img src={Certificaat} />
            <div className="title">{t("toolbox.cert_name")}</div>
          </div>
        </div>
        <div className="favorites">
          {loading ? (
            <div className="flex justify-center items-center">
              <Loading />
            </div>
          ) : (
            modules.map((module) => {
              const progress = progresses[module.id];

              const moduleLessons = lessons[module.id];

              if (
                progress &&
                moduleLessons.length !== 0 &&
                progress.length >= moduleLessons.length
              ) {
                return (
                  <div className="all-item">
                    <div className="all-title">{module.titleNL}</div>
                    <PDFDownloadLink
                      document={
                        <Certificate
                          name={`${user?.firstName} ${user?.lastName}`}
                          module={
                            i18n.language === "nl"
                              ? module.titleNL
                              : i18n.language === "en"
                              ? module.titleEN
                              : module.titleFR
                          }
                        />
                      }
                    >
                      <div className="center">{t("download_cert")}</div>
                    </PDFDownloadLink>
                  </div>
                );
              }

              return null;
            })
          )}
        </div>
      </div>
    </ToolboxHeader>
  );
}
