import { decode } from "js-base64";

export const getTokenExpiry = (token: string | null) => {
  if (token === null) return 0;

  const token_parts = token.split(/\./);

  const userData = JSON.parse(decode(token_parts[1]));

  return userData.exp;
};
