import { Sidebar } from "../Sidebar";
import React from "react";
import { ListForm } from "./ListForm";

export function CreateList() {
  return (
    <Sidebar>
      <ListForm list={null} />
    </Sidebar>
  );
}
