import { API_ENDPOINT, getHeaders } from "./AuthApi";

export interface EvaluationInput {
  score: number;
  interesting: string;
  less: string;
  unclear: string;
  relevant: string;
  recommendation: number;
  remarks: string;
  experience: string;
  name: string | undefined;
  place: string | undefined;
}

export interface EvaluationResponse extends EvaluationInput {
  id: string;
  userId: string;
  businessId: string | undefined;
  moduleId: string;
}

export async function getEvaluations(): Promise<EvaluationResponse[]> {
  const result = await fetch(`${API_ENDPOINT}/evaluations`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.evaluations) {
    return [];
  }

  return result.evaluations;
}

export async function createEvaluation(
  data: EvaluationInput,
  moduleId: string
): Promise<EvaluationResponse> {
  const result = await fetch(`${API_ENDPOINT}/evaluations/${moduleId}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  }).then((r) => r.json());

  return result;
}
