import React, { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { NavLink, useLocation } from "react-router-dom";
import ArrowRight from "./img/arrow-right.svg";
import Hamburger from "./img/hamburger.svg";
import ProgramIcon from "./img/programmas.png";
import CertificateIcon from "./img/certificaten.png";
import ReportsIcon from "./img/rapporten.png";
import FavoriteLessonsIcon from "./img/favoriete-lessen.png";
import ExercisesIcon from "./img/oefeninge.png";
import TimesIcon from "./img/tijden.png";
import "./UserDashboardSidebar.css";
import { UserDashboardSidebarMobile } from "./UserDashboardSidebarMobile";

export function UserDashboardSidebar({ children }: PropsWithChildren<any>) {
  const { t, i18n } = useTranslation("user-dashboard");
  const location = useLocation();
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false);

  const handleClick = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const routes = [
    {
      url: "/" + i18n.language + "/user-dashboard",
      name: t("userSidebar.programs"),
      icon: ProgramIcon,
    },
    {
      url: "/" + i18n.language + "/user-dashboard/certificates",
      name: t("userSidebar.certificates"),
      icon: CertificateIcon,
    },
    {
      url: "/" + i18n.language + "/user-dashboard/reports",
      name: t("userSidebar.reports"),
      icon: ReportsIcon,
    },
    {
      url: "/" + i18n.language + "/user-dashboard/favorite-lessons",
      name: t("userSidebar.favorites"),
      icon: FavoriteLessonsIcon,
    },
    {
      url: "/" + i18n.language + "/user-dashboard/exercises",
      name: t("userSidebar.exercises"),
      icon: ExercisesIcon,
    },
    {
      url: "/" + i18n.language + "/user-dashboard/times",
      name: t("userSidebar.times"),
      icon: TimesIcon,
    },
  ];

  return !isMobile ? (
    <div className="flex user-sidebar sidebar-container">
      <div className="sidebar flex flex-column">
        {routes.map((item) => {
          return (
            <NavLink
              to={item.url}
              activeClassName="active"
              className="sidebar-item"
              key={item.url}
              exact={true}
            >
              <img
                src={item.icon}
                alt="icon"
                className={`icon ${
                  location.pathname === item.url ? "active" : ""
                }`}
              />
              <div>{item.name}</div>
              {location.pathname === item.url ? (
                <img src={ArrowRight} alt="arrow" className={"ml-auto"} />
              ) : null}
            </NavLink>
          );
        })}
      </div>
      <div className="content user-content">{children}</div>
    </div>
  ) : (
    <div className="flex flex-column user-sidebar-mobile">
      <div className="content user-content">
        {!hamburgerOpen ? (
          <img
            src={Hamburger}
            alt={"menu"}
            className="user-sidebar-hamburger pointer mr-auto"
            onClick={handleClick}
          />
        ) : (
          <UserDashboardSidebarMobile
            handleClick={handleClick}
            routes={routes}
          />
        )}
        {children}
      </div>
    </div>
  );
}
