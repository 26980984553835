import React, { useEffect, useState } from "react";
import TooltipIcon from "../img/tooltipIcon.svg";
import { listTests, Test } from "../api/TestApi";
import { TestStatistics } from "../api/BusinessApi";
import "./TestsGraph.css";
import { useTranslation } from "react-i18next";

export function TestsTable({
  tests,
  testStats,
  className = "",
}: {
  tests: Test[];
  testStats: TestStatistics[];
  className?: string;
}) {
  const { t, i18n } = useTranslation("hr-dashboard");
  return (
    <table className={`tests-table ${className || ""}`}>
      <thead>
        <tr>
          <th>{t("testTable.test")}</th>
          <th>{t("testTable.total")}</th>
          <th>{t("testTable.redFlags")}</th>
        </tr>
      </thead>
      <tbody>
        {testStats.map((item) => {
          const foundTest = tests.find((t) => t.id === item.id);

          return (
            <tr key={item.id}>
              <td>
                <div className="flex">
                  {i18n.language === "nl"
                    ? foundTest?.title_nl
                    : i18n.language === "en"
                    ? foundTest?.title_en
                    : foundTest?.title_fr || ""}
                </div>
              </td>
              <td>
                <div className="flex justify-center">{item.total}</div>
              </td>
              <td>
                <div className="flex justify-center flags-container">
                  {item.flags}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function TestsGraph({ testStats }: { testStats: TestStatistics[] }) {
  const [tests, setTests] = useState<Test[]>([]);
  const { t } = useTranslation("hr-dashboard");

  useEffect(() => {
    listTests().then((result) => {
      setTests(result);
    });
  }, []);

  return (
    <div className="graph">
      <div className="graph-wrapper no-side-padding overflow-auto">
        <div className={"graph-title flex items-center"}>
          {t("testTable.title")}
          <div
            className={"flex items-center"}
            data-tooltip-id="graph-explanation"
            data-tooltip-content={t("testTable.tooltipContent")}
          >
            <img className={"tooltip-icon"} src={TooltipIcon} alt={""} />
          </div>
        </div>
        <TestsTable tests={tests} testStats={testStats} />
      </div>
    </div>
  );
}
