import React from "react";
import { uniqueId } from "../uniqueId";
import { getCorrectUrl } from "../Utils";

export function Audio({ url }: { url: string }) {
  return (
    <audio key={uniqueId()} controls={true}>
      <source src={getCorrectUrl(url)} type="audio/mp3" />
    </audio>
  );
}
