import { Sidebar } from "../Sidebar";
import React from "react";
import { PodcastForm } from "./PodcastForm";

export function CreatePodcast() {
  return (
    <Sidebar>
      <PodcastForm podcast={null} />
    </Sidebar>
  );
}
