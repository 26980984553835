import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import {
  createDocument,
  DashboardDocument,
  updateDocument,
} from "../api/DashboardDocumentsApi";
import { DocumentType } from "../Utils";

export function DashboardDocumentForm({
  document,
}: {
  document: DashboardDocument | null;
}) {
  const [name, setName] = useState(document ? document.name : "");
  const [type, setType] = useState(document ? document.type : "");
  const [url, setUrl] = useState(document ? document.url : "");

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Redirect to={"/dashboard/dashboard-documents"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (document) {
            await updateDocument(document.id, url, name, type);
          } else {
            await createDocument(url, name, type);
          }

          setSaved(true);
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              <h4>Algemene info</h4>
              <label>Naam</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={name}
                onChange={(input) => {
                  setName(input.target.value);
                }}
                required={true}
              />
              <label>Url</label>
              <input
                className="input"
                type="text"
                placeholder="Url"
                value={url}
                onChange={(input) => {
                  setUrl(input.target.value);
                }}
                required={true}
              />
              <label>Type</label>
              <Select
                className="input"
                placeholder={"Type"}
                value={{ value: type, label: type }}
                options={[
                  {
                    value: "",
                    label: "",
                  },
                  {
                    value: DocumentType.MAILTEMPLATES,
                    label: DocumentType.MAILTEMPLATES,
                  },
                  {
                    value: DocumentType.BEST_PRACTICES,
                    label: DocumentType.BEST_PRACTICES,
                  },
                  {
                    value: DocumentType.DRAAIBOEK,
                    label: DocumentType.DRAAIBOEK,
                  },
                  {
                    value: DocumentType.BROCHURES,
                    label: DocumentType.BROCHURES,
                  },
                  {
                    value: DocumentType.VISUALS_AND_GRAPHICS,
                    label: DocumentType.VISUALS_AND_GRAPHICS,
                  },
                  {
                    value: DocumentType.VARIA,
                    label: DocumentType.VARIA,
                  },
                ]}
                onChange={(e: any) => {
                  setType(e.value);
                }}
                required={true}
              />

              <button className="button" type="submit">
                {document ? "Document bijwerken" : "Document toevoegen"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
