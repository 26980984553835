import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Challenge, createChallenge, updateChallenge } from "../api/GameApi";

export function ChallengeForm({ challenge }: { challenge: Challenge | null }) {
  const [nameNL, setNameNL] = useState(challenge ? challenge.nameNL : "");
  const [nameEN, setNameEN] = useState(challenge ? challenge.nameEN : "");
  const [nameFR, setNameFR] = useState(challenge ? challenge.nameFR : "");
  const [textNL, setTextNL] = useState(challenge ? challenge.textNL : "");
  const [textEN, setTextEN] = useState(challenge ? challenge.textEN : "");
  const [textFR, setTextFR] = useState(challenge ? challenge.textFR : "");

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Redirect to={"/dashboard/challenges"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          // TODO checks on fields
          e.preventDefault();
          const updatedBusiness = {
            nameNL: nameNL,
            nameEN: nameEN,
            nameFR: nameFR,
            textNL: textNL,
            textEN: textEN,
            textFR: textFR,
          };

          if (challenge) {
            updateChallenge({
              ...updatedBusiness,
              id: challenge.id,
            }).then(async (r) => {
              setSaved(true);
            });
          } else {
            createChallenge({
              id: "",
              ...updatedBusiness,
            }).then(async (r) => {
              setSaved(true);
            });
          }
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              <h4>Algemene info</h4>
              <label>Naam NL</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={nameNL}
                onChange={(input) => {
                  setNameNL(input.target.value);
                }}
                required={true}
              />
              <label>Naam EN</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={nameEN}
                onChange={(input) => {
                  setNameEN(input.target.value);
                }}
                required={true}
              />
              <label>Naam FR</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={nameFR}
                onChange={(input) => {
                  setNameFR(input.target.value);
                }}
                required={true}
              />
              <label>Beschrijving in het Nederlands</label>
              <textarea
                className="input"
                placeholder="Beschrijving"
                rows={6}
                value={textNL}
                onChange={(input) => {
                  setTextNL(input.target.value);
                }}
                required={true}
              />
              <label>Beschrijving in het Engels</label>
              <textarea
                className="input"
                placeholder="Beschrijving"
                rows={6}
                value={textEN}
                onChange={(input) => {
                  setTextEN(input.target.value);
                }}
              />
              <label>Beschrijving in het Frans</label>
              <textarea
                className="input"
                placeholder="Beschrijving"
                value={textFR}
                rows={6}
                onChange={(input) => {
                  setTextFR(input.target.value);
                }}
              />

              <button className="button" type="submit">
                {challenge ? "Challenge bijwerken" : "Challenge toevoegen"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
