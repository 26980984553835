import { API_ENDPOINT, getHeaders } from "./AuthApi";

export interface CmsType {
  type: string;
  data: string;
}

export async function getCmsItem(type: string): Promise<CmsType> {
  const result = await fetch(`${API_ENDPOINT}/cms/${type}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result;
}

export async function upsertCmsItem(
  type: string,
  data: string
): Promise<CmsType> {
  const result = await fetch(`${API_ENDPOINT}/cms`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      type,
      data,
    }),
  }).then((r) => r.json());

  return result;
}
