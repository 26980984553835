import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import {
  addMedia,
  createMedia,
  getMedia,
  Media as MediaType,
} from "../api/MediaApi";
import { getCorrectUrl } from "../Utils";

export function Media() {
  const [media, setMedia] = useState<MediaType[]>([]);
  const [name, setName] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    getMedia().then((r) => {
      setMedia(r);
    });
  });

  function hasVideo(url: string) {
    const videoFormats = ["mp4", "mov", "avi"];

    for (const format of videoFormats) {
      if (url.toLowerCase().includes(format)) {
        return true;
      }
    }

    return false;
  }

  function hasImage(url: string) {
    const videoFormats = [
      "apng",
      "apng",
      "gif",
      "jpg",
      "jpeg",
      "jfif",
      "pjpeg",
      "pjp",
      "png",
      "svg",
      "webp",
    ];

    for (const format of videoFormats) {
      if (url.toLowerCase().includes(format)) {
        return true;
      }
    }

    return false;
  }

  function hasAudio(url: string) {
    const videoFormats = ["mp3", "wav", "ogg", "mpeg"];

    for (const format of videoFormats) {
      if (url.toLowerCase().includes(format)) {
        return true;
      }
    }

    return false;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Media</h2>
        </div>
        <div className="mb-4">
          <label>Upload media</label>
          <input
            type="file"
            className="input"
            placeholder="Upload media"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setSelectedFile(e.target.files[0]);
              }
            }}
          />
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <div
            className="button small w-fit"
            onClick={async (e) => {
              e.preventDefault();

              setError(false);
              setSuccess(false);

              try {
                if (selectedFile) {
                  const pathResult = await addMedia(selectedFile);

                  if (
                    pathResult &&
                    typeof pathResult !== "string" &&
                    pathResult.path
                  ) {
                    const result = await createMedia(pathResult.path, name);

                    setName("");
                    setMedia(media.concat(result));
                    setSelectedFile(null);
                    setSuccess(true);
                  }
                }
              } catch (e) {
                setError(true);
              }
            }}
          >
            Uploaden
          </div>
          {success ? <div className="green">Uploaden geslaagd!</div> : null}
          {error ? <div className="red">Er is iets foutgelopen</div> : null}
        </div>

        {media.length === 0 ? (
          "Momenteel nog geen media beschikbaar"
        ) : (
          <>
            <label>Filter</label>
            <input
              type="text"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
            <table className="table ">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Url</th>
                  <th>Voorbeeld</th>
                </tr>
              </thead>
              <tbody>
                {media
                  .filter((m) =>
                    (m.name || "").toLowerCase().includes(filter.toLowerCase())
                  )
                  .map((media) => {
                    return (
                      <tr key={media.id}>
                        <td>{media.name}</td>
                        <td>
                          {hasImage(media.url)
                            ? `[picture url="${media.url}"]`
                            : ""}
                          {hasVideo(media.url)
                            ? `[video url="${media.url}"]`
                            : ""}
                          {hasAudio(media.url)
                            ? `[audio url="${media.url}"]`
                            : ""}
                          {!hasImage(media.url) &&
                          !hasVideo(media.url) &&
                          !hasAudio(media.url)
                            ? media.url
                            : ""}
                        </td>
                        <td>
                          {hasImage(media.url) ? (
                            <img
                              style={{ maxWidth: "300px" }}
                              src={getCorrectUrl(media.url)}
                              alt={"media"}
                            />
                          ) : null}
                          {hasVideo(media.url) ? (
                            <video
                              src={getCorrectUrl(media.url)}
                              style={{ maxWidth: "300px" }}
                              controls={true}
                            />
                          ) : null}
                          {hasAudio(media.url) ? (
                            <audio
                              src={getCorrectUrl(media.url)}
                              style={{ maxWidth: "300px" }}
                              controls={true}
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Sidebar>
  );
}
