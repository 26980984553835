import React, { useState } from "react";
import { UserDashboardSidebar } from "./UserDashboardSidebar";
import "./UserDashboardPrograms.css";
import { useTranslation } from "react-i18next";
import { UserDashboardProgramsBox } from "./UserDashboardProgramsBox";
import { getModules, Module } from "./api/ModulesApi";
import { useEffectOnce } from "react-use";
import { getPodcasts, Podcast } from "./api/PodcastApi";
import { getUserPodcastTimes } from "./api/UserApi";

export function UserDashboardPrograms() {
  const [modules, setModules] = useState<Module[]>([]);
  const [podcasts, setPodcasts] = useState<Podcast[]>([]);
  const [podcastTimes, setPodcastTimes] = useState<
    { id: string; time: number }[]
  >([]);

  const { t } = useTranslation("user-dashboard");

  useEffectOnce(() => {
    getModules().then((result) => {
      setModules(result);
    });

    getPodcasts().then((result) => {
      setPodcasts(result);
    });

    getUserPodcastTimes().then((result) => {
      setPodcastTimes(result);
    });
  });

  return (
    <UserDashboardSidebar>
      <div className="programs-page">
        <h2>{t("userPrograms.title")}</h2>
        <div className="box-grid flex gap">
          {modules.map((item) => {
            if (!item) {
              return null;
            }

            return <UserDashboardProgramsBox module={item} />;
          })}
          {podcasts.map((item) => {
            if (!item) {
              return null;
            }

            return (
              <UserDashboardProgramsBox
                podcast={item}
                podcastTimes={podcastTimes}
              />
            );
          })}
        </div>
      </div>
    </UserDashboardSidebar>
  );
}
