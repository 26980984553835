import {
  Business,
  listBusinesses,
  getConnectedBusinessesForUser,
  ConnectedBusiness,
} from "../api/BusinessApi";
import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import {
  addConnectedBusiness,
  addUserToModules,
  createUser,
  deleteConnectedBusiness,
  getModuleProgressForUser,
  ModuleProgress as Progress,
  updateUser,
  User,
} from "../api/UserApi";
import { useEffectOnce } from "react-use";
import { forgotPassword } from "../api/AuthApi";
import { DateTime } from "luxon";
import { getModules, Module } from "../api/ModulesApi";
import { getSubjects } from "../api/SubjectsApi";
import { getLessonsByModuleAndSubject, Lesson } from "../api/LessonsApi";
import { useMutation } from "@apollo/client";
import { graphql } from "../__generated__";
import { useTranslation } from "react-i18next";

const resetPasswordMutation = graphql(`
  mutation ResetPassword($email: String!, $fromMindlab: Boolean) {
    resetPassword(email: $email, fromMindlab: $fromMindlab)
  }
`);

export function UserForm({ user }: { user: User | null }) {
  const [firstName, setFirstName] = useState(user ? user.firstName : "");
  const [lastName, setLastName] = useState(user ? user.lastName : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);
  const [language, setLanguage] = useState("nl");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [modules, setModules] = useState<Module[]>([]);
  const { t } = useTranslation("login");

  const [saved, setSaved] = useState(false);

  const [bloomupResetPassword] = useMutation(resetPasswordMutation);

  const handleResetPassword = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!process.env.REACT_APP_USE_BLOOMUP_LOGIN) {
        e.preventDefault();
        forgotPassword(email).then(() => {
          setPasswordMessage("Wachtwoord reset aangevraagd");
        });
      } else {
        bloomupResetPassword({
          variables: { email: email, fromMindlab: true },
          onError: () => {
            setPasswordMessage(t("genericError"));
          },
          onCompleted: () => {
            setPasswordMessage(t("passwordResetSend"));
          },
        });
      }
    },
    [bloomupResetPassword, email, t]
  );

  const foundBusiness = selectedBusiness
    ? businesses.find((b) => b.id === selectedBusiness)
    : null;

  const foundModule = selectedModule
    ? modules.find((b) => b.id === selectedModule)
    : null;

  useEffectOnce(() => {
    listBusinesses().then((r) => {
      setBusinesses(r);
    });

    if (user) {
      getConnectedBusinessesForUser(user.id).then((r) => {
        setConnectedBusinesses(r);

        if (r.length > 0) {
          setSelectedBusiness(r[0].business.id);
        }
      });

      getModules().then((r) => {
        setModules(r);
      });
    }
  });

  if (saved) {
    return <Redirect to={"/dashboard/users"} push />;
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();

        if (user) {
          await updateUser(user.id, firstName, lastName, email, language);

          if (connectedBusinesses.length > 0) {
            await deleteConnectedBusiness(
              connectedBusinesses[0].business.id,
              user.id
            );
          }

          await addConnectedBusiness(selectedBusiness, user.id);
        } else {
          const newUser = await createUser(
            firstName,
            lastName,
            email,
            language
          );

          await addConnectedBusiness(selectedBusiness, newUser.id);
        }

        setSaved(true);
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Voornaam</label>
            <input
              className="input"
              type="text"
              placeholder="Voornaam"
              value={firstName}
              onChange={(input) => {
                setFirstName(input.target.value);
              }}
              required={true}
            />
            <label>Achternaam</label>
            <input
              className="input"
              type="text"
              placeholder="Achternaam"
              value={lastName}
              onChange={(input) => {
                setLastName(input.target.value);
              }}
              required={true}
            />
            <label>Email</label>
            <input
              className="input"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(input) => {
                setEmail(input.target.value);
              }}
              required={true}
            />
            {user ? (
              <>
                {passwordMessage}
                <div onClick={handleResetPassword} className="text-link">
                  Wachtwoord reset aanvragen
                </div>
              </>
            ) : null}
            <label>Taal</label>
            <Select
              options={[
                { label: "nl", value: "nl" },
                { label: "en", value: "en" },
                { label: "fr", value: "fr" },
              ]}
              onChange={(e: any) => {
                setLanguage(e.value);
              }}
              value={{ label: language, value: language }}
            />
            <div className="mt-4" />
            <label>
              <b>Aangemaakt op</b>
            </label>
            <div>
              {user && user.createdAt
                ? DateTime.fromISO(user?.createdAt).toFormat("dd/LL/y")
                : "Onbekend"}
            </div>
            <div className="mt-4" />
            <label>
              <b>Voortgang</b>
            </label>
            <div className="mb-4">
              {user &&
                modules.map((m) => {
                  return <UserProgress module={m} userId={user.id} />;
                })}
            </div>

            <button className="button" type="submit">
              {user ? "Gebruiker bijwerken" : "Gebruiker aanmaken"}
            </button>
          </div>
        </div>
        <div className="w-25">
          <h4>Bedrijfsinfo</h4>
          <label>Naam</label>
          <Select
            options={[{ label: "", value: "" }].concat(
              businesses.map((item) => {
                return { label: `${item.code} (${item.name})`, value: item.id };
              })
            )}
            onChange={(e: any) => {
              setSelectedBusiness(e.value);
            }}
            value={
              foundBusiness
                ? {
                    value: foundBusiness.id,
                    label: `${foundBusiness.code} (${foundBusiness.name})`,
                  }
                : { label: "", value: "" }
            }
          />
          {user ? (
            <div
              className="button pink w-fit mt-4"
              onClick={async () => {
                for (const conn of connectedBusinesses) {
                  await deleteConnectedBusiness(conn.business.id, user.id);
                }

                window.location.reload();
              }}
            >
              Reset toegang tot bedrijven
            </div>
          ) : null}
          {user ? (
            <>
              <div className="mt-4" />
              <label>Geef toegang tot module</label>
              <Select
                options={[{ label: "", value: "" }].concat(
                  modules.map((item) => {
                    return { label: `${item.titleNL}`, value: item.id };
                  })
                )}
                onChange={(e: any) => {
                  setSelectedModule(e.value);
                }}
                value={
                  foundModule
                    ? {
                        value: foundModule.id,
                        label: `${foundModule.titleNL}`,
                      }
                    : { label: "", value: "" }
                }
              />
              <div
                className="button w-fit mt-4"
                onClick={async () => {
                  if (!selectedModule) {
                    return;
                  }

                  await addUserToModules(selectedModule, user.id);

                  window.location.reload();
                }}
              >
                Geef toegang
              </div>
            </>
          ) : null}
        </div>
      </div>
    </form>
  );
}

function UserProgress({ module, userId }: { module: Module; userId: string }) {
  const [moduleProgress, setModuleProgress] = useState<Progress[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);

  useEffectOnce(() => {
    getSubjects(module.id).then(async (result) => {
      const tempLessons: Lesson[] = [];

      for (const subject of result) {
        const subjectLessons = await getLessonsByModuleAndSubject(
          module.id,
          subject.id
        );

        tempLessons.push(...subjectLessons);
      }

      setLessons(tempLessons);
    });

    getModuleProgressForUser(module.id, userId).then(async (result) => {
      setModuleProgress(result);
    });
  });

  const visited = moduleProgress.map((i) => i.lessonId);
  const percentage = Math.floor((visited.length / lessons.length) * 100);

  return (
    <div className="mb-4">
      {module.titleNL}: {visited.length}/{lessons.length} ({percentage}%)
    </div>
  );
}
