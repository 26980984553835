import { useQuery } from "@apollo/client";
import { graphql } from "./__generated__";
import { useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import "./SSOLanding.css";
import { useTranslation } from "react-i18next";

const getSSOUrlQuery = graphql(`
  query getSSOUrlQuery($mindlabBusinessId: String!) {
    getSSOUrl(mindlabBusinessId: $mindlabBusinessId)
  }
`);

export function SSOLanding({
  setAuthenticated,
}: {
  setAuthenticated: (state: boolean) => void;
}) {
  const { t } = useTranslation("login");
  const params = useParams<{ businessId: string }>();

  const { data, loading, error } = useQuery(getSSOUrlQuery, {
    variables: { mindlabBusinessId: params.businessId },
  });

  data && setAuthenticated(true);

  if (data?.getSSOUrl) window.location.href = data?.getSSOUrl;

  return (
    <div className="ssoLandingContainer">
      <RotatingLines
        strokeColor="#224453"
        strokeWidth="5"
        animationDuration="0.75"
        width={"115"}
        visible={loading}
      />
      {error && <div>{t("login:reloadError")}</div>}
    </div>
  );
}
