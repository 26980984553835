import { API_ENDPOINT, getHeaders } from "./AuthApi";

export interface IntelliProveQualityResponse {
  score: number;
  error_type: number;
  prompt?: string;
  signature?: string;
}

export interface IntelliProveResults {
  id: string;
  timestamp: string;
  timestampEpoch: number;
  heartRate: number;
  respiratoryRate: number | null;
  heartRateVariability: number | null;
  ansBalance: number | null;
  resonantBreathingScore: number | null;
  morningReadiness: number | null;
  acuteMentalStressScore: number | null;
  mentalHealthRisk: number | null;
  errorCode: number;
  errorDescription?: string;
}

function throwErrorIfHttpError(result: { status: number }) {
  if (result.status >= 400 && result.status <= 599) {
    throw new Error("HTTP request failed");
  }
}

// eslint-disable-next-line
export namespace IntelliProveApi {
  export async function check(
    imageBlob: Blob
  ): Promise<IntelliProveQualityResponse> {
    const formData = new FormData();
    formData.append("image", imageBlob);

    return await fetch(`${API_ENDPOINT}/intelliprove/check`, {
      method: "POST",
      headers: getHeaders(false),

      body: formData,
    }).then((r) => r.json());
  }

  export async function uploadVideo(
    videoBlob: Blob,
    qualityResponse: IntelliProveQualityResponse,
    periodic: boolean
  ): Promise<string> {
    const formData = new FormData();
    formData.append("video", videoBlob);
    formData.append("qualityResponse", JSON.stringify(qualityResponse));
    formData.append("periodic", periodic ? "true" : "false");

    const result = await fetch(`${API_ENDPOINT}/intelliprove/upload-video`, {
      method: "POST",
      headers: getHeaders(false),
      body: formData,
    }).then((r) => r.json());

    throwErrorIfHttpError(result);

    return result.resultUUID;
  }

  export async function getResults(
    resultUUID: string
  ): Promise<IntelliProveResults> {
    return await fetch(`${API_ENDPOINT}/intelliprove/results/${resultUUID}`, {
      method: "GET",
      headers: getHeaders(),
    }).then((r) => r.json());
  }

  export async function getResultsForUser(): Promise<IntelliProveResults[]> {
    const results = await fetch(`${API_ENDPOINT}/intelliprove/results/me`, {
      method: "GET",
      headers: getHeaders(),
    }).then((r) => r.json());

    throwErrorIfHttpError(results);

    if (!results.results) {
      return [];
    }

    return results.results;
  }

  export async function createDeniedPeriodicResult(): Promise<void> {
    const response = await fetch(
      `${API_ENDPOINT}/intelliprove/denied-periodic-results`,
      {
        method: "POST",
        headers: getHeaders(),
      }
    ).then((r) => r.json());

    throwErrorIfHttpError(response);
  }

  export async function checkScanDue(): Promise<boolean> {
    const response = await fetch(
      `${API_ENDPOINT}/intelliprove/periodic-scan-due`,
      {
        method: "GET",
        headers: getHeaders(),
      }
    ).then((r) => r.json());

    throwErrorIfHttpError(response);

    return response.due;
  }
}
