import React from "react";
import { Sidebar } from "../../Sidebar";
import { SubjectForm } from "./SubjectForm";

export function CreateSubject() {
  return (
    <Sidebar>
      <SubjectForm subject={null} />
    </Sidebar>
  );
}
