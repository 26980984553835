import { Test, TestTemplates } from "../api/TestApi";
import React, { useEffect } from "react";
import "./LoadTest.css";
import { MultipleOldTest } from "../MultipleOldTest";
import { DefaultTest } from "../DefaultTest";
import { addModuleTime, addTestTime } from "../api/UserApi";

let interval: any;
export function LoadTest({ test }: { test: Test }) {
  useEffect(() => {
    interval = setInterval(async () => {
      await addTestTime(test.id, 1);
    }, 1000 * 60 * 1);

    return () => {
      clearInterval(interval);
    };
  });

  if (test.template === TestTemplates.MULTIPLE_OLD) {
    return <MultipleOldTest test={test} />;
  }

  return <DefaultTest test={test} />;
}
