import React, { PropsWithChildren } from "react";
import "./Sidebar.css";
import { NavLink } from "react-router-dom";

export function Sidebar({ children }: PropsWithChildren<any>) {
  return (
    <div className="flex sidebar-container">
      <div className="sidebar flex flex-column">
        <NavLink
          exact={true}
          to="/dashboard"
          activeClassName="active"
          className="sidebar-item"
        >
          Overzicht
        </NavLink>
        <NavLink
          to="/dashboard/dashboard-documents"
          activeClassName="active"
          className="sidebar-item"
        >
          Dashboard documents
        </NavLink>
        <NavLink
          to="/dashboard/about-us"
          activeClassName="active"
          className="sidebar-item"
        >
          About us
        </NavLink>
        <NavLink
          to="/dashboard/landingpage"
          activeClassName="active"
          className="sidebar-item"
        >
          Landingpage
        </NavLink>
        <NavLink
          to="/dashboard/faq"
          activeClassName="active"
          className="sidebar-item"
        >
          FAQ
        </NavLink>
        <NavLink
          to="/dashboard/test-types"
          activeClassName="active"
          className="sidebar-item"
        >
          Test Types
        </NavLink>
        <NavLink
          to="/dashboard/tests"
          activeClassName="active"
          className="sidebar-item"
        >
          Testen
        </NavLink>
        <NavLink
          to="/dashboard/users"
          activeClassName="active"
          className="sidebar-item"
        >
          Gebruikers
        </NavLink>
        <NavLink
          to="/dashboard/challenges"
          activeClassName="active"
          className="sidebar-item"
        >
          Challenges
        </NavLink>
        <NavLink
          to="/dashboard/discounts"
          activeClassName="active"
          className="sidebar-item"
        >
          Discounts
        </NavLink>
        <NavLink
          to="/dashboard/businesses"
          activeClassName="active"
          className="sidebar-item"
        >
          Bedrijfscodes
        </NavLink>
        <NavLink
          to="/dashboard/logos"
          activeClassName="active"
          className="sidebar-item"
        >
          Logos
        </NavLink>
        <NavLink
          to="/dashboard/partners"
          activeClassName="active"
          className="sidebar-item"
        >
          Partners
        </NavLink>
        <NavLink
          to="/dashboard/testimonials"
          activeClassName="active"
          className="sidebar-item"
        >
          Testimonials
        </NavLink>
        <NavLink
          to="/dashboard/modules"
          activeClassName="active"
          className="sidebar-item"
        >
          Modules
        </NavLink>
        <NavLink
          to="/dashboard/lists"
          activeClassName="active"
          className="sidebar-item"
        >
          Lijsten
        </NavLink>
        <NavLink
          to="/dashboard/podcasts"
          activeClassName="active"
          className="sidebar-item"
        >
          Podcast
        </NavLink>
        <NavLink
          to="/dashboard/blogs"
          activeClassName="active"
          className="sidebar-item"
        >
          Blog
        </NavLink>
        <NavLink
          to="/dashboard/quizzes"
          activeClassName="active"
          className="sidebar-item"
        >
          Quizzen
        </NavLink>
        <NavLink
          to="/dashboard/media"
          activeClassName="active"
          className="sidebar-item"
        >
          Media
        </NavLink>
      </div>
      <div className="content">{children}</div>
    </div>
  );
}
