import { decode } from "js-base64";

export type Token = {
  firstName: string;
  lastName: string;
  language: string;
  email: string;
  admin: boolean;
  userId: string;
  isHR: boolean;
  exp: number;
};

export type CustomResponse = {
  error?: string;
  ok: boolean;
  businessId?: string;
};

export type LoginResponse = {
  error?: string;
  ok: boolean;
  needs2FAToken: boolean;
};

export function getHeaders(includeContentType = true) {
  return {
    Authorization: `JWT ${localStorage.getItem("token")}`,
    "X-BU-Authorization": `Bearer ${localStorage.getItem(
      "bloomupAccessToken"
    )}`,
    ...(includeContentType
      ? { "Content-type": "application/json" }
      : undefined),
  };
}

export const API_ENDPOINT = process.env.REACT_APP_API_URL;

export async function removeInactive(email: string): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/remove-inactive`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      email: email,
    }),
  }).then((r) => r.json());

  return !!result.ok;
}

export async function addToBusiness(
  code: string | undefined
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/businesses/change`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      code: code,
    }),
  }).then((r) => r.json());

  return !!result.ok;
}

export function redirectIfNotAuthenticated() {
  const token = localStorage.getItem("token");

  if (token === null) {
    return true;
  }

  try {
    const token_parts = token.split(/\./);
    const token_decoded = JSON.parse(decode(token_parts[1]));

    return new Date(token_decoded.exp * 1000) < new Date();
  } catch (e) {
    return true;
  }
}

export async function login(
  email: string,
  password: string,
  token?: string
): Promise<LoginResponse> {
  const result = await fetch(`${API_ENDPOINT}/auth/login`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ email: email, password: password, token2FA: token }),
  }).then((r) => r.json());

  if (result.message) {
    return {
      ok: false,
      error: result.message,
      needs2FAToken: false,
    };
  }

  if (result.token) {
    localStorage.setItem("token", result.token);
    return { ok: true, needs2FAToken: false };
  }

  if (result.needs2FAToken) {
    return { ok: false, needs2FAToken: true };
  }

  return {
    ok: false,
    error: "Er is iets foutgelopen bij de login.",
    needs2FAToken: false,
  };
}

export async function forgotPassword(email: string): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/auth/forgot`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ email: email }),
  }).then((r) => r.json());

  return result.message;
}

export async function resetPassword(
  password: string,
  id: string
): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/auth/password`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ password: password, id: id }),
  }).then((r) => r.json());

  return result.message;
}

export async function getVersion(): Promise<string> {
  const result = await fetch(`${API_ENDPOINT}/version`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result.version;
}

export function loggedIn() {
  return !redirectIfNotAuthenticated();
}

export function getDecodedToken(): Token {
  const token = localStorage.getItem("token");

  if (token === null) {
    return {
      userId: "",
      firstName: "",
      lastName: "",
      language: "nl",
      email: "",
      admin: false,
      isHR: false,
      exp: 0,
    };
  }

  const token_parts = token.split(/\./);
  return JSON.parse(decode(token_parts[1]));
}

export type BloomupToken = {
  exp: number;
  scope: string;
  sub: string;
  token_type: "access_token" | "refresh_token";
  org_id: number;
  iat: number;
};

export function getDecodedBloomupToken(type: "access" | "refresh") {
  const token = localStorage.getItem(
    type === "access"
      ? "bloomupAccessToken"
      : type === "refresh"
      ? "bloomupRefreshToken"
      : ""
  );

  if (!token) return null;

  const token_parts = token.split(/\./);
  return JSON.parse(decode(token_parts[1])) as BloomupToken;
}

export function isAdmin() {
  const token = getDecodedToken();

  return token.admin;
}
