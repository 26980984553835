import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { QuizForm } from "./QuizForm";
import { getQuizes, QuizType } from "../api/QuizApi";

export function EditQuiz() {
  const { quizId } = useParams<{ quizId: string }>();
  const [quiz, setQuiz] = useState<QuizType | null>(null);

  useEffectOnce(() => {
    getQuizes().then((r) => {
      setQuiz(r.find((c) => c.id === quizId) || null);
    });
  });

  if (!quiz) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <QuizForm quiz={quiz} />
    </Sidebar>
  );
}
