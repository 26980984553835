import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Redirect } from "react-router-dom";
import { FaPlus, FaPen, FaTimes } from "react-icons/fa";
import { deleteDiscount, Discount, getDiscounts } from "../api/DiscountsApi";

export function Discounts() {
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [addingDiscount, setAddingDiscount] = useState(false);
  const [editingDiscount, setEditingDiscount] = useState<Discount | null>(null);
  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    getDiscounts().then((r) => {
      setDiscounts(r);
    });
  });

  if (addingDiscount) {
    return <Redirect to="/dashboard/discounts/new" push />;
  }

  if (editingDiscount) {
    return (
      <Redirect to={`/dashboard/discounts/${editingDiscount.name}`} push />
    );
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Discounts</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingDiscount(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          type="text"
          placeholder={"Naam"}
          value={filter}
          onChange={(input) => {
            setFilter(input.target.value);
          }}
        />

        {discounts.length === 0 ? (
          "Momenteel nog geen discounts beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Waarde</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {discounts
                .filter((b) =>
                  b.name.toLowerCase().includes(filter.toLowerCase())
                )
                .map((business) => {
                  return (
                    <tr key={business.id}>
                      <td>{business.name}</td>
                      <td>{business.discount}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setEditingDiscount(business);
                            }}
                          >
                            <FaPen />
                          </div>
                          <div
                            className="button small w-fit ml-5 pink"
                            onClick={async () => {
                              await deleteDiscount(business);
                              setDiscounts(
                                discounts.filter((b) => b.id !== business.id)
                              );
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
