import { Sidebar } from "../Sidebar";
import React from "react";
import { QuizForm } from "./QuizForm";

export function CreateQuiz() {
  return (
    <Sidebar>
      <QuizForm quiz={null} />
    </Sidebar>
  );
}
