import { Sidebar } from "../Sidebar";
import React, { useState } from "react";
import { TestForm } from "./TestForm";
import { useEffectOnce, useSearchParam } from "react-use";
import { getTest, Test } from "../api/TestApi";

export function CreateTest() {
  const copy = useSearchParam("copy");

  const [test, setTest] = useState<Test | null>(null);

  useEffectOnce(() => {
    if (copy) {
      getTest(copy).then((r) => {
        setTest(r);
      });
    }
  });

  if (copy && !test) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <TestForm test={test} update={false} />
    </Sidebar>
  );
}
