import React, { useState } from "react";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import "./MoodForm.css";

export function MoodForm({ id }: { id: string }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [savedInput, setSavedInput] = useState<string>("");
  const [saved, setSaved] = useState(false);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult = result.find((r) => r.key === `MoodForm-${id}`);

      if (currentResult) {
        setSavedInput(JSON.parse(currentResult.result));
      }
    });
  });

  const nl = [
    "Slecht",
    "Slecht",
    "Niet goed",
    "Niet goed",
    "Matig",
    "Matig",
    "Goed",
    "Goed",
    "Heel goed",
    "Heel goed",
  ];

  const en = [
    "Bad",
    "Bad",
    "Not good",
    "Not good",
    "Mediate",
    "Mediate",
    "Good",
    "Good",
    "Very good",
    "Very good",
  ];

  const fr = [
    "Mauvais",
    "Mauvais",
    "Pas bien",
    "Pas bien",
    "Médiocre",
    "Médiocre",
    "Bien",
    "Bien",
    "Tres bien",
    "Tres bien",
  ];

  const color = [
    "red",
    "red",
    "red",
    "orange",
    "orange",
    "yellow",
    "yellow",
    "ltgreen",
    "ltgreen",
    "green",
    "green",
  ];

  const [score, setScore] = useState(4);

  return (
    <div>
      <div className="flex items-center flex-column mood">
        <h3>{t("feeling")}</h3>
        <div id="score">{score}</div>
        <input
          type="range"
          min="1"
          max="10"
          value={score}
          onChange={(e) => {
            setScore(parseInt(e.target.value));
          }}
          className={`range ${color[score]}`}
        />
        <div id="uitleg">
          {i18n.language === "nl" && nl[score]}
          {i18n.language === "en" && en[score]}
          {i18n.language === "fr" && fr[score]}
        </div>
      </div>
      {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
      <div
        className="button w-fit mb-4"
        onClick={async (e) => {
          e.preventDefault();

          await addModuleExerciseResult(
            lessonId,
            `MoodForm-${id}`,
            JSON.stringify(savedInput)
          );

          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        }}
      >
        {t("bewaar")}
      </div>
    </div>
  );
}
