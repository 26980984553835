import React, { Fragment, useEffect, useState } from "react";
import { uniqueId } from "./uniqueId";
import { addAdmins } from "./api/BusinessApi";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function HrAdminsForm({
  admins,
  setAdmins,
  close,
  selectedBusiness,
}: {
  admins: { id: string; email: string }[];
  setAdmins: (v: { id: string; email: string }[]) => void;
  close: () => void;
  selectedBusiness: string;
}) {
  const [currentAdmins, setCurrentAdmins] = useState(admins);
  const [removedAdmins, setRemovedAdmins] = useState<string[]>([]);

  useEffect(() => {
    setCurrentAdmins(admins);
  }, [admins]);

  return (
    <Fragment>
      {currentAdmins.map((admin, index) => {
        return (
          <div className="flex items-baseline">
            <input
              className="input"
              placeholder={"Email"}
              type="text"
              value={admin.email}
              onChange={(e) => {
                const newValue = e.target.value.toLowerCase().trim();
                if (removedAdmins.includes(newValue) && newValue !== "") {
                  setRemovedAdmins(removedAdmins.filter((a) => a !== newValue));
                }

                setCurrentAdmins(
                  currentAdmins.map((a) => {
                    if (a.id === admin.id) {
                      return {
                        ...a,
                        email: newValue,
                      };
                    }

                    return a;
                  })
                );
              }}
            />
            <div
              className="ml-5 pointer"
              onClick={() => {
                setCurrentAdmins(
                  currentAdmins.filter((c) => c.id !== admin.id)
                );

                if (admin.email.trim() === "") {
                  return;
                }

                setRemovedAdmins(removedAdmins.concat(admin.email));
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        );
      })}
      <div
        onClick={() => {
          setCurrentAdmins(currentAdmins.concat({ id: uniqueId(), email: "" }));
        }}
        className={"yellow mb-4 pointer"}
      >
        + email toevoegen
      </div>
      <div className="flex">
        <div
          className="button w-fit ml-auto"
          onClick={async () => {
            const filtered = currentAdmins.filter((a) => a.email.trim() !== "");
            await addAdmins(
              filtered.map((a) => a.email.toLowerCase()),
              selectedBusiness,
              removedAdmins
            );
            setAdmins(filtered);
            close();
          }}
        >
          Opslaan
        </div>
        <div
          className="button w-fit ml-5 pink"
          onClick={() => {
            close();
          }}
        >
          Annuleren
        </div>
      </div>
    </Fragment>
  );
}
