import React from "react";
import { Sidebar } from "../Sidebar";
import { UserForm } from "./UserForm";

export function CreateUser() {
  return (
    <Sidebar>
      <UserForm user={null} />
    </Sidebar>
  );
}
