import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Blog = {
  id: string;
  nameNL: string;
  nameEN: string;
  nameFR: string;
  contentNL: string;
  contentEN: string;
  contentFR: string;
  excerptNL: string;
  excerptEN: string;
  excerptFR: string;
  image: string;
};

export async function getBlogs(): Promise<Blog[]> {
  const result = await fetch(`${API_ENDPOINT}/blogs`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.blogs) {
    return [];
  }

  return result.blogs;
}

export async function createBlog(blog: Blog): Promise<Blog> {
  const result = await fetch(`${API_ENDPOINT}/blogs`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(blog),
  }).then((r) => r.json());

  return result;
}

export async function updateBlog(blog: Blog): Promise<Blog> {
  const result = await fetch(`${API_ENDPOINT}/blogs/${blog.id}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(blog),
  }).then((r) => r.json());

  return result;
}

export async function deleteBlog(blogId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/blogs/${blogId}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((r) => r.json());
}

export async function getBlog(blogId: string): Promise<Blog> {
  const result = await fetch(`${API_ENDPOINT}/blogs/${blogId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result;
}
