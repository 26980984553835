import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { createDiscount, Discount, updateDiscount } from "../api/DiscountsApi";

export function DiscountForm({ discount }: { discount: Discount | null }) {
  const [name, setName] = useState(discount ? discount.name : "");
  const [value, setValue] = useState(discount ? discount.discount : 0);

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Redirect to={"/dashboard/discounts"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          // TODO checks on fields
          e.preventDefault();
          const updatedBusiness = {
            name: name.toLowerCase().trim(),
            discount: value,
          };

          if (discount) {
            updateDiscount({
              ...updatedBusiness,
              id: discount.id,
            }).then(async (r) => {
              setSaved(true);
            });
          } else {
            createDiscount({
              id: "",
              ...updatedBusiness,
            }).then(async (r) => {
              setSaved(true);
            });
          }
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              <h4>Algemene info</h4>
              <label>Naam</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={name}
                onChange={(input) => {
                  setName(input.target.value);
                }}
                required={true}
              />
              <label>Waarde</label>
              <input
                className="input"
                type="number"
                placeholder="Naam"
                value={value}
                onChange={(input) => {
                  setValue(parseInt(input.target.value));
                }}
                required={true}
              />

              <button className="button" type="submit">
                {discount ? "Discount bijwerken" : "Discount toevoegen"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
