import React, { useState } from "react";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import LtmNL from "../img/ltmnl.jpg";
import LtmEn from "../img/ltmen.jpg";

export function LtmForm({ match, id }: { match: string; id: string }) {
  const regex = /type=&quot;(.*)&quot; lesson_id=&quot;(.*)&quot;/gm;
  const result = regex.exec(match);
  const type = (result && result[1]) || "";
  const lessonId = (result && result[2]) || "";
  const [savedInput1, setSavedInput1] = useState<string>("");
  const [savedInput2, setSavedInput2] = useState<string>("");
  const [savedInput3, setSavedInput3] = useState<string>("");
  const [savedInput4, setSavedInput4] = useState<string>("");
  const [savedInput5, setSavedInput5] = useState<string>("");
  const [savedInput6, setSavedInput6] = useState<string>("");
  const [saved, setSaved] = useState(false);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult1 = result.find((r) => r.key === `LtmForm1-${id}`);

      if (currentResult1) {
        setSavedInput1(JSON.parse(currentResult1.result));
      }

      const currentResult2 = result.find((r) => r.key === `LtmForm2-${id}`);

      if (currentResult2) {
        setSavedInput2(JSON.parse(currentResult2.result));
      }

      const currentResult3 = result.find((r) => r.key === `LtmForm3-${id}`);

      if (currentResult3) {
        setSavedInput3(JSON.parse(currentResult3.result));
      }

      const currentResult4 = result.find((r) => r.key === `LtmForm4-${id}`);

      if (currentResult4) {
        setSavedInput4(JSON.parse(currentResult4.result));
      }

      const currentResult5 = result.find((r) => r.key === `LtmForm5-${id}`);

      if (currentResult5) {
        setSavedInput5(JSON.parse(currentResult5.result));
      }

      const currentResult6 = result.find((r) => r.key === `LtmForm6-${id}`);

      if (currentResult6) {
        setSavedInput6(JSON.parse(currentResult6.result));
      }
    });
  });

  return (
    <div>
      {i18n.language === "nl" && <img style={{ width: "100%" }} src={LtmNL} />}
      {i18n.language === "en" && <img style={{ width: "100%" }} src={LtmEn} />}
      {i18n.language === "fr" && <img style={{ width: "100%" }} src={LtmNL} />}
      {type !== "herkennen" ? (
        <>
          <div
            style={{ margin: "0 9px", display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              {" "}
              {i18n.language === "nl" && "SIGNALEN"}
              {i18n.language === "en" && "SIGNALS"}
              {i18n.language === "fr" && "SIGNAUX"}
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#DEEBF6",
                  color: "black",
                }}
                disabled
                value={savedInput1}
              />
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#9DC3E4",
                  color: "black",
                }}
                disabled
                value={savedInput2}
              />
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#2F75B3",
                  color: "black",
                }}
                disabled
                value={savedInput3}
              />
            </div>
          </div>
        </>
      ) : null}

      {type === "herkennen" ? (
        <>
          <div
            style={{ margin: "0 9px", display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              {i18n.language === "nl" && "SIGNALEN"}
              {i18n.language === "en" && "SIGNALS"}
              {i18n.language === "fr" && "SIGNAUX"}
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#DEEBF6",
                  color: "black",
                }}
                onChange={(e) => {
                  setSavedInput1(e.target.value);
                }}
                value={savedInput1}
              />
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#9DC3E4",
                  color: "black",
                }}
                onChange={(e) => {
                  setSavedInput2(e.target.value);
                }}
                value={savedInput2}
              />
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#2F75B3",
                  color: "black",
                }}
                onChange={(e) => {
                  setSavedInput3(e.target.value);
                }}
                value={savedInput3}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{ margin: "0 9px", display: "flex", alignItems: "center" }}
          >
            <div style={{ width: "100%", textAlign: "center" }}>
              {i18n.language === "nl" && "ACTIEPLAN"}
              {i18n.language === "en" && "ACTION PLAN"}
              {i18n.language === "fr" && "PLAN D'ACTION"}
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#DEEBF6",
                  color: "black",
                }}
                onChange={(e) => {
                  setSavedInput4(e.target.value);
                }}
                value={savedInput4}
              />
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#9DC3E4",
                  color: "black",
                }}
                onChange={(e) => {
                  setSavedInput5(e.target.value);
                }}
                value={savedInput5}
              />
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                rows={6}
                style={{
                  width: "100%",
                  backgroundColor: "#2F75B3",
                  color: "black",
                }}
                onChange={(e) => {
                  setSavedInput6(e.target.value);
                }}
                value={savedInput6}
              />
            </div>
          </div>
        </>
      )}

      {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
      <div
        className="button w-fit mb-4"
        onClick={async (e) => {
          e.preventDefault();

          await addModuleExerciseResult(
            lessonId,
            `LtmForm1-${id}`,
            JSON.stringify(savedInput1)
          );

          await addModuleExerciseResult(
            lessonId,
            `LtmForm2-${id}`,
            JSON.stringify(savedInput2)
          );

          await addModuleExerciseResult(
            lessonId,
            `LtmForm3-${id}`,
            JSON.stringify(savedInput3)
          );

          await addModuleExerciseResult(
            lessonId,
            `LtmForm4-${id}`,
            JSON.stringify(savedInput4)
          );

          await addModuleExerciseResult(
            lessonId,
            `LtmForm5-${id}`,
            JSON.stringify(savedInput5)
          );

          await addModuleExerciseResult(
            lessonId,
            `LtmForm6-${id}`,
            JSON.stringify(savedInput6)
          );

          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        }}
      >
        {t("bewaar")}
      </div>
    </div>
  );
}
