import React, { useEffect, useState } from "react";
import { BusinessSidebar } from "../BusinessSidebar";
import Select from "react-select";
import {
  BusinessStatistics,
  ConnectedBusiness,
  getConnectedBusinesses,
  getStatsForBusiness,
} from "../api/BusinessApi";
import { useEffectOnce } from "react-use";
import "./BusinessDashboard.css";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { getModules, Module } from "../api/ModulesApi";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import { Duration } from "luxon";
import { Loading } from "../Loading";
import { listTests, Test } from "../api/TestApi";
import { getPodcasts, Podcast } from "../api/PodcastApi";
import { isMobile } from "react-device-detect";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  PointElement,
  LineElement
);

export function BusinessStats() {
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);
  const [stats, setStats] = useState<BusinessStatistics>();
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [modules, setModules] = useState<Module[]>([]);
  const [tests, setTests] = useState<Test[]>([]);
  const [podcasts, setPodcasts] = useState<Podcast[]>([]);
  const [loading, setLoading] = useState(false);

  const foundBusiness = connectedBusinesses.find(
    (b) => b.business.id === selectedBusiness
  );

  useEffectOnce(() => {
    setLoading(true);
    getConnectedBusinesses().then((result) => {
      setConnectedBusinesses(result);

      if (result.length > 0) {
        setSelectedBusiness(result[0].business.id);

        getStatsForBusiness(result[0].business.id).then((r) => {
          setStats(r);
          setLoading(false);
        });
      }
    });

    getModules().then((result) => {
      setModules(result);
    });

    listTests().then((r) => {
      setTests(r);
    });

    getPodcasts().then((r) => {
      setPodcasts(r);
    });
  });

  useEffect(() => {
    setLoading(true);
    getStatsForBusiness(selectedBusiness).then((r) => {
      setStats(r);
      setLoading(false);
    });
  }, [selectedBusiness]);

  const data = {
    labels: ["0-40%", "41%-60%", "61%-80%", "81%-100%"],
    datasets: [
      {
        data: stats
          ? [stats.s0_40, stats.s41_60, stats.s61_80, stats.s81_100]
          : [],
        backgroundColor: ["#75A1AF", "#90B4BF", "#A8C4CD", "#DDE8EB"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  const usersData = {
    labels,
    datasets: [
      {
        label: "",
        data: labels.map((_, index) => {
          if (!stats) {
            return null;
          }

          const foundStat = stats.newUsersPerMonth.find(
            (i) => i.month === index + 1
          );

          return foundStat?.count || 0;
        }),
        backgroundColor: "#FCBA55",
        borderColor: "#FCBA55",
      },
    ],
  };

  const activityData = {
    labels,
    datasets: [
      {
        label: "",
        data: labels.map((_, index) => {
          if (!stats) {
            return null;
          }

          const foundStat = stats.activity.find((i) => i.month === index + 1);

          return foundStat?.count || 0;
        }),
        backgroundColor: "#FCBA55",
        borderColor: "#FCBA55",
      },
    ],
  };

  const testsLabels = tests.map((m) => m.title_nl);

  const testsDataset = [
    {
      label: "Jouw werknemers",
      data: tests.map((m) => {
        if (!stats) {
          return 0;
        }

        const foundStat = stats.tests.find((s) => s.id === m.id);

        return foundStat?.total || 0;
      }),
      backgroundColor: "#72A1B0",
    },
    {
      label: "Jouw werknemers",
      data: tests.map((m) => {
        if (!stats) {
          return 0;
        }

        const foundStat = stats.tests.find((s) => s.id === m.id);

        return foundStat?.flags || 0;
      }),
      backgroundColor: "#F9A681",
    },
  ];

  const testsData = {
    labels: testsLabels,
    datasets: testsDataset,
  };

  const podcastLabels = podcasts.map((m) => m.name);

  const podcastDataset = [
    {
      label: "Jouw werknemers",
      data: podcasts.map((m) => {
        if (!stats) {
          return 0;
        }

        const foundStat = stats.podcasts.find((s) => s.id === m.id);

        return foundStat?.total || 0;
      }),
      backgroundColor: "#72A1B0",
    },
  ];

  const podcastsData = {
    labels: podcastLabels,
    datasets: podcastDataset,
  };

  return (
    <BusinessSidebar>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex w-100">
            <div
              className={`ml-auto business-select ${
                isMobile ? "w-60 mt-4" : "w-15"
              }`}
            >
              <Select
                value={
                  foundBusiness
                    ? {
                        value: foundBusiness.business.id,
                        label: foundBusiness.business.name,
                      }
                    : { value: "", label: "" }
                }
                options={connectedBusinesses.map((b) => {
                  return {
                    value: b.business.id,
                    label: b.business.name,
                  };
                })}
                onChange={(e: any) => {
                  setSelectedBusiness(e.value);
                }}
              />
            </div>
          </div>
          <div className="business-dashboard flex flex-column">
            <h2>Statistieken</h2>
            <div className="flex">
              <div className="w-45">
                <Doughnut data={data} />
              </div>
              <div className="w-45 flex justify-between ml-auto">
                <div className="stats-box max-height reverse">
                  <div className="title">Gem. tijd per module</div>
                  <div className="number">
                    {stats
                      ? Duration.fromMillis(
                          stats.modules.reduce((a, b) => a + b.mean, 0) /
                            stats.modules.length
                        ).toFormat("hh'h'mm")
                      : null}
                  </div>
                </div>
                <div className="stats-box max-height ml-5 reverse">
                  <div className="title">Gem. tijd per gebruiker</div>
                  <div className="number">
                    {stats
                      ? Duration.fromMillis(stats.meanPerUser).toFormat(
                          "hh'h'mm"
                        )
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="stats-box full mt-4">
              <div className="title">Aantal nieuwe gebruikers per maand</div>
              <div>
                <Line options={options} data={usersData} />
              </div>
            </div>
            <div className="stats-box full mt-4">
              <div className="title">
                Activiteit van gebruikers in functie van tijd
              </div>
              <div>
                <Line options={options} data={activityData} />
              </div>
            </div>
            <div className="stats-box full mt-4">
              <div className="title">Keuze testen</div>
              <div>
                <Bar options={options} data={testsData} />
              </div>
            </div>
            <div className="stats-box full mt-4">
              <div className="title">Keuze podcasts</div>
              <div>
                <Bar options={options} data={podcastsData} />
              </div>
            </div>
          </div>
        </>
      )}
    </BusinessSidebar>
  );
}
