import React, { useState } from "react";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

export function SmallSingleInputWithText({
  id,
  text,
}: {
  id: string;
  text: string;
}) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [savedInput, setSavedInput] = useState<string>("");
  const [saved, setSaved] = useState(false);

  const { t } = useTranslation("general");

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult = result.find(
        (r) => r.key === `SmallSingleInput-${id}`
      );

      if (currentResult) {
        setSavedInput(JSON.parse(currentResult.result));
      }
    });
  });

  return (
    <div className="flex items-baseline">
      <div>{text}</div>
      <div className="ml-auto">
        <input
          type="text"
          value={savedInput}
          onChange={(e) => {
            setSavedInput(e.target.value);
          }}
        />
        {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
        <div
          className="button w-fit mb-4"
          onClick={async (e) => {
            e.preventDefault();

            await addModuleExerciseResult(
              lessonId,
              `SmallSingleInput-${id}`,
              JSON.stringify(savedInput)
            );

            setSaved(true);
            setTimeout(() => {
              setSaved(false);
            }, 3000);
          }}
        >
          {t("bewaar")}
        </div>
      </div>
    </div>
  );
}
