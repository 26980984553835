import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BusinessSidebar } from "./BusinessSidebar";
import "./DocumentsPage.css";
import { HiDownload } from "react-icons/hi";
import { DashboardDocument, getDocuments } from "./api/DashboardDocumentsApi";
import MailtemplatesIcon from "./img/mailtemplates.svg";
import PracticesIcon from "./img/practices.svg";
import DraaiboekIcon from "./img/draaiboek.svg";
import BrochuresIcon from "./img/brochures.svg";
import VisualsIcon from "./img/visuals.svg";
import ActionIcon from "./img/action.svg";

export function DocumentsPage() {
  const { type } = useParams<{ type: string }>();
  const [documents, setDocuments] = useState<DashboardDocument[]>([]);

  useEffect(() => {
    getDocuments().then((result) => {
      setDocuments(result);
    });
  }, []);

  const iconMapping: { [key: string]: string } = {
    "mail-templates": MailtemplatesIcon,
    "best-practices": PracticesIcon,
    draaiboek: DraaiboekIcon,
    brochures: BrochuresIcon,
    "visuals-and-graphics": VisualsIcon,
    varia: ActionIcon,
  };
  const title = type
    .replace(/-/g, " ")
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return (
    <BusinessSidebar>
      <div className="documents-page">
        <div className="documents-title">{title}</div>
        <div>
          {documents
            .filter((d) => d.type === type)
            .map((item) => {
              return (
                <a
                  className="download"
                  href={item.url}
                  target={"_blank"}
                  rel={"noreferrer"}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="flex items-center mb-4 w-35">
                    <img
                      className="icon"
                      src={iconMapping[item.type]}
                      alt="icon"
                    />
                    <div>{item.name}</div>
                    <HiDownload className="download-icon ml-auto" />
                  </div>
                </a>
              );
            })}
        </div>
      </div>
    </BusinessSidebar>
  );
}
