import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { createQuiz, QuizType, updateQuiz } from "../api/QuizApi";
import Select from "react-select";

const types = [
  { value: "multiple", label: "Multiple choice" },
  { value: "single", label: "Single" },
];

export function QuizForm({ quiz }: { quiz: QuizType | null }) {
  const [data, setData] = useState(quiz ? quiz.quiz : []);

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Redirect to={"/dashboard/quizzes"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const updatedBusiness = {
            quiz: data
              .filter((q) => q.questionNL.trim() !== "")
              .map((d) => {
                return {
                  ...d,
                  optionsNL: d.optionsNL.filter(
                    (q) => q.label.trim() !== "" && q.value.trim() !== ""
                  ),
                  optionsFR: d.optionsFR.filter(
                    (q) => q.label.trim() !== "" && q.value.trim() !== ""
                  ),
                  optionsEN: d.optionsEN.filter(
                    (q) => q.label.trim() !== "" && q.value.trim() !== ""
                  ),
                };
              }),
          };

          if (quiz) {
            updateQuiz({
              ...updatedBusiness,
              id: quiz.id,
            }).then(async (r) => {
              setSaved(true);
            });
          } else {
            createQuiz({
              id: "",
              ...updatedBusiness,
            }).then(async (r) => {
              setSaved(true);
            });
          }
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              {data.map((item, index) => {
                const foundType = types.find((t) => t.value === item.type);

                return (
                  <div>
                    <label>Vraag NL</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Naam"
                      value={item.questionNL}
                      onChange={(input) => {
                        setData(
                          data.map((d, i) => {
                            if (i === index) {
                              return {
                                ...d,
                                questionNL: input.target.value,
                              };
                            }

                            return d;
                          })
                        );
                      }}
                      required={true}
                    />
                    <label>Vraag EN</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Naam"
                      value={item.questionEN}
                      onChange={(input) => {
                        setData(
                          data.map((d, i) => {
                            if (i === index) {
                              return {
                                ...d,
                                questionEN: input.target.value,
                              };
                            }

                            return d;
                          })
                        );
                      }}
                    />
                    <label>Vraag FR</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Naam"
                      value={item.questionFR}
                      onChange={(input) => {
                        setData(
                          data.map((d, i) => {
                            if (i === index) {
                              return {
                                ...d,
                                questionFR: input.target.value,
                              };
                            }

                            return d;
                          })
                        );
                      }}
                    />

                    <label>Type vraag</label>
                    <Select
                      value={foundType ? foundType : { value: "", label: "" }}
                      options={types}
                      onChange={(e: any) => {
                        setData(
                          data.map((d, i) => {
                            if (i === index) {
                              return {
                                ...d,
                                type: e.value,
                              };
                            }

                            return d;
                          })
                        );
                      }}
                    />

                    <label>Opties NL</label>
                    {item.optionsNL.map((option, optionIndex) => {
                      return (
                        <div>
                          <div>
                            <label>Naam</label>
                            <input
                              type="text"
                              className="input"
                              value={option.label}
                              onChange={(e) => {
                                setData(
                                  data.map((t, i) => {
                                    if (i === index) {
                                      return {
                                        ...t,
                                        optionsNL: item.optionsNL.map(
                                          (o, oi) => {
                                            if (oi === optionIndex) {
                                              return {
                                                ...o,
                                                label: e.target.value,
                                              };
                                            }

                                            return o;
                                          }
                                        ),
                                      };
                                    }

                                    return t;
                                  })
                                );
                              }}
                            />
                          </div>
                          <div>
                            <label>Waarde</label>
                            <input
                              type="text"
                              className="input"
                              value={option.value}
                              onChange={(e) => {
                                setData(
                                  data.map((t, i) => {
                                    if (i === index) {
                                      return {
                                        ...t,
                                        optionsNL: item.optionsNL.map(
                                          (o, oi) => {
                                            if (oi === optionIndex) {
                                              return {
                                                ...o,
                                                value: e.target.value,
                                              };
                                            }

                                            return o;
                                          }
                                        ),
                                      };
                                    }

                                    return t;
                                  })
                                );
                              }}
                            />
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                    <div
                      className="mb-4 pointer"
                      onClick={() => {
                        setData(
                          data.map((t, i) => {
                            if (i === index) {
                              return {
                                ...t,
                                optionsNL: item.optionsNL.concat({
                                  label: "",
                                  value: "",
                                }),
                              };
                            }

                            return t;
                          })
                        );
                      }}
                    >
                      + Optie NL toevoegen
                    </div>

                    <label>Opties EN</label>
                    {item.optionsEN.map((option, optionIndex) => {
                      return (
                        <div>
                          <div>
                            <label>Naam</label>
                            <input
                              type="text"
                              className="input"
                              value={option.label}
                              onChange={(e) => {
                                setData(
                                  data.map((t, i) => {
                                    if (i === index) {
                                      return {
                                        ...t,
                                        optionsEN: item.optionsEN.map(
                                          (o, oi) => {
                                            if (oi === optionIndex) {
                                              return {
                                                ...o,
                                                label: e.target.value,
                                              };
                                            }

                                            return o;
                                          }
                                        ),
                                      };
                                    }

                                    return t;
                                  })
                                );
                              }}
                            />
                          </div>
                          <div>
                            <label>Waarde</label>
                            <input
                              type="text"
                              className="input"
                              value={option.value}
                              onChange={(e) => {
                                setData(
                                  data.map((t, i) => {
                                    if (i === index) {
                                      return {
                                        ...t,
                                        optionsEN: item.optionsEN.map(
                                          (o, oi) => {
                                            if (oi === optionIndex) {
                                              return {
                                                ...o,
                                                value: e.target.value,
                                              };
                                            }

                                            return o;
                                          }
                                        ),
                                      };
                                    }

                                    return t;
                                  })
                                );
                              }}
                            />
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                    <div
                      className="mb-4 pointer"
                      onClick={() => {
                        setData(
                          data.map((t, i) => {
                            if (i === index) {
                              return {
                                ...t,
                                optionsEN: item.optionsEN.concat({
                                  label: "",
                                  value: "",
                                }),
                              };
                            }

                            return t;
                          })
                        );
                      }}
                    >
                      + Optie EN toevoegen
                    </div>

                    <label>Opties FR</label>
                    {item.optionsFR.map((option, optionIndex) => {
                      return (
                        <div>
                          <div>
                            <label>Naam</label>
                            <input
                              type="text"
                              className="input"
                              value={option.label}
                              onChange={(e) => {
                                setData(
                                  data.map((t, i) => {
                                    if (i === index) {
                                      return {
                                        ...t,
                                        optionsFR: item.optionsFR.map(
                                          (o, oi) => {
                                            if (oi === optionIndex) {
                                              return {
                                                ...o,
                                                label: e.target.value,
                                              };
                                            }

                                            return o;
                                          }
                                        ),
                                      };
                                    }

                                    return t;
                                  })
                                );
                              }}
                            />
                          </div>
                          <div>
                            <label>Waarde</label>
                            <input
                              type="text"
                              className="input"
                              value={option.value}
                              onChange={(e) => {
                                setData(
                                  data.map((t, i) => {
                                    if (i === index) {
                                      return {
                                        ...t,
                                        optionsFR: item.optionsFR.map(
                                          (o, oi) => {
                                            if (oi === optionIndex) {
                                              return {
                                                ...o,
                                                value: e.target.value,
                                              };
                                            }

                                            return o;
                                          }
                                        ),
                                      };
                                    }

                                    return t;
                                  })
                                );
                              }}
                            />
                          </div>
                          <hr />
                        </div>
                      );
                    })}
                    <div
                      className="mb-4 pointer"
                      onClick={() => {
                        setData(
                          data.map((t, i) => {
                            if (i === index) {
                              return {
                                ...t,
                                optionsFR: item.optionsFR.concat({
                                  label: "",
                                  value: "",
                                }),
                              };
                            }

                            return t;
                          })
                        );
                      }}
                    >
                      + Optie FR toevoegen
                    </div>

                    <label>Antwoord(en)</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Naam"
                      value={item.answer}
                      onChange={(input) => {
                        setData(
                          data.map((d, i) => {
                            if (i === index) {
                              return {
                                ...d,
                                answer: input.target.value,
                              };
                            }

                            return d;
                          })
                        );
                      }}
                    />
                  </div>
                );
              })}

              <button className="button" type="submit">
                {quiz ? "Quiz bijwerken" : "Quiz toevoegen"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
