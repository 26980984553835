import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { Business, getBusiness } from "../api/BusinessApi";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { BusinessForm } from "./BusinessForm";

export function EditBusiness() {
  const { businessId } = useParams<{ businessId: string }>();
  const [business, setBusiness] = useState<Business | null>(null);

  useEffectOnce(() => {
    getBusiness(businessId).then((r) => {
      setBusiness(r);
    });
  });

  if (!business) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <BusinessForm business={business} />
    </Sidebar>
  );
}
