import React from "react";
import { useTranslation } from "react-i18next";

export function RisicoTest() {
  const { i18n, t } = useTranslation("general");

  const nl = [
    "Een koffiepauze nemen",
    "Genieten van een maaltijd",
    "Je tijd nemen om wakker te worden",
    "Je tijd nemen tussen werk en naar huis gaan",
    "Een boek lezen",
    "De krant lezen",
    "Genieten van een ontspannende lunch",
    "Een goed gesprek met een vriend",
    "Wandelen",
    "Sporten",
    "Seks hebben",
    "Wandel dagelijks met de hond",
    "Neem 30 minuten tijd voor uzelf",
    "Mediteer",
    "Een relaxatieoefening doen",
    "Een spel spelen",
    "Luister naar muziek",
    "Bezoek aan een wellness of spa",
    "Neem een lange douche of bad",
    "Kies de lange weg in plaats van de snelle weg",
    "Beantwoord je telefoon wanneer het jou past",
    "Je tijd nemen om te eten",
    "Mensen observeren",
  ];

  const en = [
    "Take a coffee break",
    "Enjoying a meal",
    "Take your time to wake up",
    "Take time to switch from office to home",
    "Read a book",
    "Read the paper",
    "Have a relaxing lunch",
    "Have a nice conversation with a friend",
    "Taking a walk",
    "Do sports",
    "Have sex",
    "Walk the dog daily",
    "Take 30 minutes of time to yourself",
    "Meditate",
    "Do a relaxation exercise",
    "Playing a game",
    "Listening to music",
    "Visiting a wellness or spa",
    "Take a long shower or a bath",
    "Choose the long way in stead of the fast way",
    "Answer phone calls when it suits you",
    "Take your time to eat",
    "Observing people",
  ];

  const fr = [
    "Prendre une pause café",
    "Profiter d'un repas			",
    "Prends ton temps pour te réveiller",
    "Prenez le temps de passer du bureau à la maison",
    "Lire un livre",
    "Lire le journal",
    "Avoir un déjeuner relaxant",
    "Avoir une bonne conversation avec un ami",
    "Faire une promenade",
    "Faire des sports",
    "Avoir des relations sexuelles",
    "Promenez le chien tous les jours",
    "Prenez 30 minutes de temps pour vous",
    "Médite",
    "Faites un exercice de relaxation",
    "Jouer un jeu",
    "écouter de la musique",
    "Visiter un centre de bien-être ou un spa",
    "Prendre une longue douche ou un bain",
    "Choisissez le long chemin plutôt que le chemin rapide",
    "Répondez aux appels téléphoniques quand cela vous convient",
    "Prends ton temps pour manger",
    "Observer des gens",
  ];

  const options_nl = [
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
  ];

  const options_en = [
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
  ];

  const options_fr = [
    {
      name: "1",
      value: 1,
    },
    {
      name: "2",
      value: 2,
    },
    {
      name: "3",
      value: 3,
    },
  ];

  function calculateScore() {
    try {
      let score = 0;

      for (let i = 0; i < nl.length; i++) {
        score += parseInt(
          (document.querySelector(
            `input[name="v${i + 1}"]:checked`
          ) as HTMLInputElement).value
        );
      }

      // @ts-ignore
      document.getElementById("resultaat").style.display = "block";
      // @ts-ignore
      document.getElementById("score").innerText = score;
      // @ts-ignore
      document.getElementById("vragen").style.display = "none";
    } catch (e) {
      // @ts-ignore
      document.getElementById("error").style.display = "block";
    }
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div id="vragen" style={{ overflow: "auto", width: "100%" }}>
        <table className="table">
          <thead>
            <tr>
              <td></td>
              {options_nl.map((_, i) => {
                return (
                  <td>
                    {i18n.language === "nl" && options_nl[i].name}
                    {i18n.language === "en" && options_en[i].name}
                    {i18n.language === "fr" && options_fr[i].name}
                  </td>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {nl.map((_, i) => {
              return (
                <tr>
                  <td>
                    {i18n.language === "nl" && nl[i]}
                    {i18n.language === "en" && en[i]}
                    {i18n.language === "fr" && fr[i]}
                  </td>
                  {options_nl.map((item) => {
                    return (
                      <td>
                        <input
                          type="radio"
                          name={`v${i + 1}`}
                          value={item.value}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <span style={{ color: "red", display: "none" }} id="error">
          Gelieve alle vragen te beantwoorden
        </span>
        <div
          className="button w-fit mt-4"
          onClick={() => {
            calculateScore();
          }}
        >
          {t("calculate_score")}
        </div>
      </div>
      <div id="resultaat" style={{ width: "80%", display: "none" }}>
        <h2>
          {t("score")}
          <div id="score">0</div>
        </h2>
        <div>
          <ul>
            <li>
              {i18n.language === "nl" &&
                "Als je 40 of meer scoort, heb je genoeg tijd besteed aan ontspanning. "}
              {i18n.language === "en" &&
                "If you score 40 or more, you spent enough time relaxing."}
              {i18n.language === "fr" &&
                "Si vous obtenez 40 ou plus, vous avez passé suffisamment de temps à vous détendre. "}
            </li>
            <li>
              {i18n.language === "nl" &&
                "Als je minder dan 40 scoort, probeer dan extra tijd te nemen om bewust deel te nemen aan ontspannende activiteiten. "}
              {i18n.language === "en" &&
                "If you score less than 40, try to take extra time to consciously engage in relaxing activities."}
              {i18n.language === "fr" &&
                "Si vous obtenez moins de 40, essayez de prendre plus de temps pour vous livrer consciemment à des activités relaxantes."}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
