import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { loggedIn, login, LoginResponse } from "./api/AuthApi";
import { Dialog } from "./Dialog";
import { useTranslation } from "react-i18next";

export function ErrorSSO() {
  return (
    <div className="auth login">
      <h2>Login failed</h2>
      <div>The login method you tried didn't work, please try again.</div>
    </div>
  );
}
