import React from "react";
import { uniqueId } from "../uniqueId";
import { getCorrectUrl } from "../Utils";

export function Video({ url }: { url: string }) {
  if (url.includes("youtube.com")) {
    const params = new URLSearchParams(`?${url.split("?")[1]}`);
    return (
      <iframe
        width="560"
        height="315"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        src={`https://www.youtube.com/embed/${params.get("v")}`}
      />
    );
  }

  return (
    <div>
      <video className="fit-object" key={uniqueId()} controls={true}>
        <source src={`${getCorrectUrl(url)}`} type="video/mp4" />
      </video>
    </div>
  );
}
