import {
  Test,
  updateTest,
  createTest,
  TestType,
  listTestTypes,
  TestTemplates,
} from "../api/TestApi";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { uniqueId } from "../uniqueId";
import Select from "react-select";
import { useEffectOnce } from "react-use";
import { EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";

export function TestForm({
  test,
  update,
}: {
  test: Test | null;
  update: boolean;
}) {
  const [titleNL, setTitleNL] = useState(test ? test.title_nl : "");
  const [titleEN, setTitleEN] = useState(test ? test.title_en : "");
  const [titleFR, setTitleFR] = useState(test ? test.title_fr : "");
  const [descriptionNL, setDescriptionNL] = useState(
    test
      ? EditorState.createWithContent(
          convertFromHTML({
            htmlToEntity: (nodeName, node, createEntity) => {
              if (nodeName === "a") {
                return createEntity("LINK", "MUTABLE", { url: node.href });
              }
            },
          })(test.description_nl)
        )
      : EditorState.createEmpty()
  );
  const [descriptionEN, setDescriptionEN] = useState(
    test && test.description_en
      ? EditorState.createWithContent(
          convertFromHTML({
            htmlToEntity: (nodeName, node, createEntity) => {
              if (nodeName === "a") {
                return createEntity("LINK", "MUTABLE", { url: node.href });
              }
            },
          })(test.description_en)
        )
      : EditorState.createEmpty()
  );
  const [descriptionFR, setDescriptionFR] = useState(
    test && test.description_fr
      ? EditorState.createWithContent(
          convertFromHTML({
            htmlToEntity: (nodeName, node, createEntity) => {
              if (nodeName === "a") {
                return createEntity("LINK", "MUTABLE", { url: node.href });
              }
            },
          })(test.description_fr)
        )
      : EditorState.createEmpty()
  );
  const [options, setOptions] = useState(test ? test.options : []);
  const [scores, setScores] = useState(
    test
      ? test.testScores.map((s) => {
          return {
            ...s,
            explanation_nl: s.explanation_nl
              ? EditorState.createWithContent(
                  convertFromHTML({
                    htmlToEntity: (nodeName, node, createEntity) => {
                      if (nodeName === "a") {
                        return createEntity("LINK", "MUTABLE", {
                          url: node.href,
                        });
                      }
                    },
                  })(s.explanation_nl)
                )
              : EditorState.createEmpty(),
            explanation_en: s.explanation_en
              ? EditorState.createWithContent(
                  convertFromHTML({
                    htmlToEntity: (nodeName, node, createEntity) => {
                      if (nodeName === "a") {
                        return createEntity("LINK", "MUTABLE", {
                          url: node.href,
                        });
                      }
                    },
                  })(s.explanation_en)
                )
              : EditorState.createEmpty(),
            explanation_fr: s.explanation_fr
              ? EditorState.createWithContent(
                  convertFromHTML({
                    htmlToEntity: (nodeName, node, createEntity) => {
                      if (nodeName === "a") {
                        return createEntity("LINK", "MUTABLE", {
                          url: node.href,
                        });
                      }
                    },
                  })(s.explanation_fr)
                )
              : EditorState.createEmpty(),
          };
        })
      : []
  );
  const [statements, setStatements] = useState(test ? test.statements : []);
  const [groups, setGroups] = useState(test ? test.groups : []);
  const [testType, setTestType] = useState(test ? test.testType : "");
  const [testTemplate, setTestTemplate] = useState(
    test ? test.template : TestTemplates.DEFAULT
  );
  const [badScore, setBadScore] = useState(test ? test.badScore : 0);
  const [badScoreType, setBadScoreType] = useState(
    test ? test.badScoreType : "lt"
  );
  const [saved, setSaved] = useState(false);
  const [testTypes, setTestTypes] = useState<TestType[]>([]);

  useEffectOnce(() => {
    listTestTypes().then((result) => {
      setTestTypes(result);
    });
  });

  if (saved) {
    return <Redirect to={"/dashboard/tests"} push />;
  }

  const foundTestType = testTypes.find((t) => t.id === testType);

  return (
    <form
      onSubmit={(e) => {
        // TODO checks on fields
        e.preventDefault();
        const updatedTest = {
          title_nl: titleNL,
          title_en: titleEN,
          title_fr: titleFR,
          description_nl: convertToHTML({
            entityToHTML: (entity, originalText) => {
              if (entity.type === "LINK") {
                return (
                  <a href={entity.data.url} target="_blank">
                    {originalText}
                  </a>
                );
              }
              return originalText;
            },
          })(descriptionNL.getCurrentContent()),
          description_en: convertToHTML({
            entityToHTML: (entity, originalText) => {
              if (entity.type === "LINK") {
                return (
                  <a href={entity.data.url} target="_blank">
                    {originalText}
                  </a>
                );
              }
              return originalText;
            },
          })(descriptionEN.getCurrentContent()),
          description_fr: convertToHTML({
            entityToHTML: (entity, originalText) => {
              if (entity.type === "LINK") {
                return (
                  <a href={entity.data.url} target="_blank">
                    {originalText}
                  </a>
                );
              }
              return originalText;
            },
          })(descriptionFR.getCurrentContent()),
          options: options.filter((option) => option.title_nl !== ""),
          testScores: scores
            .map((s) => {
              return {
                ...s,
                explanation_nl: convertToHTML({
                  entityToHTML: (entity, originalText) => {
                    if (entity.type === "LINK") {
                      return (
                        <a href={entity.data.url} target="_blank">
                          {originalText}
                        </a>
                      );
                    }
                    return originalText;
                  },
                })(s.explanation_nl.getCurrentContent()),
                explanation_en: convertToHTML({
                  entityToHTML: (entity, originalText) => {
                    if (entity.type === "LINK") {
                      return (
                        <a href={entity.data.url} target="_blank">
                          {originalText}
                        </a>
                      );
                    }
                    return originalText;
                  },
                })(s.explanation_en.getCurrentContent()),
                explanation_fr: convertToHTML({
                  entityToHTML: (entity, originalText) => {
                    if (entity.type === "LINK") {
                      return (
                        <a href={entity.data.url} target="_blank">
                          {originalText}
                        </a>
                      );
                    }
                    return originalText;
                  },
                })(s.explanation_fr.getCurrentContent()),
              };
            })
            .filter((score) => score.explanation_nl !== ""),
          statements: statements.filter((option) => option.title_nl !== ""),
          groups: groups.filter((option) => option.title_nl !== ""),
          deleted: false,
          testType: testType,
          template: testTemplate,
          badScore: badScore,
          badScoreType: badScoreType,
        };
        if (update && test) {
          updateTest({
            ...updatedTest,
            id: test.id,
          }).then((r) => {
            if (r) {
              setSaved(true);
            }
          });
        } else {
          createTest({
            id: "",
            ...updatedTest,
          }).then((r) => {
            if (r) {
              setSaved(true);
            }
          });
        }
      }}
    >
      <div className="flex justify-evenly pb-4">
        <div className="w-25">
          <div>
            <h4>Algemene info</h4>
            <label>Titel in het Nederlands</label>
            <input
              className="input"
              type="text"
              placeholder="Titel"
              value={titleNL}
              onChange={(input) => {
                setTitleNL(input.target.value);
              }}
              required={true}
            />
            <label>Beschrijving in het Nederlands</label>
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                minHeight: "400px",
                background: "white",
              }}
            >
              <Editor
                editorState={descriptionNL}
                onEditorStateChange={(item) => {
                  setDescriptionNL(item);
                }}
              />
            </div>

            <label>Titel in het Engels</label>
            <input
              className="input"
              type="text"
              placeholder="Titel"
              value={titleEN}
              onChange={(input) => {
                setTitleEN(input.target.value);
              }}
            />
            <label>Beschrijving in het Engels</label>
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                minHeight: "400px",
                background: "white",
              }}
            >
              <Editor
                editorState={descriptionEN}
                onEditorStateChange={(item) => {
                  setDescriptionEN(item);
                }}
              />
            </div>

            <label>Titel in het Frans</label>
            <input
              className="input"
              type="text"
              placeholder="Titel"
              value={titleFR}
              onChange={(input) => {
                setTitleFR(input.target.value);
              }}
            />
            <label>Beschrijving in het Frans</label>
            <div
              style={{
                border: "1px solid black",
                padding: "2px",
                minHeight: "400px",
                background: "white",
              }}
            >
              <Editor
                editorState={descriptionFR}
                onEditorStateChange={(item) => {
                  setDescriptionFR(item);
                }}
              />
            </div>

            <label>Kies test type</label>
            <Select
              className="input"
              placeholder={"Type"}
              value={
                foundTestType
                  ? { label: foundTestType.titleNL, value: foundTestType.id }
                  : { label: "", value: "" }
              }
              options={testTypes.map((g) => {
                return {
                  value: g.id,
                  label: g.titleNL,
                };
              })}
              onChange={(e: any) => {
                setTestType(e.value);
              }}
            />

            <label>Kies template</label>
            <Select
              className="input"
              placeholder={"Template"}
              value={{ label: testTemplate, value: testTemplate }}
              options={Object.values(TestTemplates).map((g) => {
                return {
                  value: g.valueOf(),
                  label: g.valueOf(),
                };
              })}
              onChange={(e: any) => {
                setTestTemplate(e.value);
              }}
            />

            <label>Red flag type</label>
            <Select
              className="input"
              placeholder={"Red flag type"}
              value={{ label: badScoreType, value: badScoreType }}
              options={[
                { value: "lt", label: "lt" },
                { value: "gt", label: "gt" },
              ]}
              onChange={(e: any) => {
                setBadScoreType(e.value);
              }}
            />

            <label>Red flag score</label>
            <input
              className="input"
              type="number"
              placeholder="Red flag score"
              value={badScore}
              onChange={(input) => {
                setBadScore(parseInt(input.target.value));
              }}
            />

            <button className="button" type="submit">
              {update ? "Test bijwerken" : "Test toevoegen"}
            </button>
          </div>
        </div>
        <div className="w-25 ml-5">
          <div>
            <h4>Groepen</h4>
            {groups.map((group, index) => {
              return (
                <div key={index}>
                  {index > 0 ? <div className="hr" /> : null}
                  <div className="flex">
                    <label>Titel in het Nederlands</label>
                    <div
                      className="delete ml-auto"
                      onClick={() => {
                        setGroups(groups.filter((g) => g.id !== group.id));
                      }}
                    >
                      <FaTimes />
                    </div>
                  </div>
                  <input
                    className="input"
                    type="text"
                    placeholder="Titel"
                    value={group.title_nl}
                    onChange={(input) => {
                      setGroups(
                        groups.map((g) => {
                          if (g.id === group.id) {
                            return {
                              ...g,
                              title_nl: input.target.value,
                            };
                          }

                          return g;
                        })
                      );
                    }}
                  />
                  <label>Titel in het Engels</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Titel"
                    value={group.title_en}
                    onChange={(input) => {
                      setGroups(
                        groups.map((g) => {
                          if (g.id === group.id) {
                            return {
                              ...g,
                              title_en: input.target.value,
                            };
                          }

                          return g;
                        })
                      );
                    }}
                  />
                  <label>Titel in het Frans</label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Titel"
                    value={group.title_fr}
                    onChange={(input) => {
                      setGroups(
                        groups.map((g) => {
                          if (g.id === group.id) {
                            return {
                              ...g,
                              title_fr: input.target.value,
                            };
                          }

                          return g;
                        })
                      );
                    }}
                  />
                </div>
              );
            })}
            <div
              className="pointer yellow"
              onClick={() => {
                setGroups(
                  groups.concat({
                    id: uniqueId(),
                    title_nl: "",
                    title_en: "",
                    title_fr: "",
                  })
                );
              }}
            >
              + Groep toevoegen
            </div>
          </div>
          <div className="hr" />
          <div>
            <h4>Scores</h4>
            {scores.map((score, index) => {
              return (
                <div key={index}>
                  {index > 0 ? <div className="hr" /> : null}
                  <div className="flex">
                    <label>Uitleg in het Nederlands</label>
                    <div
                      className="delete ml-auto"
                      onClick={() => {
                        setScores(scores.filter((s) => s.id !== score.id));
                      }}
                    >
                      <FaTimes />
                    </div>
                  </div>
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      minHeight: "400px",
                      background: "white",
                    }}
                  >
                    <Editor
                      editorState={score.explanation_nl}
                      onEditorStateChange={(state) => {
                        setScores(
                          scores.map((s) => {
                            if (s.id === score.id) {
                              return {
                                ...s,
                                explanation_nl: state,
                              };
                            }

                            return s;
                          })
                        );
                      }}
                    />
                  </div>
                  <label>Uitleg in het Engels</label>
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      minHeight: "400px",
                      background: "white",
                    }}
                  >
                    <Editor
                      editorState={score.explanation_en}
                      onEditorStateChange={(state) => {
                        setScores(
                          scores.map((s) => {
                            if (s.id === score.id) {
                              return {
                                ...s,
                                explanation_en: state,
                              };
                            }

                            return s;
                          })
                        );
                      }}
                    />
                  </div>
                  <label>Uitleg in het Frans</label>
                  <div
                    style={{
                      border: "1px solid black",
                      padding: "2px",
                      minHeight: "400px",
                      background: "white",
                    }}
                  >
                    <Editor
                      editorState={score.explanation_fr}
                      onEditorStateChange={(state) => {
                        setScores(
                          scores.map((s) => {
                            if (s.id === score.id) {
                              return {
                                ...s,
                                explanation_fr: state,
                              };
                            }

                            return s;
                          })
                        );
                      }}
                    />
                  </div>
                  <label>Lager dan voorwaarde</label>
                  <input
                    className="input"
                    type="number"
                    placeholder="Lager dan"
                    value={score.ltScore}
                    min={0}
                    onChange={(input) => {
                      setScores(
                        scores.map((s) => {
                          if (s.id === score.id) {
                            return {
                              ...s,
                              ltScore: parseInt(input.target.value),
                            };
                          }

                          return s;
                        })
                      );
                    }}
                  />
                  <Select
                    className="input"
                    placeholder={"Groep"}
                    value={
                      score.groupId
                        ? {
                            value: score.groupId,
                            label: groups.find((g) => g.id === score.groupId)!
                              .title_nl,
                          }
                        : null
                    }
                    options={groups
                      .filter((g) => g.title_nl !== "")
                      .map((g) => {
                        return {
                          value: g.id,
                          label: g.title_nl,
                        };
                      })}
                    onChange={(e: any) => {
                      setScores(
                        scores.map((s) => {
                          if (score.id === s.id) {
                            return {
                              ...s,
                              groupId: e.value,
                            };
                          }

                          return s;
                        })
                      );
                    }}
                  />
                </div>
              );
            })}
            <div
              className="pointer yellow"
              onClick={() => {
                setScores(
                  scores.concat({
                    id: uniqueId(),
                    explanation_fr: EditorState.createEmpty(),
                    explanation_en: EditorState.createEmpty(),
                    explanation_nl: EditorState.createEmpty(),
                    ltScore: 0,
                    groupId: "",
                  })
                );
              }}
            >
              + Score toevoegen
            </div>
          </div>
        </div>
        <div className="w-25 ml-5">
          <div>
            <h4>Opties</h4>
            {options
              .sort((a, b) => a.position - b.position)
              .map((option, index) => {
                return (
                  <div key={index}>
                    {index > 0 ? <div className="hr" /> : null}
                    <div className="flex">
                      <label>Titel in het Nederlands</label>
                      <div
                        className="delete ml-auto"
                        onClick={() => {
                          setOptions(options.filter((o) => o.id !== option.id));
                        }}
                      >
                        <FaTimes />
                      </div>
                    </div>
                    <input
                      className="input"
                      type="text"
                      placeholder="Titel"
                      value={option.title_nl}
                      onChange={(input) => {
                        setOptions(
                          options.map((o) => {
                            if (o.id === option.id) {
                              return {
                                ...o,
                                title_nl: input.target.value,
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                    <label>Titel in het Engels</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Titel"
                      value={option.title_en}
                      onChange={(input) => {
                        setOptions(
                          options.map((o) => {
                            if (o.id === option.id) {
                              return {
                                ...o,
                                title_en: input.target.value,
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                    <label>Titel in het Frans</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Titel"
                      value={option.title_fr}
                      onChange={(input) => {
                        setOptions(
                          options.map((o) => {
                            if (o.id === option.id) {
                              return {
                                ...o,
                                title_fr: input.target.value,
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                    <label>Score indien gekozen</label>
                    <input
                      className="input"
                      type="number"
                      placeholder="Punten"
                      value={option.score}
                      onChange={(input) => {
                        setOptions(
                          options.map((o) => {
                            if (o.id === option.id) {
                              return {
                                ...o,
                                score: parseInt(input.target.value),
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                    <label>Volgorde</label>
                    <input
                      className="input"
                      type="number"
                      placeholder="Volgorde"
                      value={option.position}
                      onChange={(input) => {
                        setOptions(
                          options.map((o) => {
                            if (o.id === option.id) {
                              return {
                                ...o,
                                position: parseInt(input.target.value),
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                    <label>Groep</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Groep"
                      value={option.groupId}
                      onChange={(input) => {
                        setOptions(
                          options.map((o) => {
                            if (o.id === option.id) {
                              return {
                                ...o,
                                groupId: input.target.value,
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                  </div>
                );
              })}
            <div
              className="pointer yellow"
              onClick={() => {
                setOptions(
                  options.concat({
                    id: uniqueId(),
                    title_nl: "",
                    title_en: "",
                    title_fr: "",
                    score: 0,
                    position: options.length,
                    groupId: "standaard",
                  })
                );
              }}
            >
              + Optie toevoegen
            </div>
          </div>
          <div className="hr" />
          <div>
            <h4>Stellingen</h4>
            {statements
              .sort((a, b) => a.position - b.position)
              .map((statement, index) => {
                return (
                  <div key={index}>
                    {index > 0 ? <div className="hr" /> : null}
                    <div className="flex">
                      <label>Titel in het Nederlands</label>
                      <div
                        className="delete ml-auto"
                        onClick={() => {
                          setStatements(
                            statements.filter((s) => s.id !== statement.id)
                          );
                        }}
                      >
                        <FaTimes />
                      </div>
                    </div>

                    <input
                      className="input"
                      type="text"
                      placeholder="Titel"
                      value={statement.title_nl}
                      onChange={(input) => {
                        setStatements(
                          statements.map((s) => {
                            if (statement.id === s.id) {
                              return {
                                ...s,
                                title_nl: input.target.value,
                              };
                            }

                            return s;
                          })
                        );
                      }}
                    />
                    <label>Titel in het Engels</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Titel"
                      value={statement.title_en}
                      onChange={(input) => {
                        setStatements(
                          statements.map((s) => {
                            if (statement.id === s.id) {
                              return {
                                ...s,
                                title_en: input.target.value,
                              };
                            }

                            return s;
                          })
                        );
                      }}
                    />
                    <label>Titel in het Frans</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Titel"
                      value={statement.title_fr}
                      onChange={(input) => {
                        setStatements(
                          statements.map((s) => {
                            if (statement.id === s.id) {
                              return {
                                ...s,
                                title_fr: input.target.value,
                              };
                            }

                            return s;
                          })
                        );
                      }}
                    />
                    <Select
                      className="input"
                      placeholder={"Groep"}
                      value={
                        statement.groupId
                          ? {
                              value: statement.groupId,
                              label: groups.find(
                                (g) => g.id === statement.groupId
                              )!.title_nl,
                            }
                          : null
                      }
                      options={groups
                        .filter((g) => g.title_nl !== "")
                        .map((g) => {
                          return {
                            value: g.id,
                            label: g.title_nl,
                          };
                        })}
                      onChange={(e: any) => {
                        setStatements(
                          statements.map((s) => {
                            if (statement.id === s.id) {
                              return {
                                ...s,
                                groupId: e.value,
                              };
                            }

                            return s;
                          })
                        );
                      }}
                    />
                    <label>Volgorde</label>
                    <input
                      className="input"
                      type="number"
                      placeholder="Volgorde"
                      value={statement.position}
                      onChange={(input) => {
                        setStatements(
                          statements.map((o) => {
                            if (o.id === statement.id) {
                              return {
                                ...o,
                                position: parseInt(input.target.value),
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                    <label>Optie groep</label>
                    <input
                      className="input"
                      type="text"
                      placeholder="Optie groep"
                      value={statement.optionGroupId}
                      onChange={(input) => {
                        setStatements(
                          statements.map((o) => {
                            if (o.id === statement.id) {
                              return {
                                ...o,
                                optionGroupId: input.target.value,
                              };
                            }

                            return o;
                          })
                        );
                      }}
                    />
                  </div>
                );
              })}
            <div
              className="pointer yellow"
              onClick={() => {
                setStatements(
                  statements.concat({
                    id: uniqueId(),
                    title_nl: "",
                    title_en: "",
                    title_fr: "",
                    groupId: "",
                    position: statements.length,
                    optionGroupId: "standaard",
                  })
                );
              }}
            >
              + Stelling toevoegen
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
