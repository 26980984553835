import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";
import { useEffectOnce } from "react-use";
import { getListItems, ListItem } from "../api/ListApi";
import { Checkbox } from "../Checkbox";
import { useTranslation } from "react-i18next";

export function ItemSelection({ id }: { id: string }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [selected, setSelected] = useState<string[]>([]);
  const [items, setItems] = useState<ListItem[]>([]);
  const [saved, setSaved] = useState(false);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getListItems(id).then((result) => {
      setItems(result);
    });
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult = result.find((r) => r.key === `ItemSelection-${id}`);

      if (currentResult) {
        setSelected(JSON.parse(currentResult.result));
      }
    });
  });

  useEffect(() => {
    getListItems(id).then((result) => {
      setItems(result);
    });
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult = result.find((r) => r.key === `ItemSelection-${id}`);

      if (currentResult) {
        setSelected(JSON.parse(currentResult.result));
      }
    });
  }, [id]);

  return (
    <div>
      <div className="flex wrap">
        {items.map((item) => {
          return (
            <div className="mr-5">
              <Checkbox
                key={item.id}
                title={
                  i18n.language === "nl"
                    ? item.nameNL
                    : i18n.language === "en"
                    ? item.nameEN
                    : item.nameFR
                }
                value={selected.includes(item.id)}
                onClick={(clicked) => {
                  if (clicked) {
                    setSelected(selected.concat(item.id));
                  } else {
                    setSelected(selected.filter((i) => i !== item.id));
                  }
                }}
              />
            </div>
          );
        })}
      </div>

      {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
      <div
        className="button w-fit mb-4"
        onClick={async (e) => {
          e.preventDefault();

          await addModuleExerciseResult(
            lessonId,
            `ItemSelection-${id}`,
            JSON.stringify(selected)
          );

          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        }}
      >
        {t("bewaar")}
      </div>
    </div>
  );
}
