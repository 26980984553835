import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { createPodcast, Podcast, updatePodcast } from "../api/PodcastApi";
import { addMedia } from "../api/MediaApi";

export function PodcastForm({ podcast }: { podcast: Podcast | null }) {
  const [name, setName] = useState(podcast ? podcast.name : "");
  const [description, setDescription] = useState(
    podcast ? podcast.description : ""
  );
  const [image, setImage] = useState(podcast ? podcast.image : "");
  const [nameEN, setNameEN] = useState(podcast ? podcast.nameEN : "");
  const [descriptionEN, setDescriptionEN] = useState(
    podcast ? podcast.descriptionEN : ""
  );
  const [audioEN, setAudioEN] = useState(podcast ? podcast.audioEN : "");
  const [nameFR, setNameFR] = useState(podcast ? podcast.nameFR : "");
  const [descriptionFR, setDescriptionFR] = useState(
    podcast ? podcast.descriptionFR : ""
  );
  const [audioFR, setAudioFR] = useState(podcast ? podcast.audioFR : "");
  const [audio, setAudio] = useState(podcast ? podcast.audio : "");

  const [selectedImageFile, setSelectedImageFile] = useState<File | null>();
  const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>();
  const [selectedAudioFileEN, setSelectedAudioFileEN] = useState<File | null>();
  const [selectedAudioFileFR, setSelectedAudioFileFR] = useState<File | null>();

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Redirect to={"/dashboard/podcasts"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          let updatedBusiness = {
            name: name,
            description: description,
            image: image,
            audio: audio,
            nameEN: nameEN,
            descriptionEN: descriptionEN,
            audioEN: audioEN,
            nameFR: nameFR,
            descriptionFR: descriptionFR,
            audioFR: audioFR,
          };

          if (selectedImageFile) {
            const pathResult = await addMedia(selectedImageFile);

            if (
              pathResult &&
              typeof pathResult !== "string" &&
              pathResult.path
            ) {
              updatedBusiness = {
                ...updatedBusiness,
                image: pathResult.path,
              };
            }
          }

          if (selectedAudioFile) {
            const pathResult = await addMedia(selectedAudioFile);

            if (
              pathResult &&
              typeof pathResult !== "string" &&
              pathResult.path
            ) {
              updatedBusiness = {
                ...updatedBusiness,
                audio: pathResult.path,
              };
            }
          }

          if (selectedAudioFileEN) {
            const pathResult = await addMedia(selectedAudioFileEN);

            if (
              pathResult &&
              typeof pathResult !== "string" &&
              pathResult.path
            ) {
              updatedBusiness = {
                ...updatedBusiness,
                audioEN: pathResult.path,
              };
            }
          }

          if (selectedAudioFileFR) {
            const pathResult = await addMedia(selectedAudioFileFR);

            if (
              pathResult &&
              typeof pathResult !== "string" &&
              pathResult.path
            ) {
              updatedBusiness = {
                ...updatedBusiness,
                audioFR: pathResult.path,
              };
            }
          }

          if (podcast) {
            updatePodcast({
              ...updatedBusiness,
              id: podcast.id,
            }).then(async (r) => {
              setSaved(true);
            });
          } else {
            createPodcast({
              id: "",
              ...updatedBusiness,
            }).then(async (r) => {
              setSaved(true);
            });
          }
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              <h4>Algemene info</h4>
              <label>Naam</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={name}
                onChange={(input) => {
                  setName(input.target.value);
                }}
                required={true}
              />
              <label>Beschrijving</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={description}
                onChange={(input) => {
                  setDescription(input.target.value);
                }}
                required={true}
              />
              <label>Afbeelding</label>
              <input
                type="file"
                className="input"
                placeholder="Upload afbeelding"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setSelectedImageFile(e.target.files[0]);
                  }
                }}
              />
              <label>Audio</label>
              <input
                type="file"
                className="input"
                placeholder="Upload audio"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setSelectedAudioFile(e.target.files[0]);
                  }
                }}
              />

              <label>Naam EN</label>
              <input
                className="input"
                type="text"
                placeholder="Naam EN"
                value={nameEN}
                onChange={(input) => {
                  setNameEN(input.target.value);
                }}
              />
              <label>Beschrijving EN</label>
              <input
                className="input"
                type="text"
                placeholder="Beschrijving EN"
                value={descriptionEN}
                onChange={(input) => {
                  setDescriptionEN(input.target.value);
                }}
              />
              <label>Audio EN</label>
              <input
                type="file"
                className="input"
                placeholder="Upload audio EN"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setSelectedAudioFileEN(e.target.files[0]);
                  }
                }}
              />

              <label>Naam FR</label>
              <input
                className="input"
                type="text"
                placeholder="Naam FR"
                value={nameFR}
                onChange={(input) => {
                  setNameFR(input.target.value);
                }}
              />
              <label>Beschrijving FR</label>
              <input
                className="input"
                type="text"
                placeholder="Beschrijving FR"
                value={descriptionFR}
                onChange={(input) => {
                  setDescriptionFR(input.target.value);
                }}
              />
              <label>Audio FR</label>
              <input
                type="file"
                className="input"
                placeholder="Upload audio FR"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setSelectedAudioFileFR(e.target.files[0]);
                  }
                }}
              />

              <button className="button" type="submit">
                {podcast ? "Podcast bijwerken" : "Podcast toevoegen"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
