import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Testimonial = {
  id: string;
  text: string;
  name: string;
  score: number;
};

export async function getTestimonials(): Promise<Testimonial[]> {
  const result = await fetch(`${API_ENDPOINT}/testimonials`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.tests) {
    return [];
  }

  return result.tests;
}

export async function createTestimonial(
  text: string,
  name: string,
  score: number
): Promise<Testimonial> {
  const result = await fetch(`${API_ENDPOINT}/testimonials`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      text: text,
      name: name,
      score: score,
    }),
  }).then((r) => r.json());

  return result;
}

export async function updateTestimonial(
  id: string,
  text: string,
  name: string,
  score: number
): Promise<Testimonial> {
  const result = await fetch(`${API_ENDPOINT}/testimonials/${id}`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      text: text,
      name: name,
      score: score,
    }),
  }).then((r) => r.json());

  return result;
}

export async function getTestimonial(id: string): Promise<Testimonial> {
  const result = await fetch(`${API_ENDPOINT}/testimonials/${id}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result;
}

export async function deleteTestimonial(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/testimonials/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((r) => r.json());
}
