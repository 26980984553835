import React, { useState } from "react";
import Select from "react-select";
import { useEffectOnce } from "react-use";
import { BusinessSidebar } from "../BusinessSidebar";
import "./BusinessDocuments.css";
import ReportPink from "../img/report_pink.svg";
import ReportPurple from "../img/report_purple.svg";
import ReportBlue from "../img/report_blue.svg";
import ReportYellow from "../img/report_yellow.svg";
import { ReportBox } from "../ReportBox";
import { Quarter } from "../QuarterlyReport/Quarter";
import { ConnectedBusiness, getConnectedBusinesses } from "../api/BusinessApi";
import { Loading } from "../Loading";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export function BusinessReports() {
  const [loading, setLoading] = useState(true);
  const [selectedYear, setSelectedYear] = useState("2023");
  const { t } = useTranslation("hr-dashboard");
  const [selectedBusinessId, setSelectedBusinessId] = useState<string | null>(
    null
  );
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);
  const [premium, setPremium] = useState(false);

  const selectedBusiness = connectedBusinesses.find(
    (cb) => cb.business.id === selectedBusinessId
  )?.business;

  useEffectOnce(() => {
    getConnectedBusinesses().then((businesses) => {
      setConnectedBusinesses(businesses);

      if (businesses.length > 0) {
        setSelectedBusinessId(businesses[0].business.id);
      }

      setPremium(businesses.filter((b) => b.business.type >= 1).length >= 1);

      setLoading(false);
    });
  });

  return (
    <BusinessSidebar>
      {loading || !selectedBusiness ? (
        <Loading />
      ) : (
        <>
          <div
            className={`ml-auto business-select ${
              isMobile ? "w-60 mt-4" : "w-15"
            }`}
          >
            <Select
              value={
                selectedBusiness
                  ? {
                      value: selectedBusiness.id,
                      label: selectedBusiness.name,
                    }
                  : { value: "", label: "" }
              }
              options={connectedBusinesses.map((b) => {
                return {
                  value: b.business.id,
                  label: b.business.name,
                };
              })}
              onChange={(e: any) => {
                setSelectedBusinessId(e.value);
              }}
            />
          </div>
          <div className="business-documents">
            <div className="documents-title flex">
              <div>{t("businessReports.title")}</div>
              <div className="report-year-select">
                <Select
                  value={{ value: selectedYear, label: selectedYear }}
                  options={[
                    { value: "2022", label: "2022" },
                    { value: "2023", label: "2023" },
                    { value: "2024", label: "2024" },
                  ]}
                  onChange={(e) => {
                    setSelectedYear(e?.value || "2023");
                  }}
                />
              </div>
            </div>
            <div className="boxes">
              <ReportBox
                quarter={Quarter.Q1}
                year={parseInt(selectedYear)}
                business={selectedBusiness}
                icon={ReportPink}
                locked={!premium}
                className="report-pink"
              />
              <ReportBox
                quarter={Quarter.Q2}
                year={parseInt(selectedYear)}
                business={selectedBusiness}
                icon={ReportPurple}
                locked={!premium}
                className="report-purple"
              />
              <ReportBox
                quarter={Quarter.Q3}
                year={parseInt(selectedYear)}
                business={selectedBusiness}
                icon={ReportBlue}
                locked={!premium}
                className="report-blue"
              />
              <ReportBox
                quarter={Quarter.Q4}
                year={parseInt(selectedYear)}
                business={selectedBusiness}
                icon={ReportYellow}
                locked={!premium}
                className="report-yellow"
              />
            </div>
          </div>
        </>
      )}
    </BusinessSidebar>
  );
}
