import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  createTestimonial,
  Testimonial,
  updateTestimonial,
} from "../api/TestimonialsApi";

export function TestimonialForm({
  testimonial,
}: {
  testimonial?: Testimonial;
}) {
  const [text, setText] = useState("");
  const [name, setName] = useState("");
  const [score, setScore] = useState(5);

  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (!testimonial) {
      return;
    }

    setText(testimonial.text);
    setName(testimonial.name);
    setScore(testimonial.score);
  }, [testimonial]);

  if (saved) {
    return <Redirect to={"/dashboard/testimonials"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          if (testimonial) {
            updateTestimonial(testimonial.id, text, name, score).then(
              async (r) => {
                setSaved(true);
              }
            );
          } else {
            createTestimonial(text, name, score).then(async (r) => {
              setSaved(true);
            });
          }
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              <h4>Algemene info</h4>
              <label>Text</label>
              <textarea
                className="input"
                placeholder="Text"
                maxLength={300}
                rows={6}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
              <label>Name</label>
              <input
                type="text"
                className="input"
                placeholder="Text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <label>Score</label>
              <input
                type="number"
                className="input"
                placeholder="Text"
                max={5}
                min={0}
                value={score}
                onChange={(e) => {
                  setScore(parseInt(e.target.value));
                }}
              />

              <button className="button" type="submit">
                Testimonial {testimonial ? "updaten" : "toevoegen"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
