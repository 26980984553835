import React, { useState } from "react";
import Banner2 from "./img/banner2.png";
import Vlek from "./img/vlek.png";
import Check from "./img/check.png";
import "./Mindlab.css";
import Onboarding1 from "./img/onboarding1.png";
import Onboarding2 from "./img/onboarding2.png";
import Onboarding3 from "./img/onboarding3.png";
import Onboarding4 from "./img/onboarding4.png";
import Onboarding5 from "./img/onboarding5.png";
import { useTranslation } from "react-i18next";
import { getLogos, Logo } from "./api/LogosApi";
import { getTestimonials, Testimonial } from "./api/TestimonialsApi";
import { useEffectOnce } from "react-use";
import Slider from "react-slick";
import { getPartners, Partner } from "./api/PartnersApi";
import { getCorrectUrl } from "./Utils";

export function Mindlab() {
  const data: any = {
    "50": { collegas: 9, verloren: 216, kost: 72.224 },
    "60": { collegas: 11, verloren: 259, kost: 86.669 },
    "70": { collegas: 13, verloren: 302, kost: 101.114 },
    "80": { collegas: 15, verloren: 345, kost: 115.559 },
    "90": { collegas: 17, verloren: 388, kost: 130.004 },
    "100": { collegas: 19, verloren: 431, kost: 144.449 },
    "110": { collegas: 21, verloren: 474, kost: 158.894 },
    "120": { collegas: 23, verloren: 517, kost: 173.339 },
    "130": { collegas: 25, verloren: 560, kost: 187.784 },
    "140": { collegas: 27, verloren: 603, kost: 202.229 },
    "150": { collegas: 29, verloren: 646, kost: 216.674 },
    "160": { collegas: 31, verloren: 689, kost: 231.119 },
    "170": { collegas: 33, verloren: 732, kost: 245.564 },
    "180": { collegas: 35, verloren: 775, kost: 260.009 },
    "190": { collegas: 37, verloren: 818, kost: 274.454 },
    "200": { collegas: 39, verloren: 861, kost: 288.899 },
    "210": { collegas: 41, verloren: 904, kost: 303.344 },
    "220": { collegas: 43, verloren: 947, kost: 317.789 },
    "230": { collegas: 45, verloren: 990, kost: 332.234 },
    "240": { collegas: 47, verloren: 1033, kost: 346.679 },
    "250": { collegas: 49, verloren: 1076, kost: 361.124 },
    "260": { collegas: 51, verloren: 1119, kost: 375.569 },
    "270": { collegas: 53, verloren: 1162, kost: 390.014 },
    "280": { collegas: 55, verloren: 1205, kost: 404.459 },
    "290": { collegas: 57, verloren: 1248, kost: 418.904 },
    "300": { collegas: 59, verloren: 1291, kost: 433.349 },
    "310": { collegas: 61, verloren: 1334, kost: 447.794 },
    "320": { collegas: 63, verloren: 1377, kost: 462.239 },
    "330": { collegas: 65, verloren: 1420, kost: 476.684 },
    "340": { collegas: 67, verloren: 1463, kost: 491.129 },
    "350": { collegas: 69, verloren: 1506, kost: 505.574 },
    "360": { collegas: 71, verloren: 1549, kost: 520.019 },
    "370": { collegas: 73, verloren: 1592, kost: 534.464 },
    "380": { collegas: 75, verloren: 1635, kost: 548.909 },
    "390": { collegas: 77, verloren: 1678, kost: 563.354 },
    "400": { collegas: 79, verloren: 1721, kost: 577.799 },
    "410": { collegas: 81, verloren: 1764, kost: 592.244 },
    "420": { collegas: 83, verloren: 1807, kost: 606.689 },
    "430": { collegas: 85, verloren: 1850, kost: 621.134 },
    "440": { collegas: 87, verloren: 1893, kost: 635.579 },
    "450": { collegas: 89, verloren: 1936, kost: 650.024 },
    "460": { collegas: 91, verloren: 1979, kost: 664.469 },
    "470": { collegas: 93, verloren: 2022, kost: 678.914 },
    "480": { collegas: 95, verloren: 2065, kost: 693.359 },
    "490": { collegas: 97, verloren: 2108, kost: 707.804 },
    "500": { collegas: 99, verloren: 2151, kost: 722.249 },
    "510": { collegas: 101, verloren: 2194, kost: 736.694 },
    "520": { collegas: 103, verloren: 2237, kost: 751.139 },
    "530": { collegas: 105, verloren: 2280, kost: 765.584 },
    "540": { collegas: 107, verloren: 2323, kost: 780.029 },
    "550": { collegas: 109, verloren: 2366, kost: 794.474 },
    "560": { collegas: 111, verloren: 2409, kost: 808.919 },
    "570": { collegas: 113, verloren: 2452, kost: 823.364 },
    "580": { collegas: 115, verloren: 2495, kost: 837.809 },
    "590": { collegas: 117, verloren: 2538, kost: 852.254 },
    "600": { collegas: 119, verloren: 2581, kost: 866.699 },
    "610": { collegas: 121, verloren: 2624, kost: 881.144 },
    "620": { collegas: 123, verloren: 2667, kost: 895.589 },
    "630": { collegas: 125, verloren: 2710, kost: 910.034 },
    "640": { collegas: 127, verloren: 2753, kost: 924.479 },
    "650": { collegas: 129, verloren: 2796, kost: 938.924 },
    "660": { collegas: 131, verloren: 2839, kost: 953.369 },
    "670": { collegas: 133, verloren: 2882, kost: 967.814 },
    "680": { collegas: 135, verloren: 2925, kost: 982.259 },
    "690": { collegas: 137, verloren: 2968, kost: 996.704 },
    "700": { collegas: 139, verloren: 3011, kost: 1011.149 },
    "710": { collegas: 141, verloren: 3054, kost: 1025.594 },
    "720": { collegas: 143, verloren: 3097, kost: 1040.039 },
    "730": { collegas: 145, verloren: 3140, kost: 1054.484 },
    "740": { collegas: 147, verloren: 3183, kost: 1068.929 },
    "750": { collegas: 149, verloren: 3226, kost: 1083.374 },
    "760": { collegas: 151, verloren: 3269, kost: 1097.819 },
    "770": { collegas: 153, verloren: 3312, kost: 1112.264 },
    "780": { collegas: 155, verloren: 3355, kost: 1126.709 },
    "790": { collegas: 157, verloren: 3398, kost: 1141.154 },
    "800": { collegas: 159, verloren: 3441, kost: 1155.599 },
    "810": { collegas: 161, verloren: 3484, kost: 1170.044 },
    "820": { collegas: 163, verloren: 3527, kost: 1184.489 },
    "830": { collegas: 165, verloren: 3570, kost: 1198.934 },
    "840": { collegas: 167, verloren: 3613, kost: 1213.379 },
    "850": { collegas: 169, verloren: 3656, kost: 1227.824 },
    "860": { collegas: 171, verloren: 3699, kost: 1242.269 },
    "870": { collegas: 173, verloren: 3742, kost: 1256.714 },
    "880": { collegas: 175, verloren: 3785, kost: 1271.159 },
    "890": { collegas: 177, verloren: 3828, kost: 1285.604 },
    "900": { collegas: 179, verloren: 3871, kost: 1300.049 },
    "910": { collegas: 181, verloren: 3914, kost: 1314.494 },
    "920": { collegas: 183, verloren: 3957, kost: 1328.939 },
    "930": { collegas: 185, verloren: 4000, kost: 1343.384 },
    "940": { collegas: 187, verloren: 4043, kost: 1357.829 },
    "950": { collegas: 189, verloren: 4086, kost: 1372.274 },
    "960": { collegas: 191, verloren: 4129, kost: 1386.719 },
    "970": { collegas: 193, verloren: 4172, kost: 1401.164 },
    "980": { collegas: 195, verloren: 4215, kost: 1415.609 },
    "990": { collegas: 197, verloren: 4258, kost: 1430.054 },
    "1000": { collegas: 199, verloren: 4301, kost: 1444.499 },
    "1010": { collegas: 201, verloren: 4344, kost: 1458.944 },
    "1020": { collegas: 203, verloren: 4387, kost: 1473.389 },
    "1030": { collegas: 205, verloren: 4430, kost: 1487.834 },
    "1040": { collegas: 207, verloren: 4473, kost: 1502.279 },
    "1050": { collegas: 209, verloren: 4516, kost: 1516.724 },
    "1060": { collegas: 211, verloren: 4559, kost: 1531.169 },
    "1070": { collegas: 213, verloren: 4602, kost: 1545.614 },
    "1080": { collegas: 215, verloren: 4645, kost: 1560.059 },
    "1090": { collegas: 217, verloren: 4688, kost: 1574.504 },
    "1100": { collegas: 219, verloren: 4731, kost: 1588.949 },
    "1110": { collegas: 221, verloren: 4774, kost: 1603.394 },
    "1120": { collegas: 223, verloren: 4817, kost: 1617.839 },
    "1130": { collegas: 225, verloren: 4860, kost: 1632.284 },
    "1140": { collegas: 227, verloren: 4903, kost: 1646.729 },
    "1150": { collegas: 229, verloren: 4946, kost: 1661.174 },
    "1160": { collegas: 231, verloren: 4989, kost: 1675.619 },
    "1170": { collegas: 233, verloren: 5032, kost: 1690.064 },
    "1180": { collegas: 235, verloren: 5075, kost: 1704.509 },
    "1190": { collegas: 237, verloren: 5118, kost: 1718.954 },
    "1200": { collegas: 239, verloren: 5161, kost: 1733.399 },
    "1210": { collegas: 241, verloren: 5204, kost: 1747.844 },
    "1220": { collegas: 243, verloren: 5247, kost: 1762.289 },
    "1230": { collegas: 245, verloren: 5290, kost: 1776.734 },
    "1240": { collegas: 247, verloren: 5333, kost: 1791.179 },
    "1250": { collegas: 249, verloren: 5376, kost: 1805.624 },
    "1260": { collegas: 251, verloren: 5419, kost: 1820.069 },
    "1270": { collegas: 253, verloren: 5462, kost: 1834.514 },
    "1280": { collegas: 255, verloren: 5505, kost: 1848.959 },
    "1290": { collegas: 257, verloren: 5548, kost: 1863.404 },
    "1300": { collegas: 259, verloren: 5591, kost: 1877.849 },
    "1310": { collegas: 261, verloren: 5634, kost: 1892.294 },
    "1320": { collegas: 263, verloren: 5677, kost: 1906.739 },
    "1330": { collegas: 265, verloren: 5720, kost: 1921.184 },
    "1340": { collegas: 267, verloren: 5763, kost: 1935.629 },
    "1350": { collegas: 269, verloren: 5806, kost: 1950.074 },
    "1360": { collegas: 271, verloren: 5849, kost: 1964.519 },
    "1370": { collegas: 273, verloren: 5892, kost: 1978.964 },
    "1380": { collegas: 275, verloren: 5935, kost: 1993.409 },
    "1390": { collegas: 277, verloren: 5978, kost: 2007.854 },
    "1400": { collegas: 279, verloren: 6021, kost: 2022.299 },
    "1410": { collegas: 281, verloren: 6064, kost: 2036.744 },
    "1420": { collegas: 283, verloren: 6107, kost: 2051.189 },
    "1430": { collegas: 285, verloren: 6150, kost: 2065.634 },
    "1440": { collegas: 287, verloren: 6193, kost: 2080.079 },
    "1450": { collegas: 289, verloren: 6236, kost: 2094.524 },
    "1460": { collegas: 291, verloren: 6279, kost: 2108.969 },
    "1470": { collegas: 293, verloren: 6322, kost: 2123.414 },
    "1480": { collegas: 295, verloren: 6365, kost: 2137.859 },
    "1490": { collegas: 297, verloren: 6408, kost: 2152.304 },
    "1500": { collegas: 299, verloren: 6451, kost: 2166.749 },
    "1510": { collegas: 301, verloren: 6494, kost: 2181.194 },
    "1520": { collegas: 303, verloren: 6537, kost: 2195.639 },
    "1530": { collegas: 305, verloren: 6580, kost: 2210.084 },
    "1540": { collegas: 307, verloren: 6623, kost: 2224.529 },
    "1550": { collegas: 309, verloren: 6666, kost: 2238.974 },
    "1560": { collegas: 311, verloren: 6709, kost: 2253.419 },
    "1570": { collegas: 313, verloren: 6752, kost: 2267.864 },
    "1580": { collegas: 315, verloren: 6795, kost: 2282.309 },
    "1590": { collegas: 317, verloren: 6838, kost: 2296.754 },
    "1600": { collegas: 319, verloren: 6881, kost: 2311.199 },
    "1610": { collegas: 321, verloren: 6924, kost: 2325.644 },
    "1620": { collegas: 323, verloren: 6967, kost: 2340.089 },
    "1630": { collegas: 325, verloren: 7010, kost: 2354.534 },
    "1640": { collegas: 327, verloren: 7053, kost: 2368.979 },
    "1650": { collegas: 329, verloren: 7096, kost: 2383.424 },
    "1660": { collegas: 331, verloren: 7139, kost: 2397.869 },
    "1670": { collegas: 333, verloren: 7182, kost: 2412.314 },
    "1680": { collegas: 335, verloren: 7225, kost: 2426.759 },
    "1690": { collegas: 337, verloren: 7268, kost: 2441.204 },
    "1700": { collegas: 339, verloren: 7311, kost: 2455.649 },
    "1710": { collegas: 341, verloren: 7354, kost: 2470.094 },
    "1720": { collegas: 343, verloren: 7397, kost: 2484.539 },
    "1730": { collegas: 345, verloren: 7440, kost: 2498.984 },
    "1740": { collegas: 347, verloren: 7483, kost: 2513.429 },
    "1750": { collegas: 349, verloren: 7526, kost: 2527.874 },
    "1760": { collegas: 351, verloren: 7569, kost: 2542.319 },
    "1770": { collegas: 353, verloren: 7612, kost: 2556.764 },
    "1780": { collegas: 355, verloren: 7655, kost: 2571.209 },
    "1790": { collegas: 357, verloren: 7698, kost: 2585.654 },
    "1800": { collegas: 359, verloren: 7741, kost: 2600.099 },
    "1810": { collegas: 361, verloren: 7784, kost: 2614.544 },
    "1820": { collegas: 363, verloren: 7827, kost: 2628.989 },
    "1830": { collegas: 365, verloren: 7870, kost: 2643.434 },
    "1840": { collegas: 367, verloren: 7913, kost: 2657.879 },
    "1850": { collegas: 369, verloren: 7956, kost: 2672.324 },
    "1860": { collegas: 371, verloren: 7999, kost: 2686.769 },
    "1870": { collegas: 373, verloren: 8042, kost: 2701.214 },
    "1880": { collegas: 375, verloren: 8085, kost: 2715.659 },
    "1890": { collegas: 377, verloren: 8128, kost: 2730.104 },
    "1900": { collegas: 379, verloren: 8171, kost: 2744.549 },
    "1910": { collegas: 381, verloren: 8214, kost: 2758.994 },
    "1920": { collegas: 383, verloren: 8257, kost: 2773.439 },
    "1930": { collegas: 385, verloren: 8300, kost: 2787.884 },
    "1940": { collegas: 387, verloren: 8343, kost: 2802.329 },
    "1950": { collegas: 389, verloren: 8386, kost: 2816.774 },
    "1960": { collegas: 391, verloren: 8429, kost: 2831.219 },
    "1970": { collegas: 393, verloren: 8472, kost: 2845.664 },
    "1980": { collegas: 395, verloren: 8515, kost: 2860.109 },
    "1990": { collegas: 397, verloren: 8558, kost: 2874.554 },
    "2000": { collegas: 399, verloren: 8601, kost: 2888.999 },
  };

  const [logos, setLogos] = useState<Logo[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  const [value, setValue] = useState("50");

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getLogos().then((r) => {
      setLogos(r);
    });
    getPartners().then((r) => {
      setPartners(r);
    });
    getTestimonials().then((r) => {
      setTestimonials(r);
    });
  });

  return (
    <div className="home">
      <div className="slideshow-container">
        <div
          id="banner2"
          className="banner mySlides fade"
          style={{ backgroundColor: "#1D4051" }}
        >
          <div style={{ textAlign: "center" }} className="banner-title w30">
            <h1 style={{ color: "white" }}>{t("banner2")}</h1>
          </div>
          <img style={{ maxHeight: "350px", width: "auto" }} src={Banner2} />
        </div>
      </div>

      <div className="container">
        <h5>
          <b>{t("about.about2")}</b>
        </h5>
        <div>{t("about.text3")}</div>
        <br />
        <div>{t("about.text4")}</div>
      </div>
      <div
        style={{
          backgroundColor: "#6B9AAD",
          color: "white",
          padding: "60px 90px",
          marginBottom: "55px",
        }}
      >
        <div className="container">
          <div className="onboarding">
            <img src={Onboarding1} alt={"onboarding"} />
            <img src={Onboarding2} alt={"onboarding"} />
            <img src={Onboarding3} alt={"onboarding"} />
            <img src={Onboarding4} alt={"onboarding"} />
            <img src={Onboarding5} alt={"onboarding"} />
          </div>
          <div>{t("mind-step1")}</div>
          <div>{t("mind-step2")}</div>
          <div>{t("mind-step3")}</div>
          <div>{t("mind-step4")}</div>
          <div>{t("mind-step5")}</div>
        </div>
      </div>
      <div className="about" style={{ marginTop: "20px" }}>
        <div className="how">
          <h3 style={{ textAlign: "center", textTransform: "uppercase" }}>
            {t("help")}
          </h3>
          <div
            dangerouslySetInnerHTML={{
              __html: t("help_text"),
            }}
          ></div>
        </div>
        <div className="why">
          <img
            style={{
              position: "absolute",
              width: "502px",
              zIndex: "1",
              marginTop: "-79px",
            }}
            src={Vlek}
          />
          <div
            style={{
              zIndex: "2",
              position: "relative",
              marginLeft: "110px",
              marginTop: "-27px",
            }}
          >
            <h3>{t("why")}</h3>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "16px", marginRight: "10px" }}
                  src={Check}
                />
                {t("why1")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "16px", marginRight: "10px" }}
                  src={Check}
                />
                {t("why2")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "16px", marginRight: "10px" }}
                  src={Check}
                />{" "}
                {t("why3")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "16px", marginRight: "10px" }}
                  src={Check}
                />{" "}
                {t("why4")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "16px", marginRight: "10px" }}
                  src={Check}
                />{" "}
                {t("why5")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "16px", marginRight: "10px" }}
                  src={Check}
                />
                {t("why6")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ width: "16px", marginRight: "10px" }}
                  src={Check}
                />
                {t("why7")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-column mb-4">
        <h3 style={{ textAlign: "center" }}>{t("how")}</h3>
        <iframe
          width="560"
          height="315"
          src={
            i18n.language === "nl"
              ? "https://www.youtube.com/embed/pJoTzVdE958"
              : i18n.language === "en"
              ? "https://www.youtube.com/embed/vK6garyg0Tg"
              : "https://www.youtube.com/embed/pJoTzVdE958"
          }
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      <div
        style={{
          paddingTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "100px",
        }}
      >
        <h2>{t("Impact")}</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "0 10%",
          }}
        >
          <div
            style={{
              width: "100%",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h4>{t("employees")}</h4>
            <div
              id="werknemers"
              style={{
                marginBottom: "20px",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              {value}
            </div>
            <input
              type="range"
              min="50"
              max="2000"
              step="10"
              value={value}
              className="range slider"
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div
              style={{
                display: "flex",
                width: "25%",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                id="collegas"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#6FA0AF",
                }}
              >
                {data[value].collegas}
              </div>
              <div>{t("problem")}</div>
            </div>
            <div
              style={{
                display: "flex",
                width: "25%",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                id="verloren"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#6FA0AF",
                }}
              >
                {data[value].verloren}
              </div>
              <div>{t("cost")}</div>
            </div>
            <div
              style={{
                display: "flex",
                width: "25%",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                id="kost"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#6FA0AF",
                }}
              >
                € {data[value].kost}
              </div>
              <div>{t("cost2")}</div>
            </div>
          </div>
        </div>
        <a
          href={
            i18n.language === "nl"
              ? "/uploads/images/01G30SGP8PW97VA0KBGFN125TP.pdf"
              : i18n.language === "en"
              ? "/uploads/images/01G30SH788T89VSSS4VFCBTPP8.pdf"
              : "/uploads/images/01G30SGY5X70C6FE2NMYQ2WG5J.pdf"
          }
          target={"_blank"}
          className="button yellow mt-4"
        >
          {t("download")}
        </a>
        <a href="mailto:info@mindlab.be" className="button yellow mt-4">
          {t("demo")}
        </a>
      </div>
      <div
        style={{
          paddingTop: "30px",
          marginBottom: "70px",
          backgroundColor: "lightgray",
          paddingBottom: "30px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          Bedrijven waarmee we samenwerken
        </h2>
        <div style={{ marginLeft: "50px", marginRight: "50px" }}>
          <Slider
            dots={false}
            infinite={true}
            autoplay={true}
            autoplaySpeed={6000}
            speed={500}
            slidesToShow={5}
            slidesToScroll={5}
            responsive={[
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {logos.map((logo, i) => {
              return (
                <div>
                  <img
                    src={getCorrectUrl(logo.url)}
                    alt={"logo"}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "contain",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div
        style={{
          paddingTop: "30px",
          marginBottom: "70px",
          backgroundColor: "lightgray",
          paddingBottom: "30px",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Partners</h2>
        <div style={{ marginLeft: "50px", marginRight: "50px" }}>
          <Slider
            dots={false}
            infinite={true}
            autoplay={true}
            autoplaySpeed={6000}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            responsive={[
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {partners.map((logo, i) => {
              return (
                <div>
                  <img
                    src={getCorrectUrl(logo.url)}
                    alt={"logo"}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "contain",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div
        style={{
          paddingTop: "30px",
          marginBottom: "100px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h2 style={{ textAlign: "center" }}>Wat gebruikers zeggen</h2>
        <div style={{ marginLeft: "50px", marginRight: "50px" }}>
          <Slider
            dots={false}
            infinite={true}
            autoplay={true}
            autoplaySpeed={6000}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
          >
            {testimonials.map((testimonial) => {
              return (
                <div>
                  <div style={{ textAlign: "center" }}>{testimonial.text}</div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
