import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type Partner = {
  id: string;
  url: string;
};

export async function getPartners(): Promise<Partner[]> {
  const result = await fetch(`${API_ENDPOINT}/partners`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.logos) {
    return [];
  }

  return result.logos;
}

export async function createPartners(url: string): Promise<Partner> {
  const result = await fetch(`${API_ENDPOINT}/partners`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      url: url,
    }),
  }).then((r) => r.json());

  return result;
}

export async function deletePartners(id: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/partners/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((r) => r.json());
}
