import React, { useState, useEffect, ReactComponentElement } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import { getVersion, loggedIn } from "./api/AuthApi";
import { Profile } from "./Profile";
import { Dashboard } from "./Dashboard";
import { Tests } from "./Tests/Tests";
import { isB2C, RedirectIfNotAdmin, RedirectIfNotLoggedIn } from "./Utils";
import { Businesses } from "./Businesses/Businesses";
import "react-datetime/css/react-datetime.css";
import { Home } from "./Home";
import { Modules } from "./Modules/users/Modules";
import { Module } from "./Modules/users/Module";
import { ModuleProgress } from "./Modules/users/ModuleProgress";
import { ShowTest } from "./Tests/ShowTest";
import { EditTest } from "./Tests/EditTest";
import { CreateTest } from "./Tests/CreateTest";
import { AdminModules } from "./Modules/admins/AdminModules";
import { CreateBusiness } from "./Businesses/CreateBusiness";
import { EditBusiness } from "./Businesses/EditBusiness";
import { EditModule } from "./Modules/admins/EditModule";
import { CreateModule } from "./Modules/admins/CreateModule";
import { ViewSubjects } from "./Modules/admins/ViewSubjects";
import { CreateSubject } from "./Modules/admins/CreateSubject";
import { EditSubject } from "./Modules/admins/EditSubject";
import { CreateLesson } from "./Modules/admins/CreateLesson";
import { EditLesson } from "./Modules/admins/EditLesson";
import { Checkout } from "./Checkout";
import { About } from "./About";
import { Lists } from "./Lists/Lists";
import { CreateList } from "./Lists/CreateList";
import { EditList } from "./Lists/EditList";
import { TestsView } from "./TestsView";
import { Media } from "./Media/Media";
import { TestTypes } from "./Tests/TestTypes";
import { Mindlab } from "./Mindlab";
import { GeneralTerms } from "./GeneralTerms";
import { PrivacyPolicy } from "./PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";
import { Toolbox } from "./Toolbox";
import { Crisishulp } from "./Crisishulp";
import { EditUser } from "./Users/EditUser";
import { Users } from "./Users/Users";
import { BusinessMail } from "./BusinessMail";
import { MindlabMail } from "./MindlabMail";
import { Favorites } from "./Favorites";
import { ExercisesResults } from "./ExercisesResults";
import { BusinessInfo } from "./BusinessDashboard/BusinessInfo";
import { BusinessDashboard } from "./BusinessDashboard/BusinessDashboard";
import { BusinessStats } from "./BusinessDashboard/BusinessStats";
import { BusinessDocuments } from "./BusinessDashboard/BusinessDocuments";
import { ToolboxCertificates } from "./ToolboxCertificates";
import { TestResults } from "./TestResults";
import { Skills } from "./Modules/users/Skills";
import { Faq } from "./Faq";
import { CreateUser } from "./Users/CreateUser";
import { User, getCurrentUser } from "./api/UserApi";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import { Blog, BlogPage } from "./Blog";
import PasswordForgot from "./PasswordForgot";
import PasswordReset from "./PasswordReset";
import { Game } from "./Game";
import { Challenges } from "./Challenges/Challenges";
import { CreateChallenge } from "./Challenges/CreateChallenge";
import { EditChallenge } from "./Challenges/EditChallenge";
import { Discounts } from "./Discounts/Discounts";
import { CreateDiscount } from "./Discounts/CreateDiscount";
import { EditDiscount } from "./Discounts/EditDiscount";
import { Podcasts } from "./Podcasts/Podcasts";
import { CreatePodcast } from "./Podcasts/CreatePodcast";
import { EditPodcast } from "./Podcasts/EditPodcast";
import { Podcast } from "./Podcast";
import { Blogs } from "./Blogs/Blogs";
import { CreateBlog } from "./Blogs/CreateBlog";
import { EditBlog } from "./Blogs/EditBlog";
import { Quizzes } from "./Quizzes/Quizzes";
import { CreateQuiz } from "./Quizzes/CreateQuiz";
import { EditQuiz } from "./Quizzes/EditQuiz";
import { Logos } from "./Logos/Logos";
import { CreateLogo } from "./Logos/CreateLogo";
import { Testimonials } from "./Testimonials/Testimonials";
import { CreateTestimonial } from "./Testimonials/CreateTestimonial";
import Inactive from "./Inactive";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Partners } from "./Partners/Partners";
import { CreatePartner } from "./Partners/CreatePartner";
import { RedirectToSSO, SSORedirect } from "./SSORedirect";
import { GameGroup } from "./GameGroup";
import { GameStarted } from "./GameStarted";
import { Landingpage } from "./Landingpage";
import { Header } from "./Header";
import { StartFaceScan } from "./StartFaceScan";
import { FaceScannerModal } from "./FaceScannerModal";
import { DashboardDocuments } from "./DashboardDocuments/DashboardDocuments";
import { CreateDashboardDocument } from "./DashboardDocuments/CreateDashboardDocument";
import { EditDashboardDocument } from "./DashboardDocuments/EditDashboardDocument";
import { BusinessReports } from "./BusinessDashboard/BusinessReports";
import { IntelliProveApi } from "./api/IntelliProveApi";
import { SuggestFaceScanModal } from "./SuggestFaceScanModal";
import { DocumentsPage } from "./DocumentsPage";
import { UserDashboardPrograms } from "./UserDashboardPrograms";
import { UserDashboardCertificates } from "./UserDashboardCertificates";
import { UserDashboardReports } from "./UserDashboardReports";
import { UserDashboardFavoriteLessons } from "./UserDashboardFavoriteLessons";
import { UserDashboardExercises } from "./UserDashboardExercises";
import { UserDashboardTimes } from "./UserDashboardTimes";
import { FaceScannerInfoModal } from "./FaceScannerInfoModal";
import { LandingPageCms } from "./LandingPageCms";
import { FaqCms } from "./FaqCms";
import { UpdateTestimonial } from "./Testimonials/UpdateTestimonial";
import { ErrorSSO } from "./ErrorSSO";
import { SSOLanding } from "./SSOLanding";
import { SSORedirectV2 } from "./SSORedirectV2";

interface FaceScanStartData {
  showScannerModal: boolean;
  periodic: boolean;
}

function App() {
  const [authenticated, setAuthenticated] = useState(loggedIn());
  const [user, setUser] = useState<User>();
  const [showSuggestFaceScanModal, setShowSuggestFaceScanModal] = useState(
    false
  );
  const [showInfoFaceScan, setShowInfoFaceScan] = useState(false);
  const [faceScanDue, setFaceScanDue] = useState(false);
  const [faceScanStartData, setFaceScanStartData] = useState<FaceScanStartData>(
    {
      showScannerModal: false,
      periodic: false,
    }
  );
  const { i18n } = useTranslation();

  useEffectOnce(() => {
    getCurrentUser().then(async (r) => {
      setUser(r);

      if (!r) {
        return;
      }

      localStorage.setItem("reset", "true");

      await i18n.changeLanguage(r.language);
    });

    getVersion().then((v) => {
      if (v !== process.env.REACT_APP_VERSION) {
        window.location.reload();
      }
    });
  });

  const languages = ["nl", "en", "fr"];
  const routes = [
    {
      path: "/",
      component: <Landingpage authenticated={authenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/home",
      component: (
        <Home
          authenticated={authenticated}
          setShowSuggestFaceScanModal={setShowSuggestFaceScanModal}
        />
      ),
      check: null,
      condition: true,
    },
    {
      path: "/modules",
      component: <Modules authenticated={authenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/inactive",
      component: <Inactive />,
      check: null,
      condition: true,
    },
    {
      path: "/skills",
      component: <Skills authenticated={authenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/game/single",
      component: <Game />,
      check: null,
      condition: true,
    },
    {
      path: "/game/group",
      component: <GameGroup />,
      check: null,
      condition: true,
    },
    {
      path: "/game/test",
      component: <GameStarted />,
      check: null,
      condition: true,
    },
    {
      path: "/game",
      component: <Game />,
      check: null,
      condition: true,
    },
    {
      path: "/podcasts",
      component: <Podcast authenticated={authenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/modules/:moduleId",
      component: <Module />,
      check: null,
      condition: true,
    },
    {
      path: "/modules/:moduleId/progress",
      component: <ModuleProgress />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/checkout/:moduleId",
      component: <Checkout />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/modules/:moduleId/subjects/:subjectId/lessons/:lessonId",
      component: <ModuleProgress />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/login",
      component: <Login setAuthenticated={setAuthenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/login/sso/:businessId",
      component: <SSOLanding setAuthenticated={setAuthenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/login/ssoRedirect/:businessId",
      component: <SSORedirectV2 setAuthenticated={setAuthenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/register",
      component: <Register setAuthenticated={setAuthenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/zna-registratie",
      component: <Register setAuthenticated={setAuthenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/blog",
      component: <Blog authenticated={authenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/blog/:id",
      component: <BlogPage />,
      check: null,
      condition: true,
    },
    {
      path: "/faq",
      component: <Faq authenticated={authenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/password/reset",
      component: <PasswordForgot />,
      check: null,
      condition: true,
    },
    {
      path: "/password-reset",
      component: <PasswordReset />,
      check: null,
      condition: true,
    },
    {
      path: "/profile",
      component: <Profile />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/about",
      component: <About authenticated={authenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/mindlab",
      component: <Mindlab />,
      check: null,
      condition: true,
    },
    {
      path: "/tests-view/:testId",
      component: <ShowTest />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/dashboard/general",
      component: <BusinessDashboard />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/dashboard/statistics",
      component: <BusinessStats />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/dashboard/documents",
      component: <BusinessDocuments />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/dashboard/documents/:type",
      component: <DocumentsPage />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/dashboard/reports",
      component: <BusinessReports />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/dashboard/info",
      component: <BusinessInfo />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/tests",
      component: <TestsView authenticated={authenticated} />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/tests/:typeId",
      component: <TestsView authenticated={authenticated} />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/tests/:typeId/test/:testId",
      component: <TestsView authenticated={authenticated} />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/general-terms",
      component: <GeneralTerms />,
      check: null,
      condition: true,
    },
    {
      path: "/privacy-policy",
      component: <PrivacyPolicy />,
      check: null,
      condition: true,
    },
    {
      path: "/user-dashboard",
      component: <UserDashboardPrograms />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/user-dashboard/programs",
      component: <UserDashboardPrograms />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/user-dashboard/certificates",
      component: <UserDashboardCertificates />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/user-dashboard/reports",
      component: <UserDashboardReports />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/user-dashboard/favorite-lessons",
      component: <UserDashboardFavoriteLessons />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/user-dashboard/exercises",
      component: <UserDashboardExercises />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/user-dashboard/times",
      component: <UserDashboardTimes />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/user-dashboard/crisis",
      component: <Crisishulp />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/toolbox",
      component: <Toolbox />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/toolbox/crisis",
      component: <Crisishulp />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/toolbox/favorites",
      component: <Favorites />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/toolbox/certificates",
      component: <ToolboxCertificates />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/toolbox/exercises",
      component: <ExercisesResults />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/toolbox/tests",
      component: <TestResults />,
      check: !isB2C() ? <RedirectIfNotLoggedIn /> : null,
      condition: true,
    },
    {
      path: "/dashboard",
      component: <Dashboard />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/users",
      component: <Users />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/users/new",
      component: <CreateUser />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/users/:userId",
      component: <EditUser />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/tests",
      component: <Tests />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/tests/new",
      component: <CreateTest />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/tests/:testId",
      component: <EditTest />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/modules",
      component: <AdminModules />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/modules/new",
      component: <CreateModule />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/modules/:moduleId",
      component: <EditModule />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/modules/:moduleId/subjects",
      component: <ViewSubjects />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/modules/:moduleId/subjects/new",
      component: <CreateSubject />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/modules/:moduleId/subjects/:subjectId",
      component: <EditSubject />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/modules/:moduleId/subjects/:subjectId/lessons/new",
      component: <CreateLesson />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path:
        "/dashboard/modules/:moduleId/subjects/:subjectId/lessons/:lessonId",
      component: <EditLesson />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/businesses",
      component: <Businesses />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/businesses/new",
      component: <CreateBusiness />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/media",
      component: <Media />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/test-types",
      component: <TestTypes />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/businesses/:businessId",
      component: <EditBusiness />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/lists",
      component: <Lists />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/lists/new",
      component: <CreateList />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/logos",
      component: <Logos />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/logos/new",
      component: <CreateLogo />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/partners",
      component: <Partners />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/partners/new",
      component: <CreatePartner />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/testimonials",
      component: <Testimonials />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/testimonials/new",
      component: <CreateTestimonial />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/testimonials/:id",
      component: <UpdateTestimonial />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/lists/:listId",
      component: <EditList />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/challenges",
      component: <Challenges />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/challenges/new",
      component: <CreateChallenge />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/challenges/:challengeId",
      component: <EditChallenge />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/podcasts",
      component: <Podcasts />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/podcasts/new",
      component: <CreatePodcast />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/podcasts/:podcastId",
      component: <EditPodcast />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/dashboard-documents",
      component: <DashboardDocuments />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/dashboard-documents/new",
      component: <CreateDashboardDocument />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/dashboard-documents/:documentId",
      component: <EditDashboardDocument />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/blogs",
      component: <Blogs />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/blogs/new",
      component: <CreateBlog />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/blogs/:blogId",
      component: <EditBlog />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/quizzes",
      component: <Quizzes />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/quizzes/new",
      component: <CreateQuiz />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/quizzes/:blogId",
      component: <EditQuiz />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/discounts",
      component: <Discounts />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/discounts/new",
      component: <CreateDiscount />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/discounts/:name",
      component: <EditDiscount />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/faq",
      component: <FaqCms />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/dashboard/landingpage",
      component: <LandingPageCms />,
      check: <RedirectIfNotAdmin />,
      condition: true,
    },
    {
      path: "/error/sso",
      component: <ErrorSSO />,
      check: null,
      condition: true,
    },
    {
      path: "/sso/:token",
      component: <SSORedirect setAuthenticated={setAuthenticated} />,
      check: null,
      condition: true,
    },
    {
      path: "/fpc/gent/sso",
      component: (
        <RedirectToSSO
          url={
            "https://mindlab.be/api/sso/signin?business=01GQ4KS7WRD7BR93SEFNMCCWAA"
          }
        />
      ),
      check: null,
      condition: true,
    },
    {
      path: "/fpc/antwerpen/sso",
      component: (
        <RedirectToSSO
          url={
            "https://mindlab.be/api/sso/signin?business=01GKMSG90Y7B9GE5546B3KE11X"
          }
        />
      ),
      check: null,
      condition: true,
    },
    {
      path: "/dieteren/sso",
      component: (
        <RedirectToSSO
          url={
            "https://mindlab.be/api/sso/signin?business=01GBQAHCNSR419TTVC6WM357F0"
          }
        />
      ),
      check: null,
      condition: true,
    },
    {
      path: "/uzgent/sso",
      component: (
        <RedirectToSSO
          url={
            "https://mindlab.be/api/sso/signin?business=01HASDDKMZ12KGGTANNTEYC1AJ"
          }
        />
      ),
      check: null,
      condition: true,
    },
    {
      path: "/sbs/sso",
      component: (
        <RedirectToSSO
          url={
            "https://mindlab.be/api/sso/signin?business=01HEN13FJYBMCD0RVMVZ1A16KR"
          }
        />
      ),
      check: null,
      condition: true,
    },
  ];

  const components: ReactComponentElement<any>[] = [];

  routes.forEach((route) => {
    if (route.condition) {
      components.push(
        <Route exact path={`${route.path}`}>
          {route.check}
          {route.component}
        </Route>
      );
    }

    return languages.forEach((language) => {
      if (route.condition) {
        components.push(
          <Route exact path={`/${language}${route.path}`}>
            {route.check}
            {route.component}
          </Route>
        );
      }
    });
  });

  useEffect(() => {
    if (authenticated) {
      IntelliProveApi.checkScanDue().then((due) => setFaceScanDue(due));
    }
  }, [authenticated]);

  useEffect(() => {
    if (faceScanDue) {
      if (localStorage.getItem("facescan") === "true") {
        return;
      }

      localStorage.setItem("facescan", "true");
      setShowSuggestFaceScanModal(true);
    }
  }, [faceScanDue]);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Header
          authenticated={authenticated}
          setAuthenticated={(state: boolean) => {
            setAuthenticated(state);
          }}
        />
        <BusinessMail authenticated={authenticated} />
        <MindlabMail />
        <Switch>{components}</Switch>
        {authenticated ? (
          <StartFaceScan
            onClick={() =>
              setFaceScanStartData({
                showScannerModal: true,
                periodic: false,
              })
            }
          />
        ) : null}
        <SuggestFaceScanModal
          scanDue={faceScanDue}
          authenticated={authenticated}
          user={user}
          isOpen={showSuggestFaceScanModal}
          onRequestClose={() => setShowSuggestFaceScanModal(false)}
          onRequestInfo={() => {
            setShowInfoFaceScan(true);
            setShowSuggestFaceScanModal(false);
          }}
          onAcceptScan={() => {
            setFaceScanStartData({
              showScannerModal: true,
              periodic: true,
            });
            localStorage.removeItem("facescan");
            setFaceScanDue(false);
            setShowSuggestFaceScanModal(false);
          }}
        />
        <FaceScannerModal
          isOpen={faceScanStartData.showScannerModal}
          onRequestClose={() =>
            setFaceScanStartData({
              showScannerModal: false,
              periodic: faceScanStartData.periodic,
            })
          }
          periodic={faceScanStartData.periodic}
        />
        <FaceScannerInfoModal
          isOpen={showInfoFaceScan}
          onRequestClose={() => setShowInfoFaceScan(false)}
          periodic={faceScanStartData.periodic}
          onAcceptScan={() => {
            setFaceScanStartData({
              showScannerModal: true,
              periodic: true,
            });
            localStorage.removeItem("facescan");
            setFaceScanDue(false);
            setShowSuggestFaceScanModal(false);
            setShowInfoFaceScan(false);
          }}
        />
      </Router>
    </>
  );
}

export default App;
