import React, { useState } from "react";
import "./Crisishulp.css";
import { ToolboxHeader } from "./ToolboxHeader";
import Question from "./img/question.png";
import {
  getUserTestScores,
  listTests,
  Test,
  UserTestScore,
} from "./api/TestApi";
import { useEffectOnce } from "react-use";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export function TestResults() {
  const [tests, setTests] = useState<Test[]>([]);
  const [scores, setScores] = useState<UserTestScore[]>([]);
  const [showScore, setShowScore] = useState<UserTestScore | null>(null);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    listTests()?.then((result) => {
      setTests(result);
    });

    getUserTestScores().then((result) => {
      setScores(result);
    });
  });

  return (
    <ToolboxHeader>
      <div className="container crisishulp w-100">
        <div className="tools">
          <div className="bread">
            <img src={Question} />
            <div className="title">{t("toolbox.test_name")}</div>
          </div>
        </div>
        {tests.map((test) => {
          const testScores = scores.filter((s) => s.testId === test.id);

          if (testScores.length === 0) {
            return null;
          }

          const uniqueDates = Array.from(
            new Set(testScores.map((t) => t.date))
          );
          const groupedResults = [];

          for (const date of uniqueDates) {
            const scores = testScores.filter((s) => s.date === date);

            groupedResults.push(scores);
          }

          return (
            <div>
              <h3>
                {i18n.language === "nl" && test.title_nl}
                {i18n.language === "en" && test.title_en}
                {i18n.language === "fr" && test.title_fr}
              </h3>
              <div>
                {groupedResults.map((score) => {
                  const firstItem = score[0];

                  return (
                    <div
                      className="pointer"
                      onClick={() => {
                        setShowScore(firstItem);
                      }}
                    >
                      <div>
                        {DateTime.fromISO(firstItem.date).toFormat(
                          "dd/LL/yyyy"
                        )}
                      </div>
                      {showScore && showScore.id === firstItem.id
                        ? score.map((item) => {
                            const groupScore = test.testScores
                              .filter(
                                (s) =>
                                  s.groupId === item.groupId &&
                                  s.ltScore > item.score
                              )
                              .sort((a, b) => a.ltScore - b.ltScore)[0];

                            if (!groupScore) {
                              return null;
                            }

                            return (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    i18n.language === "nl"
                                      ? groupScore.explanation_nl
                                      : i18n.language === "en"
                                      ? groupScore.explanation_en || ""
                                      : groupScore.explanation_fr || "",
                                }}
                              />
                            );
                          })
                        : null}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </ToolboxHeader>
  );
}
