import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { addMedia } from "../api/MediaApi";
import { createLogo } from "../api/LogosApi";

export function LogoForm() {
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>();

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Redirect to={"/dashboard/logos"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          let updatedBusiness = {
            url: "",
          };

          if (selectedImageFile) {
            const pathResult = await addMedia(selectedImageFile);

            if (
              pathResult &&
              typeof pathResult !== "string" &&
              pathResult.path
            ) {
              updatedBusiness = {
                ...updatedBusiness,
                url: pathResult.path,
              };
            }
          }

          createLogo(updatedBusiness.url).then(async (r) => {
            setSaved(true);
          });
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-25">
            <div>
              <h4>Algemene info</h4>
              <label>Afbeelding</label>
              <input
                type="file"
                className="input"
                placeholder="Upload afbeelding"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setSelectedImageFile(e.target.files[0]);
                  }
                }}
              />

              <button className="button" type="submit">
                Logo toevoegen
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
