import React from "react";
import { Document, Font, StyleSheet } from "@react-pdf/renderer";
import { Business } from "../api/BusinessApi";
import { Quarter } from "./Quarter";
import montserratRegular from "../fonts/Montserrat-Regular.otf";
import montserratMedium from "../fonts/Montserrat-Medium.otf";
import montserratBold from "../fonts/Montserrat-Bold.otf";
import { QuarterlyReportTitlePage } from "./QuarterlyReportTitlePage";
import { QuarterlyReportContent } from "./QuarterlyReportContent";

export function QuarterlyReport({
  quarter,
  year,
  business,
  statsBoxRowImage,
  modulesGraphImage,
  skillsGraphImage,
  sessionGraphImage,
  usersGraphImage,
  activityGraphImage,
  testsTableImage,
  locked,
}: {
  quarter: Quarter;
  year: number;
  business: Business;
  statsBoxRowImage: string;
  modulesGraphImage: string;
  skillsGraphImage: string;
  sessionGraphImage: string;
  usersGraphImage: string;
  activityGraphImage: string;
  testsTableImage: string;
  locked: boolean;
}) {
  Font.register({
    family: "Montserrat",
    fonts: [
      {
        src: montserratRegular,
      },
      {
        src: montserratBold,
      },
    ],
  });

  const styles = StyleSheet.create({
    doc: {
      fontFamily: "Montserrat",
    },
  });

  return (
    <Document style={styles.doc}>
      <QuarterlyReportTitlePage
        quarter={quarter}
        year={year}
        businessName={business.name}
      />
      <QuarterlyReportContent
        quarter={quarter}
        year={year}
        businessName={business.name}
        statsBoxRowImage={statsBoxRowImage}
        modulesGraphImage={modulesGraphImage}
        skillsGraphImage={skillsGraphImage}
        sessionGraphImage={sessionGraphImage}
        usersGraphImage={usersGraphImage}
        activityGraphImage={activityGraphImage}
        testsTableImage={testsTableImage}
        locked={locked}
      />
    </Document>
  );
}
