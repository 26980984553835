import React, { useEffect, useState } from "react";
import { BusinessSidebar } from "../BusinessSidebar";
import "./BusinessDocuments.css";
import MailtemplatesIcon from "../img/mailtemplates.svg";
import PracticesIcon from "../img/practices.svg";
import DraaiboekIcon from "../img/draaiboek.svg";
import BrochuresIcon from "../img/brochures.svg";
import VisualsIcon from "../img/visuals.svg";
import ActionIcon from "../img/action.svg";
import { DocumentType } from "../Utils";
import { DashboardDocument, getDocuments } from "../api/DashboardDocumentsApi";
import { DocumentBox } from "../DocumentBox";
import { useTranslation } from "react-i18next";

export function BusinessDocuments() {
  const [documents, setDocuments] = useState<DashboardDocument[]>([]);
  const [open, setOpen] = useState<DocumentType | null>(null);
  const { t } = useTranslation("hr-dashboard");

  useEffect(() => {
    getDocuments().then((result) => {
      setDocuments(result);
    });
  }, []);

  return (
    <BusinessSidebar>
      <div className="business-documents">
        <div className="documents-title">{t("businessDocuments.title")}</div>
        <div className="boxes">
          <DocumentBox
            type={DocumentType.MAILTEMPLATES}
            name={t("businessDocuments.mailTemplates")}
            icon={MailtemplatesIcon}
            className={"mailtemplates"}
          />
          {/*
          <DocumentBox
            type={DocumentType.BEST_PRACTICES}
            name={t("businessDocuments.bestPractices")}
            icon={PracticesIcon}
            className={"practices"}
          />
          */}
          <DocumentBox
            type={DocumentType.DRAAIBOEK}
            name={t("businessDocuments.script")}
            icon={DraaiboekIcon}
            className={"draaiboek"}
          />
          <DocumentBox
            type={DocumentType.BROCHURES}
            name={t("businessDocuments.brochures")}
            icon={BrochuresIcon}
            className={"brochures"}
          />
          <DocumentBox
            type={DocumentType.VISUALS_AND_GRAPHICS}
            name={t("businessDocuments.visualsAndGraphics")}
            icon={VisualsIcon}
            className={"visuals"}
          />
          <DocumentBox
            type={DocumentType.VARIA}
            name={t("businessDocuments.varia")}
            icon={ActionIcon}
            className={"varia"}
          />
        </div>
      </div>
    </BusinessSidebar>
  );
}
