import React, { Fragment } from "react";
import { Page, View, StyleSheet, Image, Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { Quarter } from "./Quarter";
import { QuarterlyReportFooter } from "./QuarterlyReportFooter";
import PremiumIcon from "../img/Premium.svg";

export function QuarterlyReportContent({
  quarter,
  year,
  businessName,
  locked,
  statsBoxRowImage,
  modulesGraphImage,
  skillsGraphImage,
  usersGraphImage,
  sessionGraphImage,
  activityGraphImage,
  testsTableImage,
}: {
  quarter: Quarter;
  year: number;
  businessName: string;
  locked: boolean;
  statsBoxRowImage: string;
  modulesGraphImage: string;
  skillsGraphImage: string;
  usersGraphImage: string;
  sessionGraphImage: string;
  activityGraphImage: string;
  testsTableImage: string;
}) {
  const { t } = useTranslation("hr-dashboard");

  const styles = StyleSheet.create({
    marginTop: {
      marginTop: "50px",
    },
    smallMarginTop: {
      marginTop: "20px",
    },
    graphs: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: "30px",
    },
    graph: {
      width: "355px",
      marginTop: "10px",
      padding: "10px",
    },
    largeGraph: {
      width: "800px",
      marginTop: "10px",
      padding: "10px",
    },
    graphWrapper: {
      border: "1px solid grey",
      borderRadius: "10px",
      padding: "10px",
      fontSize: "15px",
      color: "#1F3B49",
    },
  });

  return (
    <Fragment>
      <Page orientation="landscape">
        <Image style={styles.marginTop} src={statsBoxRowImage} />
        <View style={styles.graphs}>
          <View style={styles.graphWrapper}>
            <Text>{t("modulesGraph.title")}</Text>
            <Image style={styles.graph} src={modulesGraphImage} />
          </View>
          <View style={styles.graphWrapper}>
            <Text>{t("skillsGraph.title")}</Text>
            <Image style={styles.graph} src={skillsGraphImage} />
            <img className="graph-premium" src={PremiumIcon} alt="" />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
      <Page orientation="landscape">
        <View style={{ ...styles.graphs, ...styles.marginTop }}>
          <View style={styles.graphWrapper}>
            <Text>{t("usersGraph.title")}</Text>
            <Image style={styles.graph} src={usersGraphImage} />
          </View>
          <View style={styles.graphWrapper}>
            <Text>{t("activityGraph.title")}</Text>
            <Image style={styles.graph} src={activityGraphImage} />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
      <Page orientation="landscape">
        <View style={{ ...styles.graphs, ...styles.marginTop }}>
          <View style={styles.graphWrapper}>
            <Text>{t("report:sessionGraphTitle")}</Text>
            <Image style={styles.largeGraph} src={sessionGraphImage} />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
      <Page orientation="portrait">
        <View style={{ ...styles.graphs, ...styles.smallMarginTop }}>
          <View style={styles.graphWrapper}>
            <Text>{t("testTable.title")}</Text>
            <Image style={styles.graph} src={testsTableImage} />
          </View>
        </View>
        <QuarterlyReportFooter />
      </Page>
    </Fragment>
  );
}
