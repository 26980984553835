import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { loggedIn } from "./api/AuthApi";
import { useEffectOnce } from "react-use";
import { getCheckoutUrl } from "./api/UserApi";

export function Checkout() {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [discount, setDiscount] = useState("");
  const location = useLocation();
  const history = useHistory();

  useEffectOnce(() => {
    if (moduleId === "undefined") {
      return;
    }
  });

  function checkout() {
    getCheckoutUrl(
      moduleId,
      discount.toLowerCase().trim() === ""
        ? undefined
        : discount.toLowerCase().trim()
    ).then((result) => {
      window.location.href = result.url;
    });
  }

  useEffect(() => {
    if (!loggedIn()) {
      history.replace(`/login?redirect=/checkout/${moduleId}`);
    }
  }, [location]);

  if (!loggedIn()) {
    return <Redirect push to={`/login?redirect=/checkout/${moduleId}`} />;
  }

  return (
    <div className="auth">
      <h2>Checkout</h2>
      <div>Kortingscode?</div>
      <input
        className="input w-10"
        type="text"
        value={discount}
        onChange={(e) => {
          setDiscount(e.target.value);
        }}
      />
      <button className="button mt-4" onClick={checkout}>
        Doorgaan
      </button>
      <button className="button mt-4 pink" onClick={checkout}>
        Overslaan
      </button>
    </div>
  );
}
