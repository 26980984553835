import React, { useEffect, useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import {
  createTestType,
  deleteTest,
  deleteTestType,
  listTestTypes,
  TestType,
  updateTestType,
} from "../api/TestApi";
import { addImage } from "../api/BusinessApi";
import { FaPen, FaTimes } from "react-icons/fa";

export function TestTypes() {
  const [testTypes, setTestTypes] = useState<TestType[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [titleNl, setTitleNl] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [titleFr, setTitleFr] = useState("");
  const [editType, setType] = useState<TestType | null>(null);

  useEffectOnce(() => {
    listTestTypes().then((r) => {
      setTestTypes(r);
    });
  });

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Test Types</h2>
        </div>
        <div className="mb-4">
          <label>Titel in het Nederlands</label>
          <input
            type="text"
            className="input"
            value={titleNl}
            onChange={(e) => {
              setTitleNl(e.target.value);
            }}
          />
          <label>Titel in het Engels</label>
          <input
            type="text"
            className="input"
            value={titleEn}
            onChange={(e) => {
              setTitleEn(e.target.value);
            }}
          />
          <label>Titel in het Frans</label>
          <input
            type="text"
            className="input"
            value={titleFr}
            onChange={(e) => {
              setTitleFr(e.target.value);
            }}
          />
          <label>Upload image</label>
          <input
            type="file"
            className="input"
            placeholder="Upload image"
            accept={"image/*"}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                setSelectedFile(e.target.files[0]);
              }
            }}
          />
          <div
            className="button small w-fit"
            onClick={async (e) => {
              e.preventDefault();

              let pathResult;

              if (selectedFile) {
                pathResult = await addImage(selectedFile);
              }

              let result: TestType;

              if (editType) {
                result = await updateTestType(
                  {
                    titleFR: titleFr,
                    titleNL: titleNl,
                    titleEN: titleEn,
                    image:
                      pathResult &&
                      typeof pathResult !== "string" &&
                      pathResult.path
                        ? pathResult.path
                        : editType.image,
                  },
                  editType.id
                );

                setTestTypes(
                  testTypes.map((i) => {
                    if (i.id === editType.id) {
                      return result;
                    }

                    return i;
                  })
                );
                setType(null);
              } else {
                result = await createTestType({
                  titleFR: titleFr,
                  titleNL: titleNl,
                  titleEN: titleEn,
                  image:
                    pathResult &&
                    typeof pathResult !== "string" &&
                    pathResult.path
                      ? pathResult.path
                      : "",
                });

                setTestTypes(testTypes.concat(result));
              }

              setSelectedFile(null);
              setTitleFr("");
              setTitleNl("");
              setTitleEn("");
            }}
          >
            Opslaan
          </div>
        </div>

        {testTypes.length === 0 ? (
          "Momenteel nog geen types beschikbaar"
        ) : (
          <>
            <table className="table ">
              <thead>
                <tr>
                  <th>Naam</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {testTypes.map((type) => {
                  return (
                    <tr key={type.id}>
                      <td>{type.titleNL}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setTitleNl(type.titleNL);
                              setTitleFr(type.titleFR);
                              setTitleEn(type.titleEN);
                              setType(type);
                            }}
                          >
                            <FaPen />
                          </div>
                          <div
                            className="button small w-fit ml-5 pink"
                            onClick={async () => {
                              await deleteTestType(type.id);
                              setTestTypes(
                                testTypes.filter((t) => t.id !== type.id)
                              );
                            }}
                          >
                            <FaTimes />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Sidebar>
  );
}
