import React, { useEffect, useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { loggedIn } from "./api/AuthApi";
import { Checkbox } from "./Checkbox";
import { isB2C, isOkPass } from "./Utils";
import { useTranslation } from "react-i18next";
import { Dialog } from "./Dialog";
import { Enable2FA } from "./Profile";
import { EngiePrivacyNotice } from "./EngiePrivacyNotice";
import { getBusinessSettings, SettingType } from "./api/BusinessSettingApi";
import { Loading } from "./Loading";
import { graphql } from "./__generated__";
import { useMutation } from "@apollo/client";
import { Language } from "./__generated__/graphql";
import { getConnectedBusinesses } from "./api/BusinessApi";

const registerHumanMutation = graphql(`
  mutation RegisterHuman($input: RegisterHumanInput!) {
    registerHumanV2(input: $input) {
      accessToken
      refreshToken
      mindlabToken
    }
  }
`);

function Register({
  setAuthenticated,
}: {
  setAuthenticated: (state: boolean) => void;
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [businessCode, setBusinessCode] = useState("");
  const [faDone, setFaDone] = useState(false);
  const [businessSettings, setBusinessSettings] = useState<SettingType>({
    disableTests: false,
    enforce2FA: false,
    inactiveSiteLink: process.env.REACT_APP_FRONTEND_URL || "",
  });
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const { t, i18n } = useTranslation("login");

  const searchParams = new URLSearchParams(location.search);
  const redirectSearch = searchParams.get("redirect");
  const redirectUrl = redirectSearch
    ? decodeURIComponent(redirectSearch)
    : i18n.language
    ? "/" + i18n.language + "/home"
    : "/home";

  const [
    registerHuman,
    { called: registerHumanCalled, error: registerHumanError },
  ] = useMutation(registerHumanMutation);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if ((registerHumanCalled && !registerHumanError) || loggedIn()) {
      setAuthenticated(true);
      history.push(redirectUrl);
    }
  }, []);

  if (faDone) {
    setAuthenticated(true);
    history.push(redirectUrl);
    return null;
  }

  return (
    <div className="auth">
      <Dialog
        isOpen={businessSettings.enforce2FA}
        onRequestClose={() => {
          //Nothing
        }}
        title="2 staps verificatie instellen"
        size="small"
        padding={true}
        showCloseButton={false}
      >
        <Enable2FA
          close={() => {
            setFaDone(true);
          }}
          givenPassword={password}
        />
      </Dialog>
      <Dialog
        isOpen={
          registerHumanCalled &&
          !registerHumanError &&
          businessCode.toLowerCase() === "corp-mindlab"
        }
        onRequestClose={() => {
          // Nothing
        }}
        title="Mindlab by BloomUp - Information notice regarding the Processing of your Personal Data (1)"
        size="wide"
        padding={true}
        showCloseButton={false}
      >
        <EngiePrivacyNotice
          onClose={() => {
            setFaDone(true);
          }}
        />
      </Dialog>
      {errorMessage ? <div className="error">{t(errorMessage)}</div> : null}
      <h2>{t("register")}</h2>
      <form
        className="auth-form"
        onSubmit={async (e) => {
          e.preventDefault();

          if (!privacy) {
            setErrorMessage(
              "Gelieve akkoord te gaan met de privacy verklaring."
            );

            return;
          }

          const passwordCheck = isOkPass(password);

          if (!passwordCheck.result) {
            setErrorMessage(passwordCheck.error);

            return;
          }

          setLoading(true);

          let lowerCaseBusinessCode:
            | string
            | undefined = businessCode.toLowerCase().trim();
          if (lowerCaseBusinessCode.length === 0)
            lowerCaseBusinessCode = undefined;
          try {
            const graphQLResponse = await registerHuman({
              variables: {
                input: {
                  firstName,
                  lastName,
                  email,
                  password,
                  organizationCode: lowerCaseBusinessCode,
                  preferredLanguage:
                    i18n.language === "fr"
                      ? "frBe"
                      : (i18n.language as Language),
                  mindlabNewsletter: newsletter,
                  fromMindlab: true,
                },
              },
            });

            const tokens = graphQLResponse.data?.registerHumanV2;

            if (tokens?.accessToken) {
              localStorage.setItem("bloomupAccessToken", tokens.accessToken);
            }
            if (tokens?.refreshToken) {
              localStorage.setItem("bloomupRefreshToken", tokens.refreshToken);
            }
            if (tokens?.mindlabToken) {
              localStorage.setItem("token", tokens.mindlabToken);

              setAuthenticated(true);
              setErrorMessage(undefined);

              if (lowerCaseBusinessCode) {
                // The GraphQL Mutation will throw an error if we pass fromMindlab = true
                // and the passed org code is invalid, that's why it's safe to
                // assume connectedBusinesses will return exactly one business
                const businesses = await getConnectedBusinesses();

                const settings = await getBusinessSettings(
                  businesses[0].business.id
                );

                setBusinessSettings(settings.settings);

                setLoading(false);

                if (!settings.settings.enforce2FA) {
                  history.push(redirectUrl);
                }
              } else {
                setLoading(false);
                history.push(redirectUrl);
              }
            }
          } catch (e) {
            setErrorMessage("Er is iets foutgelopen bij de registratie.");
          }
        }}
      >
        <label>{t("email")}</label>
        <input
          className="input"
          type="email"
          placeholder={t("email")}
          value={email}
          onChange={(input) => {
            setEmail(input.target.value);
          }}
          required={true}
        />
        <label>{t("password")}</label>
        <input
          className="input"
          type="password"
          placeholder={t("password")}
          value={password}
          onChange={(input) => {
            setPassword(input.target.value);
          }}
          required={true}
        />
        <label>{t("repeat_password")}</label>
        <input
          className="input"
          type="password"
          placeholder={t("password")}
          value={password2}
          onChange={(input) => {
            setPassword2(input.target.value);
          }}
          required={true}
        />
        {isB2C() ? null : (
          <>
            <label>{t("code")}</label>
            <input
              className="input"
              type="text"
              placeholder={t("code")}
              value={businessCode}
              onChange={(input) => {
                setBusinessCode(input.target.value);
              }}
              required={true}
            />
          </>
        )}
        <label>{t("first_name")}</label>
        <input
          className="input"
          type="text"
          placeholder={t("first_name")}
          value={firstName}
          onChange={(input) => {
            setFirstName(input.target.value);
          }}
          required={true}
        />
        <label>{t("last_name")}</label>
        <input
          className="input"
          type="text"
          placeholder={t("last_name")}
          value={lastName}
          onChange={(input) => {
            setLastName(input.target.value);
          }}
          required={true}
        />
        <Checkbox
          title={t("newsletter")}
          value={newsletter}
          onClick={(checked) => {
            setNewsletter(checked);
          }}
        />
        <Checkbox
          title={t("acceptPrivacyPolicy")}
          value={privacy}
          onClick={(checked) => {
            setPrivacy(checked);
          }}
        />
        <div className="mt-4 mb-4">
          {t("create")} <Link to="/general-terms">{t("terms")}</Link> {t("en")}{" "}
          <a href="/Externe_Privacyverklaring_Mindlab.pdf" target="_blank">
            {t("policy")}
          </a>
          .
        </div>
        <div className="error">{errorMessage ? t(errorMessage) : null}</div>
        {loading ? (
          <Loading />
        ) : (
          <button
            className="button fit"
            type="submit"
            disabled={password !== password2}
          >
            {t("create_account_2")}
          </button>
        )}
      </form>
    </div>
  );
}

export default Register;
