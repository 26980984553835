import React, { useEffect, useState } from "react";
import "./Game.css";
import { Checkbox } from "./Checkbox";
import GameImg from "./img/game.png";
import { useTranslation } from "react-i18next";
import { isPlaying, startGroupGame } from "./api/GroupGameApi";
import { GameStarted } from "./GameStarted";
import { Loading } from "./Loading";

export function GameGroup() {
  const [name, setName] = useState("");
  const [policy, setPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [started, setStarted] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation("general");

  useEffect(() => {
    setLoading(true);
    isPlaying().then((response) => {
      setStarted(response);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="allbanner">
      <div
        id="banner2"
        className="banner mySlides fade flex justify-evenly wrap"
        style={{
          backgroundColor: `#1C4052`,
          minHeight: "400px",
        }}
      >
        <div style={{ textAlign: "center" }} className="banner-title w30">
          <h1 style={{ color: "white" }}>{t("game")}</h1>
        </div>
        <img
          style={{
            maxHeight: "350px",
            width: "auto",
            maxWidth: "303px",
          }}
          src={GameImg}
        />
      </div>
      {started ? (
        <GameStarted />
      ) : (
        <div className="game-wrapper mt-4">
          <div className="flex">
            <h2 className="mr-5">Naam:</h2>
            <input
              className="input"
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <Checkbox
            title={
              <div>
                Door deel te nemen in het groepsspel, ga je akkoord dat andere
                gebruikers jou als gebruiker kunnen zien. Andere gegevens zoals
                welke programma's je raadpleegt, worden nooit gedeeld met
                derden. Lees hier onze{" "}
                <a
                  href="/Externe_Privacyverklaring_Mindlab.pdf"
                  target="_blank"
                >
                  privacy policy
                </a>
              </div>
            }
            value={policy}
            onClick={setPolicy}
          />
          {<div className="error">{error}</div>}
          <div
            className="button w-fit yellow mt-4"
            onClick={async () => {
              if (!policy) {
                setError("Gelieve de privacy policy te accepteren.");
                return;
              }

              if (!name.trim()) {
                setError("Gelieve een naam in te vullen.");
                return;
              }

              await startGroupGame(name.trim());
              window.location.href = "/game/group";
            }}
          >
            Start het spel
          </div>
        </div>
      )}
    </div>
  );
}
