import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { getList, List } from "../api/ListApi";
import { ListForm } from "./ListForm";

export function EditList() {
  const { listId } = useParams<{ listId: string }>();
  const [list, setList] = useState<List | null>(null);

  useEffectOnce(() => {
    getList(listId).then((r) => {
      setList(r);
    });
  });

  if (!list) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <ListForm list={list} />
    </Sidebar>
  );
}
