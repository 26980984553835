import React from "react";
import ReactPDF from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QuarterlyReport } from "./QuarterlyReport/QuarterlyReport";
import { Quarter } from "./QuarterlyReport/Quarter";
import { Business, getStatsForBusiness } from "./api/BusinessApi";
import { getModules } from "./api/ModulesApi";
import { getSessions } from "./api/UserApi";
import {
  createStatsBoxRowImage,
  createModulesGraphImage,
  createSessionGraphImage,
  createUsersGraphImage,
  createActivityGraphImage,
  createTestsTableImage,
} from "./QuarterlyReport/getReportImage";
import { listTests } from "./api/TestApi";

export function ReportBox({
  quarter,
  year,
  icon,
  className,
  business,
  locked,
}: {
  quarter: Quarter;
  year: number;
  icon: string;
  className: string;
  business: Business;
  locked: boolean;
}) {
  const { t } = useTranslation("report");
  const translationResponse = useTranslation("hr-dashboard");

  return (
    <div>
      <div
        className={`${className} box`}
        onClick={async () => {
          const toastId = toast.loading("Creating report...");
          try {
            // create pdf
            const modules = await getModules();
            const allStats = await getStatsForBusiness(business.id);
            const stats = await getStatsForBusiness(business.id, {
              quarter,
              year,
            });
            const statsBoxRowImage = await createStatsBoxRowImage(
              allStats,
              !locked
            );

            const modulesWithoutSkills = modules.filter((m) => !m.skills);
            const modulesGraphImage = await createModulesGraphImage(
              modulesWithoutSkills,
              stats,
              translationResponse
            );

            const skills = modules.filter((m) => m.skills);
            const skillsGraphImage = await createModulesGraphImage(
              skills,
              stats,
              translationResponse
            );
            const sessions = await getSessions(business.id, {
              quarter,
              year,
            });
            const sessionGraphImage = await createSessionGraphImage(sessions);

            const usersGraphImage = await createUsersGraphImage(stats);
            const activityGraphImage = await createActivityGraphImage(
              stats.modulesActivity,
              translationResponse
            );
            const tests = await listTests();

            const testsTableImage = await createTestsTableImage(
              tests,
              stats.tests
            );

            const blob = await ReactPDF.pdf(
              <QuarterlyReport
                quarter={quarter}
                year={year}
                business={business}
                locked={locked}
                statsBoxRowImage={statsBoxRowImage}
                modulesGraphImage={modulesGraphImage}
                skillsGraphImage={skillsGraphImage}
                sessionGraphImage={sessionGraphImage}
                usersGraphImage={usersGraphImage}
                activityGraphImage={activityGraphImage}
                testsTableImage={testsTableImage}
              />
            ).toBlob();

            // download pdf
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `report_${business.name}_Q${quarter}_${year}.pdf`;
            link.click();

            toast.dismiss(toastId);
          } catch (e) {
            toast.update(toastId, {
              render: "Report creation failed",
              type: "error",
              isLoading: false,
            });
          }
        }}
      >
        <div className="box-inside-wrapper">
          <img className="icon" src={icon} alt="icon" />
          <div className="name">{t("quarterReport", { quarter })}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
