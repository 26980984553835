import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import TooltipIcon from "../img/tooltipIcon.svg";
import PremiumIcon from "../img/Premium.svg";
import { Tooltip } from "react-tooltip";
import { ChartArea, ChartData } from "chart.js";
import { Sessions } from "../api/UserApi";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart as ChartJS } from "chart.js";
import { useTranslation } from "react-i18next";

ChartJS.register(zoomPlugin);

export function getSessionGraphData(
  sessions: Sessions,
  gradient: CanvasGradient | string = "#3CD6E080",
  amountOfDataPoints?: number
): ChartData<"line", number[], string> {
  const sessionsPerDay =
    amountOfDataPoints && sessions.sessionsPerDay.length > amountOfDataPoints
      ? sessions.sessionsPerDay.slice(-amountOfDataPoints)
      : sessions.sessionsPerDay;

  const datasets = [
    {
      label: "",
      data: sessions ? sessionsPerDay.map((item) => item.sessions) : [],
      backgroundColor: gradient,
      borderColor: gradient,
      fill: "start",
      tension: 0.5,
    },
  ];

  const labels = sessions ? sessionsPerDay.map((item) => item.day) : [];

  return {
    labels,
    datasets,
  };
}

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createLinearGradient(
    area.left,
    area.bottom,
    area.right,
    area.top
  );

  gradient.addColorStop(0, "#3CD6E080");
  gradient.addColorStop(1, "#6B98AA80");

  return gradient;
}

export function SessionGraph({
  sessions,
  premium = false,
}: {
  sessions: Sessions;
  premium?: boolean;
}) {
  const chartRef = useRef<any>(null);
  const { t } = useTranslation("hr-dashboard");

  const gradient = chartRef.current
    ? createGradient(chartRef.current.ctx, chartRef.current.chartArea)
    : "#3CD6E080";

  const sessionGraphData = getSessionGraphData(sessions, gradient, 30);

  const options = {
    aspectRatio: 1,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#000000",
        displayColors: false,
        bodyFont: {
          size: 12,
          weight: "700",
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x" as const,
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x" as const,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
        min: 0,
        max: Math.max(
          Math.round(Math.max(...sessionGraphData.datasets[0].data) * 1.2),
          10
        ),
      },
    },
  };

  return (
    <div className="graph">
      <div className={"graph-title flex items-center"}>
        {t("sessionGraph.title")}
        <div
          className={"flex items-center"}
          data-tooltip-id="graph-explanation"
          data-tooltip-content={t("sessionGraph.tooltipContent")}
        >
          <img className={"tooltip-icon"} src={TooltipIcon} alt={""} />
        </div>
        <Tooltip id={"graph-explanation"} className={"tooltip"} />
      </div>
      {!sessions || sessions.sessionsPerDay.length <= 1 ? (
        <div className={"ml-5 mt-4 mb-4"}>
          {t("sessionGraph.noDataMessage")}
        </div>
      ) : (
        <div className="graph-wrapper click">
          <Line
            style={{ maxHeight: "420px" }}
            ref={chartRef}
            data={sessionGraphData}
            options={options}
          />
        </div>
      )}
      {premium ? (
        <img className={"graph-premium"} src={PremiumIcon} alt={""} />
      ) : null}
    </div>
  );
}
