import React, { Fragment, useState } from "react";
import "./Faq.css";
import { Trans, useTranslation } from "react-i18next";
import { FaqItem } from "./FaqItem";
import { useEffectOnce } from "react-use";
import { getCmsItem } from "./api/CMSApi";
import { Footer } from "./Footer";

export function Faq({ authenticated }: { authenticated: boolean }) {
  const { t, i18n } = useTranslation("general");
  const [data, setData] = useState<any>([]);

  useEffectOnce(() => {
    getCmsItem("faq").then((result) => {
      try {
        const parsed = JSON.parse(result.data);

        setData(parsed || []);
      } catch (e) {
        console.log(e);
      }
    });
  });

  const faqItems = [
    { title: t("faq1-title"), text: t("faq1-text") },
    { title: t("faq2-title"), text: t("faq2-text") },
    { title: t("faq3-title"), text: t("faq3-text") },
    { title: t("faq4-title"), text: t("faq4-text") },
    { title: t("faq5-title"), text: t("faq5-text") },
    {
      title: t("faq6-title"),
      text: (
        <Trans t={t} i18nKey={"faq6-text"}>
          <a href={"https://www.bloomup.org"} />
        </Trans>
      ),
    },
    { title: t("faq7-title"), text: t("faq7-text") },
    { title: t("faq8-title"), text: t("faq8-text") },
    { title: t("faq9-title"), text: t("faq9-text") },
    { title: t("faq10-title"), text: t("faq10-text") },
    { title: t("faq11-title"), text: t("faq11-text") },
  ];

  return (
    <Fragment>
      <div className="container faq">
        <h1>FAQ</h1>
        <div>
          {data.map((item: any) => {
            return (
              <FaqItem
                title={
                  i18n.language === "nl"
                    ? item.questionNL
                    : i18n.language === "en"
                    ? item.questionEN
                    : item.questionFR
                }
                text={
                  i18n.language === "nl"
                    ? item.answerNL
                    : i18n.language === "en"
                    ? item.answerEN
                    : item.answerFR
                }
              />
            );
          })}
          {data.length === 0
            ? faqItems
                .filter((i) => i.title.trim() !== "")
                .map((item) => {
                  return <FaqItem title={item.title} text={item.text} />;
                })
            : null}
        </div>
      </div>
      <Footer authenticated={authenticated} />
    </Fragment>
  );
}
