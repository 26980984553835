import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { Test, getTest } from "../api/TestApi";
import { useEffectOnce } from "react-use";
import { LoadTest } from "./LoadTest";
import { useTranslation } from "react-i18next";

export function ShowTest() {
  const { testId } = useParams<{ testId: string }>();
  const [test, setTest] = useState<Test | null>(null);

  useEffectOnce(() => {
    getTest(testId).then((r) => {
      setTest(r);
    });
  });

  if (!test) {
    return null;
  }

  return (
    <div className="container mt-4">
      <h2>{test.title_nl}</h2>
      <div
        className="mb-4"
        dangerouslySetInnerHTML={{
          __html: test.description_nl,
        }}
      />
      <LoadTest test={test} />
    </div>
  );
}
