import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { createPodcast, Podcast, updatePodcast } from "../api/PodcastApi";
import { addMedia } from "../api/MediaApi";
import { Blog, createBlog, updateBlog } from "../api/BlogApi";
import { EditorState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import ReactQuill from "react-quill";
import { QuillFormats, QuillModules } from "../Modules/admins/LessonForm";

export function BlogForm({ blog }: { blog: Blog | null }) {
  const [nameNL, setNameNL] = useState(blog ? blog.nameNL : "");
  const [nameEN, setNameEN] = useState(blog ? blog.nameEN : "");
  const [nameFR, setNameFR] = useState(blog ? blog.nameFR : "");
  const [excerptNL, setExcerptNL] = useState(blog ? blog.excerptNL : "");
  const [excerptEN, setExcerptEN] = useState(blog ? blog.excerptEN : "");
  const [excerptFR, setExcerptFR] = useState(blog ? blog.excerptFR : "");
  const [contentNL, setContentNL] = useState(blog ? blog.contentNL : "");
  const [contentEN, setContentEN] = useState(blog ? blog.contentEN : "");
  const [contentFR, setContentFR] = useState(blog ? blog.contentFR : "");
  const [image, setImage] = useState(blog ? blog.image : "");
  const [banner, setBanner] = useState("");

  const [selectedImageFile, setSelectedImageFile] = useState<File | null>();
  const [selectedBannerFile, setSelectedBannerFile] = useState<File | null>();

  const [saved, setSaved] = useState(false);

  if (saved) {
    return <Redirect to={"/dashboard/blogs"} push />;
  }

  return (
    <>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          let updatedBusiness = {
            nameNL: nameNL,
            nameEN: nameEN || "",
            nameFR: nameFR || "",
            contentNL: contentNL,
            contentEN: contentEN,
            contentFR: contentFR,
            excerptNL: excerptNL,
            excerptEN: excerptEN,
            excerptFR: excerptFR,
            image: image,
            banner: banner,
          };

          if (selectedImageFile) {
            const pathResult = await addMedia(selectedImageFile);

            if (
              pathResult &&
              typeof pathResult !== "string" &&
              pathResult.path
            ) {
              updatedBusiness = {
                ...updatedBusiness,
                image: pathResult.path,
              };
            }
          }

          if (selectedBannerFile) {
            const pathResult = await addMedia(selectedBannerFile);

            if (
              pathResult &&
              typeof pathResult !== "string" &&
              pathResult.path
            ) {
              updatedBusiness = {
                ...updatedBusiness,
                banner: pathResult.path,
              };
            }
          }

          if (blog) {
            updateBlog({
              ...updatedBusiness,
              id: blog.id,
            }).then(async (r) => {
              setSaved(true);
            });
          } else {
            createBlog({
              id: "",
              ...updatedBusiness,
            }).then(async (r) => {
              setSaved(true);
            });
          }
        }}
      >
        <div className="flex justify-evenly pb-4">
          <div className="w-100">
            <div>
              <h4>Algemene info</h4>
              <label>Naam NL</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={nameNL}
                onChange={(input) => {
                  setNameNL(input.target.value);
                }}
                required={true}
              />
              <label>Naam EN</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={nameEN}
                onChange={(input) => {
                  setNameEN(input.target.value);
                }}
                required={true}
              />
              <label>Naam FR</label>
              <input
                className="input"
                type="text"
                placeholder="Naam"
                value={nameFR}
                onChange={(input) => {
                  setNameFR(input.target.value);
                }}
              />
              <label>Korte tekst NL</label>
              <input
                className="input"
                type="text"
                placeholder="Tekst"
                value={excerptNL}
                onChange={(input) => {
                  setExcerptNL(input.target.value);
                }}
                required={true}
              />
              <label>Korte tekst EN</label>
              <input
                className="input"
                type="text"
                placeholder="Tekst"
                value={excerptEN}
                onChange={(input) => {
                  setExcerptEN(input.target.value);
                }}
                required={true}
              />
              <label>Korte tekst FR</label>
              <input
                className="input"
                type="text"
                placeholder="Tekst"
                value={excerptFR}
                onChange={(input) => {
                  setExcerptFR(input.target.value);
                }}
              />
              <label>Afbeelding</label>
              <input
                type="file"
                className="input"
                placeholder="Upload afbeelding"
                onChange={(e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    setSelectedImageFile(e.target.files[0]);
                  }
                }}
              />
              <div className="container">
                <div className="flex">
                  <h4>Content in het Nederlands</h4>
                </div>
                <div style={{ backgroundColor: "white" }}>
                  <ReactQuill
                    theme="snow"
                    value={contentNL}
                    onChange={(value) => {
                      setContentNL(value);
                    }}
                    formats={QuillFormats}
                    modules={QuillModules}
                  />
                </div>
              </div>
              <div className="container">
                <div className="flex">
                  <h4>Content in het Engels</h4>
                </div>
                <div style={{ backgroundColor: "white" }}>
                  <ReactQuill
                    theme="snow"
                    value={contentEN}
                    onChange={(value) => {
                      setContentEN(value);
                    }}
                    formats={QuillFormats}
                    modules={QuillModules}
                  />
                </div>
              </div>
              <div className="container">
                <div className="flex">
                  <h4>Content in het Frans</h4>
                </div>
                <div style={{ backgroundColor: "white" }}>
                  <ReactQuill
                    theme="snow"
                    value={contentFR}
                    onChange={(value) => {
                      setContentFR(value);
                    }}
                    formats={QuillFormats}
                    modules={QuillModules}
                  />
                </div>
              </div>

              <button className="button" type="submit">
                {blog ? "Blog bijwerken" : "Blog toevoegen"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
