import { API_ENDPOINT, getHeaders } from "./AuthApi";

export type QuizType = {
  id: string;
  quiz: {
    questionNL: string;
    questionEN: string;
    questionFR: string;
    type: string;
    optionsNL: { value: string; label: string }[];
    optionsEN: { value: string; label: string }[];
    optionsFR: { value: string; label: string }[];
    answer: string;
  }[];
};

export async function getQuizes(): Promise<QuizType[]> {
  const result = await fetch(`${API_ENDPOINT}/quizzes`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.quizzes) {
    return [];
  }

  return result.quizzes;
}

export async function createQuiz(quiz: QuizType): Promise<QuizType> {
  const result = await fetch(`${API_ENDPOINT}/quizzes`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(quiz),
  }).then((r) => r.json());

  return result;
}

export async function updateQuiz(quiz: QuizType): Promise<QuizType> {
  const result = await fetch(`${API_ENDPOINT}/quizzes/${quiz.id}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(quiz),
  }).then((r) => r.json());

  return result;
}

export async function deleteQuiz(quizId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/quizzes/${quizId}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((r) => r.json());
}

export async function getQuiz(quizId: string): Promise<QuizType> {
  const result = await fetch(`${API_ENDPOINT}/quizzes/${quizId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result;
}
