import { ApolloClient, InMemoryCache } from "@apollo/client";
import { httpLink } from ".";
import { getTokenExpiry } from "./getTokenExpiry";
import { graphql } from "../__generated__";

export const REFRESH_TOKEN_MUTATION = graphql(`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessTokenV2(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`);
export const getValidBloomupToken = async () => {
  const bloomupToken = localStorage.getItem("bloomupAccessToken");

  if (getTokenExpiry(bloomupToken) * 1000 > Date.now()) {
    return bloomupToken;
  }

  const bloomupRefreshToken = localStorage.getItem("bloomupRefreshToken");

  if (
    !bloomupRefreshToken ||
    getTokenExpiry(bloomupRefreshToken) * 1000 <= Date.now()
  ) {
    localStorage.removeItem("bloomupRefreshToken");
    localStorage.removeItem("bloomupAccessToken");
    return null;
  }

  const tempApolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
    name:
      "mindlab-frontend-refresh-client-" + process.env.REACT_APP_FRONTEND_TYPE,
  });

  const { data } = await tempApolloClient.mutate({
    mutation: REFRESH_TOKEN_MUTATION,
    variables: { refreshToken: bloomupRefreshToken },
  });

  tempApolloClient.stop();

  const newAccessToken = data?.refreshAccessTokenV2.accessToken;
  if (!newAccessToken) {
    localStorage.removeItem("bloomupRefreshToken");
    localStorage.removeItem("bloomupAccessToken");
    return null;
  }

  localStorage.setItem("bloomupAccessToken", newAccessToken);
  if (data.refreshAccessTokenV2.refreshToken) {
    localStorage.setItem(
      "bloomupRefreshToken",
      data.refreshAccessTokenV2.refreshToken
    );
  }
  return newAccessToken;
};
