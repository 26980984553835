import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { UserForm } from "./UserForm";
import { getUser, User } from "../api/UserApi";

export function EditUser() {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<User | null>(null);

  useEffectOnce(() => {
    getUser(userId).then((r) => {
      setUser(r);
    });
  });

  if (!user) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <UserForm user={user} />
    </Sidebar>
  );
}
