import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { createEvaluation } from "../api/EvaluationsApi";
import { useTranslation } from "react-i18next";

export function Evaluation({ id }: { id: string }) {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [tevreden, setTevreden] = useState("10");
  const [interessant, setInteressant] = useState("");
  const [minder, setMinder] = useState("");
  const [onduidelijk, setOnduidelijk] = useState("");
  const [relevant, setRelevant] = useState("");
  const [aanbevelen, setAanbevelen] = useState("10");
  const [opmerkingen, setOpmerkingen] = useState("");
  const [ervaring, setErvaring] = useState("");
  const [voornaam, setVoornaam] = useState("");
  const [woonplaats, setWoonplaats] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const { t } = useTranslation("general");

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        await createEvaluation(
          {
            score: parseInt(tevreden),
            interesting: interessant,
            less: minder,
            unclear: onduidelijk,
            relevant: relevant,
            recommendation: parseInt(aanbevelen),
            remarks: opmerkingen,
            experience: ervaring,
            name: voornaam !== "" ? voornaam : undefined,
            place: woonplaats !== "" ? woonplaats : undefined,
          },
          moduleId
        );

        setSubmitted(true);
      }}
    >
      <label>{t("evaluation.score")}</label>
      <div className="flex">
        {Array.from(Array(11).keys()).map((item) => {
          return (
            <div className="flex flex-column items-center" key={item}>
              <input
                type="radio"
                name="tevreden"
                onChange={(e) => {
                  setTevreden(e.target.value);
                }}
                required
                value={item}
                checked={item === parseInt(tevreden)}
              />{" "}
              {item}
            </div>
          );
        })}
      </div>
      <div className="mt-4" />
      <label>{t("evaluation.interesting")}</label>
      <input
        type="text"
        value={interessant}
        onChange={(e) => {
          setInteressant(e.target.value);
        }}
      />
      <div className="mt-4" />
      <label>{t("evaluation.less")}</label>
      <input
        type="text"
        value={minder}
        onChange={(e) => {
          setMinder(e.target.value);
        }}
      />
      <div className="mt-4" />
      <label>{t("evaluation.unclear")}</label>
      <input
        type="text"
        value={onduidelijk}
        onChange={(e) => {
          setOnduidelijk(e.target.value);
        }}
      />
      <div className="mt-4" />
      <label>{t("evaluation.relevant")}</label>
      <input
        type="text"
        value={relevant}
        onChange={(e) => {
          setRelevant(e.target.value);
        }}
      />
      <div className="mt-4" />
      <label>{t("evaluation.recommendation")}</label>
      <div className="flex">
        {Array.from(Array(11).keys()).map((item) => {
          return (
            <div className="flex flex-column items-center" key={item}>
              <input
                type="radio"
                name="aanbevelen"
                onChange={(e) => {
                  setAanbevelen(e.target.value);
                }}
                required
                value={item}
                checked={item === parseInt(aanbevelen)}
              />{" "}
              {item}
            </div>
          );
        })}
      </div>
      <div className="mt-4" />
      <label>{t("evaluation.remarks")}</label>
      <textarea
        value={opmerkingen}
        onChange={(e) => {
          setOpmerkingen(e.target.value);
        }}
      />
      <div className="mt-4" />
      <label>{t("evaluation.experience")}</label>
      <textarea
        value={ervaring}
        onChange={(e) => {
          setErvaring(e.target.value);
        }}
      />
      <div className="mt-4" />
      <label>{t("evaluation.name")}</label>
      <input
        type="text"
        value={voornaam}
        onChange={(e) => {
          setVoornaam(e.target.value);
        }}
      />
      <div className="mt-4" />
      <label>{t("evaluation.place")}</label>
      <input
        type="text"
        value={woonplaats}
        onChange={(e) => {
          setWoonplaats(e.target.value);
        }}
      />
      {submitted ? (
        <div className="green mt-4 mb-4">Succesvol ingediend!</div>
      ) : null}
      <button type="submit" className="button w-fit">
        {t("evaluation.send")}
      </button>
    </form>
  );
}
