import React from "react";
import { getCorrectUrl } from "../Utils";

export function Image({ url }: { url: string }) {
  const regexUrl = /url="([^;]*)"/gm;
  const regexWidth = /width="([^;]*)"/gm;
  const regexHeight = /height="([^;]*)"/gm;
  const regexFloat = /float="([^;]*)"/gm;
  const regexPosition = /position="([^;]*)"/gm;
  const resultUrl = regexUrl.exec(url);
  const resultWidth = regexWidth.exec(url);
  const resultHeight = regexHeight.exec(url);
  const resultFloat = regexFloat.exec(url);
  const resultPosition = regexPosition.exec(url);

  const correctUrl = (resultUrl && resultUrl[1]) || "";
  const width = (resultWidth && resultWidth[1]) || "100%";
  const height = (resultHeight && resultHeight[1]) || "auto";
  const position = (resultPosition && resultPosition[1]) || "initial";
  const float =
    ((resultFloat && resultFloat[1]) as
      | "none"
      | "-moz-initial"
      | "inherit"
      | "initial"
      | "revert"
      | "unset"
      | "inline-end"
      | "inline-start"
      | "left"
      | "right"
      | undefined) || "none";

  return (
    <div>
      <img
        className="fit-object"
        src={getCorrectUrl(correctUrl)}
        alt={"Module Image"}
        style={{
          height: height,
          width: width,
          float: float,
          maxHeight: height !== "auto" ? "100%" : "calc(100vh - 300px)",
          objectPosition: position,
        }}
      />
    </div>
  );
}
