import React, { useCallback, useEffect, useState } from "react";
import { getDecodedToken } from "./api/AuthApi";
import "./Profile.css";
import { useEffectOnce } from "react-use";
import {
  deleteCurrentUser,
  getCurrentUser,
  getTotalTimes,
  getModuleCount,
} from "./api/UserApi";
import { Dialog } from "./Dialog";
import { ConnectedBusiness, getConnectedBusinesses } from "./api/BusinessApi";
import {
  getModuleAccess,
  getModules,
  Module as ModuleType,
} from "./api/ModulesApi";
import { ModuleView } from "./Modules/users/Modules";
import { getCorrectUrl, isB2C } from "./Utils";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Duration } from "luxon";
import { getLessons, Lesson } from "./api/LessonsApi";
import { Loading } from "./Loading";
import { useApolloClient, useMutation } from "@apollo/client";
import { graphql } from "./__generated__";

const request2FASecretsMutation = graphql(`
  mutation Request2FASecrets($password: String!) {
    request2FASecrets(password: $password) {
      qrCode
      secret
    }
  }
`);

const enable2FAMutation = graphql(`
  mutation Enable2FA($input: Enable2FAInput!) {
    enable2FA(input: $input)
  }
`);

const joinOrgByEmailOrCodeMutation = graphql(`
  mutation joinOrgByEmailOrCodeProfile($emailOrCode: String!) {
    joinOrganizationByEmailOrCodeV2(emailOrCode: $emailOrCode)
  }
`);

export function Enable2FA({
  close,
  givenPassword,
}: {
  close: () => void;
  givenPassword?: string;
}) {
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const [
    bloomupRequest2FASecrets,
    { data, loading: request2FASecretsLoading, error: request2FASecretsError },
  ] = useMutation(request2FASecretsMutation);
  const request2FASecrets = data?.request2FASecrets;

  const [bloomupEnable2FA, { error: enable2FAError }] = useMutation(
    enable2FAMutation
  );

  const { t } = useTranslation(["adminProfile", "login"]);

  useEffect(() => {
    if (givenPassword !== undefined) {
      bloomupRequest2FASecrets({ variables: { password: givenPassword } });
    }
  }, [bloomupRequest2FASecrets, givenPassword]);

  const handle2FACodeSubmission = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (request2FASecrets) {
        try {
          const result = await bloomupEnable2FA({
            variables: {
              input: {
                token: code,
                secret: request2FASecrets.secret,
              },
            },
          });
          if (result.data?.enable2FA) {
            close();
          }
        } catch (e) {
          // Do nothing, error is set in the hook
        }
      }
    },
    [bloomupEnable2FA, close, request2FASecrets, code]
  );

  if (request2FASecretsLoading) {
    return <Loading />;
  }

  return (
    <div>
      {request2FASecrets ? (
        <div className="flex items-center flex-column">
          <div>{t("scan")}</div>
          <img
            className="mt-4 mb-4 qr"
            alt="2FA QR"
            src={request2FASecrets.qrCode}
          />
          <div className="text-center">Secret: {request2FASecrets.secret}</div>
          <br />
          <form onSubmit={handle2FACodeSubmission}>
            <label>Code</label>
            <input
              type="text"
              placeholder="Code"
              value={code}
              onChange={(input) => {
                setCode(input.target.value);
              }}
              required={true}
            />
            {enable2FAError && (
              <div className="mt-4">{t("login:2FASetFailed")}</div>
            )}
            <button className="button mt-4" type="submit">
              {t("confirm")}
            </button>
          </form>
        </div>
      ) : (
        <div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                await bloomupRequest2FASecrets({
                  variables: { password },
                });
              } catch (e) {
                // do nothing, error will be set in the hook
              }
            }}
          >
            <label>{t("fill_password")}</label>
            <input
              type="password"
              placeholder={t("password")}
              value={password}
              onChange={(input) => {
                setPassword(input.target.value);
              }}
              required={true}
            />
            {request2FASecretsError && (
              <div className="mt-4">{t("login:2FASetFailed")}</div>
            )}
            <button className="button w-fit yellow" type="submit">
              {t("enable2fa")}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

function ProfileSidebar({
  showProfile,
  setShowProfile,
  showBusiness,
  setShowBusiness,
  showModules,
  setShowModules,
  showTimes,
  setShowTimes,
}: {
  showProfile: boolean;
  setShowProfile: (b: boolean) => void;
  showBusiness: boolean;
  setShowBusiness: (b: boolean) => void;
  showModules: boolean;
  setShowModules: (b: boolean) => void;
  showTimes: boolean;
  setShowTimes: (b: boolean) => void;
}) {
  function resetSidebar() {
    setShowProfile(false);
    setShowBusiness(false);
    setShowModules(false);
    setShowTimes(false);
  }

  const { t } = useTranslation("adminProfile");

  return (
    <div className="profile-sidebar">
      <div
        className={`profile-sidebar-item ${showProfile ? "active" : ""}`}
        onClick={() => {
          resetSidebar();
          setShowProfile(true);
        }}
      >
        {t("profile")}
      </div>
      {isB2C() ? null : (
        <div
          className={`profile-sidebar-item ${showBusiness ? "active" : ""}`}
          onClick={() => {
            resetSidebar();
            setShowBusiness(true);
          }}
        >
          {t("business")}
        </div>
      )}

      <div
        className={`profile-sidebar-item ${showModules ? "active" : ""}`}
        onClick={() => {
          resetSidebar();
          setShowModules(true);
        }}
      >
        {t("modules")}
      </div>

      <div
        className={`profile-sidebar-item ${showTimes ? "active" : ""}`}
        onClick={() => {
          resetSidebar();
          setShowTimes(true);
        }}
      >
        {t("times")}
      </div>
    </div>
  );
}

export function Profile() {
  const apolloClient = useApolloClient();
  const [editingProfile, setEditingProfile] = useState(false);
  const token = getDecodedToken();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [language, setLanguage] = useState("nl");
  const [code, setCode] = useState("");
  const [oldCode, setOldCode] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [enable2FA, setEnable2FA] = useState(false);
  const [connectedBusinesses, setConnectedBusinesses] = useState<
    ConnectedBusiness[]
  >([]);
  const [joinOrganizationByEmailOrCode, { error: joinOrgError }] = useMutation(
    joinOrgByEmailOrCodeMutation
  );

  const [showProfile, setShowProfile] = useState(true);
  const [showBusiness, setShowBusiness] = useState(false);
  const [showModules, setShowModules] = useState(false);
  const [showTimes, setShowTimes] = useState(false);

  const [modules, setModules] = useState<ModuleType[]>([]);
  const [moduleAccess, setModuleAccess] = useState<string[]>([]);
  const [times, setTimes] = useState({
    tests: 0,
    podcasts: 0,
    modules: 0,
  });
  const [lessons, setLessons] = useState<Lesson[]>([]);

  const [isDeleting, setIsDeleting] = useState(false);

  const { t, i18n } = useTranslation("adminProfile");

  const [hasSkills, setHasSkills] = useState(false);

  const [progressCount, setProgressCount] = useState<Record<string, number>>(
    {}
  );

  const refreshUser = () => {
    // TODO: add loading
    getCurrentUser().then((r) => {
      setFirstName(r.firstName);
      setLastName(r.lastName);
      setNewsletter(r.newsletter);
      setTwoFactorAuth(r.twoFactorAuthEnabled);
      setLanguage(r.language);
    });

    getTotalTimes().then((r) => {
      setTimes(r);
    });

    getConnectedBusinesses().then((r) => {
      setConnectedBusinesses(r);

      if (r.length > 0) {
        setOldCode(r[0].business.code);
        setCode(r[0].business.code);
      }

      setHasSkills(r.filter((b) => b.business.type >= 1).length >= 1);
    });

    getModules().then((result) => {
      setModules(result);
    });

    getModuleAccess().then((result) => {
      setModuleAccess(result);
    });

    getLessons().then((result) => {
      setLessons(result);
    });

    getModuleCount().then((result) => {
      setProgressCount(result);
    });
  };

  useEffectOnce(() => {
    refreshUser();
  });

  return (
    <div className="container">
      <div className="profile-name flex">
        <div>{token.firstName}</div>
        {showProfile ? (
          <div
            className="button ml-auto"
            onClick={async () => {
              if (editingProfile) {
                // await updateCurrentUser(
                //   firstName,
                //   lastName,
                //   newsletter,
                //   language
                // );
                if (code !== oldCode) {
                  await joinOrganizationByEmailOrCode({
                    variables: {
                      emailOrCode: code.toLowerCase().trim(),
                    },
                  });
                }
                refreshUser();
              }
              setEditingProfile(!editingProfile);
            }}
          >
            {editingProfile ? t("save_profile") : t("edit_profile")}
          </div>
        ) : null}

        {/* <div
          className={`button pink ${showProfile ? "ml-5" : "ml-auto"}`}
          onClick={() => {
            setIsDeleting(true);
          }}
        >
          {t("delete_profile")}
        </div> */}
      </div>
      {joinOrgError && (
        <div className="mt-4">{t("general:joinBusinessFailed")}</div>
      )}
      <div className="profile-settings flex">
        <ProfileSidebar
          showProfile={showProfile}
          setShowProfile={setShowProfile}
          showBusiness={showBusiness}
          setShowBusiness={setShowBusiness}
          setShowModules={setShowModules}
          showModules={showModules}
          showTimes={showTimes}
          setShowTimes={setShowTimes}
        />
        <div className="profile-content">
          {showProfile ? (
            <>
              <div className="profile-content-title">{t("details")}</div>
              <div className="hr" />
              <table>
                <tbody>
                  <tr>
                    <td className="label">{t("first_name")}</td>
                    <td>
                      {editingProfile ? (
                        <input
                          type="text"
                          placeholder={t("first_name")}
                          value={firstName}
                          onChange={(input) => {
                            setFirstName(input.target.value);
                          }}
                          required={true}
                          disabled
                        />
                      ) : (
                        token.firstName
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t("last_name")}</td>
                    <td>
                      {editingProfile ? (
                        <input
                          type="text"
                          placeholder={t("last_name")}
                          value={lastName}
                          onChange={(input) => {
                            setLastName(input.target.value);
                          }}
                          required={true}
                          disabled
                        />
                      ) : (
                        token.lastName
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t("newsletter_name")}</td>
                    <td>
                      {/* {editingProfile ? (
                        <Checkbox
                          title={t("newsletter")}
                          value={newsletter}
                          onClick={(checked) => {
                            setNewsletter(checked);
                          }}
                        />
                      ) : newsletter ? (
                        t("yes")
                      ) : (
                        t("no")
                      )} */}
                      {newsletter ? t("yes") : t("no")}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t("language")}</td>
                    <td>
                      {editingProfile ? (
                        <Select
                          options={[
                            { label: "nl", value: "nl" },
                            { label: "en", value: "en" },
                            { label: "fr", value: "fr" },
                          ]}
                          onChange={async (e: any) => {
                            await i18n.changeLanguage(e.value);
                            setLanguage(e.value);
                          }}
                          value={{ label: language, value: language }}
                          isDisabled={true}
                        />
                      ) : (
                        language
                      )}
                    </td>
                  </tr>
                  {isB2C() ? null : editingProfile ? (
                    <tr>
                      <td className="label">{t("code")}</td>
                      <td>
                        <input
                          type="text"
                          placeholder={t("code")}
                          value={code}
                          onChange={(input) => {
                            setCode(input.target.value);
                          }}
                        />
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td className="label">2FA</td>
                    <td>
                      {editingProfile ? (
                        twoFactorAuth ? (
                          "Uw 2FA is al ingeschakeld"
                        ) : (
                          <div
                            className="button w-fit yellow"
                            onClick={() => {
                              setEnable2FA(true);
                            }}
                          >
                            {t("enable2fa")}
                          </div>
                        )
                      ) : twoFactorAuth ? (
                        t("yes")
                      ) : (
                        t("no")
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : null}
          {showTimes ? (
            <>
              <div className="profile-content-title">{t("times")}</div>
              <div className="hr" />
              <table>
                <tbody>
                  <tr>
                    <td className="label">{t("modules")}</td>
                    <td>
                      {Duration.fromMillis(times.modules * 1000 * 60).toFormat(
                        "hh'h'mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t("tests")}</td>
                    <td>
                      {Duration.fromMillis(times.tests * 1000 * 60).toFormat(
                        "hh'h'mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="label">{t("podcasts")}</td>
                    <td>
                      {Duration.fromMillis(times.podcasts * 1000 * 60).toFormat(
                        "hh'h'mm"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : null}
          {showBusiness ? (
            <>
              <div className="profile-content-title">{t("business")}</div>
              <div className="hr" />
              {connectedBusinesses.map((business) => {
                return (
                  <div className="flex justify-center flex-column items-center">
                    <img
                      src={getCorrectUrl(business.business.logo)}
                      alt={business.business.name}
                    />
                    <h2>{business.business.name}</h2>
                    <div
                      style={{ whiteSpace: "break-spaces" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          i18n.language === "nl"
                            ? `${business.business.info_nl}`
                            : i18n.language === "en"
                            ? `${business.business.info_en}`
                            : `${business.business.info_fr}`,
                      }}
                    />
                  </div>
                );
              })}
            </>
          ) : null}
          {showModules ? (
            <div className="flex wrap">
              {modules
                .filter((m) => !m.skills || (m.skills && hasSkills))
                .filter((m) => {
                  return (
                    (i18n.language === "fr" && m.titleFR.trim() !== "") ||
                    (i18n.language === "en" && m.titleEN.trim() !== "") ||
                    i18n.language === "nl"
                  );
                })
                .filter((module) => moduleAccess.includes(module.id))
                .map((module, index) => {
                  return (
                    <ModuleView
                      key={index}
                      module={module}
                      available={true}
                      lessons={lessons.filter(
                        (lesson) => lesson.moduleId === module.id
                      )}
                      progressCount={progressCount[module.id] || 0}
                    />
                  );
                })}
            </div>
          ) : null}
        </div>
      </div>
      <Dialog
        isOpen={enable2FA}
        onRequestClose={() => {
          setEnable2FA(false);
        }}
        title={t("setup2FA")}
        size="small"
        padding={true}
      >
        <Enable2FA
          close={() => {
            setEnable2FA(false);
            setTwoFactorAuth(true);
          }}
        />
      </Dialog>
      <Dialog
        isOpen={isDeleting}
        onRequestClose={() => {
          setIsDeleting(false);
        }}
        title={t("delete_profile")}
        size="small"
        padding={true}
      >
        <div>{t("delete_confirm")}</div>
        <div
          className="button pink mt-4 w-fit"
          onClick={async () => {
            await deleteCurrentUser();
            localStorage.clear();
            apolloClient.resetStore();
            window.location.href = "/";
          }}
        >
          {t("delete")}
        </div>
      </Dialog>
    </div>
  );
}
