import React from "react";
import ReactModal from "react-modal";
import "./FaceScannerModal.css";
import { FaceScanner } from "./FaceScanner";
import { FaceScannerInfo } from "./FaceScannerInfo";

export function FaceScannerInfoModal({
  isOpen,
  onRequestClose,
  onAcceptScan,
  periodic,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  onAcceptScan: () => void;

  periodic: boolean; // whether scan is a periodic scan or scan started by other event (e.g. user chose to scan themselves or as part of exercise)
}) {
  return (
    <ReactModal
      isOpen={isOpen}
      appElement={window.document.getElementById("root") as HTMLElement}
      onRequestClose={onRequestClose}
      className="scanner-body"
      overlayClassName="scanner-overlay"
      bodyOpenClassName="scanner-open"
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
    >
      <FaceScannerInfo
        onRequestClose={onRequestClose}
        onAcceptScan={onAcceptScan}
      />
    </ReactModal>
  );
}
