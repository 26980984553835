import React, { useState } from "react";
import {
  addModuleExerciseResult,
  getModuleExerciseResult,
} from "../api/UserApi";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import "./MoodForm.css";

export function BoetseerForm({ id }: { id: string }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [savedInput1, setSavedInput1] = useState<string>("");
  const [savedInput2, setSavedInput2] = useState<string>("");
  const [savedInput3, setSavedInput3] = useState<string>("");
  const [savedInput4, setSavedInput4] = useState<string>("");
  const [saved, setSaved] = useState(false);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getModuleExerciseResult(lessonId).then((result) => {
      const currentResult1 = result.find(
        (r) => r.key === `BoetseerForm1-${id}`
      );

      if (currentResult1) {
        setSavedInput1(JSON.parse(currentResult1.result));
      }

      const currentResult2 = result.find(
        (r) => r.key === `BoetseerForm2-${id}`
      );

      if (currentResult2) {
        setSavedInput1(JSON.parse(currentResult2.result));
      }

      const currentResult3 = result.find(
        (r) => r.key === `BoetseerForm3-${id}`
      );

      if (currentResult3) {
        setSavedInput1(JSON.parse(currentResult3.result));
      }

      const currentResult4 = result.find(
        (r) => r.key === `BoetseerForm4-${id}`
      );

      if (currentResult4) {
        setSavedInput1(JSON.parse(currentResult4.result));
      }
    });
  });

  return (
    <div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <label>
          {i18n.language === "nl" && "Heel graag"}
          {i18n.language === "en" && "Like very much"}
          {i18n.language === "fr" && "Aimer beaucoup"}
        </label>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <label>
            {i18n.language === "nl" && "Niet goed"}
            {i18n.language === "en" && "Not good"}
            {i18n.language === "fr" && "Pas bien"}
          </label>
        </div>
        <div style={{ width: "75%", margin: "0 10px" }}>
          <div style={{ borderBottom: "1px solid #689DC5", display: "flex" }}>
            <div style={{ width: "50%", borderRight: "1px solid #689DC5" }}>
              <textarea
                rows={6}
                style={{ width: "100%" }}
                placeholder="1."
                value={savedInput1}
                onChange={(e) => {
                  setSavedInput1(e.target.value);
                }}
              />
            </div>
            <div style={{ width: "50%", borderLeft: "1px solid #689DC5" }}>
              <textarea
                rows={6}
                style={{ width: "100%" }}
                placeholder="2."
                value={savedInput2}
                onChange={(e) => {
                  setSavedInput2(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ borderTop: "1px solid #689DC5", display: "flex" }}>
            <div style={{ width: "50%", borderRight: "1px solid #689DC5" }}>
              <textarea
                rows={6}
                style={{ width: "100%" }}
                placeholder="4."
                value={savedInput4}
                onChange={(e) => {
                  setSavedInput4(e.target.value);
                }}
              />
            </div>
            <div style={{ width: "50%", borderLeft: "1px solid #689DC5" }}>
              <textarea
                rows={6}
                style={{ width: "100%" }}
                placeholder="3."
                value={savedInput3}
                onChange={(e) => {
                  setSavedInput3(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <label>
            {i18n.language === "nl" && "Heel goed"}
            {i18n.language === "en" && "Very good"}
            {i18n.language === "fr" && "Tres bien"}
          </label>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <label>
          {i18n.language === "nl" && "Niet graag"}
          {i18n.language === "en" && "Don’t like"}
          {i18n.language === "fr" && "Ne pas aimer"}
        </label>
      </div>
      {saved ? <div className="green mt-4 mb-4">{t("saved")}!</div> : null}
      <div
        className="button w-fit mb-4"
        onClick={async (e) => {
          e.preventDefault();

          await addModuleExerciseResult(
            lessonId,
            `BoetseerForm1-${id}`,
            JSON.stringify(savedInput1)
          );

          await addModuleExerciseResult(
            lessonId,
            `BoetseerForm2-${id}`,
            JSON.stringify(savedInput2)
          );

          await addModuleExerciseResult(
            lessonId,
            `BoetseerForm3-${id}`,
            JSON.stringify(savedInput3)
          );

          await addModuleExerciseResult(
            lessonId,
            `BoetseerForm4-${id}`,
            JSON.stringify(savedInput4)
          );

          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 3000);
        }}
      >
        {t("bewaar")}
      </div>
    </div>
  );
}
