import React, { useState } from "react";
import { FavoriteType, getFavorites } from "./api/FavoritesApi";
import { useEffectOnce } from "react-use";
import { getLessonById, Lesson } from "./api/LessonsApi";
import { Link } from "react-router-dom";
import { ToolboxHeader } from "./ToolboxHeader";
import Favoriet from "./img/favoriet.png";
import "./Favorites.css";
import { useTranslation } from "react-i18next";

export function Favorites() {
  const [favorites, setFavorites] = useState<FavoriteType[]>([]);
  const [lessons, setLessons] = useState<Lesson[]>([]);

  const { t, i18n } = useTranslation("general");

  useEffectOnce(() => {
    getFavorites().then(async (result) => {
      setFavorites(result);

      const tmpLessons = [];

      for (const favorite of result) {
        const lesson = await getLessonById(favorite.lessonId);
        tmpLessons.push(lesson);
      }

      setLessons(tmpLessons);
    });
  });

  return (
    <ToolboxHeader>
      <div className="container crisishulp w-100">
        <div className="tools">
          <div className="bread">
            <img src={Favoriet} />
            <div className="title">{t("toolbox.favo_name")}</div>
          </div>
        </div>
        <div className="favorites">
          {favorites.map((item) => {
            const lesson = lessons.find((l) => l.id === item.lessonId);

            return (
              <div>
                <div className="favorite-item">
                  <Link
                    to={`/modules/${lesson?.moduleId}/subjects/${lesson?.subjectId}/lessons/${lesson?.id}`}
                  >
                    {i18n.language === "nl" && lesson?.nameNL}
                    {i18n.language === "en" && lesson?.nameEN}
                    {i18n.language === "fr" && lesson?.nameFR}
                  </Link>
                </div>
                <hr />
              </div>
            );
          })}
        </div>
      </div>
    </ToolboxHeader>
  );
}
