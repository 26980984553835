import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Sidebar } from "../Sidebar";
import { Blog, getBlogs } from "../api/BlogApi";
import { BlogForm } from "./BlogForm";

export function EditBlog() {
  const { blogId } = useParams<{ blogId: string }>();
  const [blog, setBlog] = useState<Blog | null>(null);

  useEffectOnce(() => {
    getBlogs().then((r) => {
      setBlog(r.find((c) => c.id === blogId) || null);
    });
  });

  if (!blog) {
    return <Sidebar />;
  }

  return (
    <Sidebar>
      <BlogForm blog={blog} />
    </Sidebar>
  );
}
