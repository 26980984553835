import i18n from "i18next";
import { initReactI18next, UseTranslationResponse } from "react-i18next";

import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const fallbackLng = ["nl"];
const availableLanguages = ["nl", "fr", "en"];

// @ts-ignore
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLng,
    debug: true,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

// Eventually this should be generated from the json files
export type TranslationResponseType = UseTranslationResponse<string, string>;

export default i18n;
