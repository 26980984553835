import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { useHistory } from "react-router-dom";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { UserDashboardSidebar } from "./UserDashboardSidebar";
import { RowData, Table } from "./Table";
import { getFavorites } from "./api/FavoritesApi";
import { getLessonById, Lesson } from "./api/LessonsApi";
import { getModule } from "./api/ModulesApi";
import "./UserDashboardFavoriteLessons.css";

export function UserDashboardFavoriteLessons() {
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const [rowData, setRowData] = useState<
    RowData<string, string, string, undefined>
  >();

  const { t, i18n } = useTranslation("user-dashboard");
  const history = useHistory();

  useEffectOnce(() => {
    getFavorites().then(async (result) => {
      const tmpLessons = [];

      for (const favorite of result) {
        const lesson = await getLessonById(favorite.lessonId);
        tmpLessons.push(lesson);
      }

      setLessons(tmpLessons);
    });
  });

  useEffect(() => {
    (async () => {
      if (lessons) {
        const rowData: RowData<string, string, string, undefined> = [];
        for (const lesson of lessons) {
          const module = await getModule(lesson.moduleId);
          if (i18n.language === "nl") {
            rowData.push({
              col1Value: module.titleNL,
              col2Value: lesson.nameNL,
              buttonColValue: lesson.id,
              type: undefined,
            });
          } else if (i18n.language === "en") {
            rowData.push({
              col1Value: module.titleEN,
              col2Value: lesson.nameEN,
              buttonColValue: lesson.id,
              type: undefined,
            });
          } else if (i18n.language === "fr") {
            rowData.push({
              col1Value: module.titleFR,
              col2Value: lesson.nameFR,
              buttonColValue: lesson.id,
              type: undefined,
            });
          }
        }
        setRowData(rowData);
      }
    })();
  }, [lessons, i18n.language]);

  return (
    <UserDashboardSidebar>
      <div className="dashboard-favorite-lessons">
        <h2 className="title-underlined">{t("favoriteLessons.title")}</h2>
        {rowData ? (
          <Table<string, string, string, undefined>
            rowData={rowData}
            col1Data={{
              headerName: t("favoriteLessons.module"),
            }}
            col2Data={{
              headerName: t("favoriteLessons.lesson"),
            }}
            buttonIcon={faCaretRight}
            onButtonClick={async (lessonId) => {
              const lesson = await getLessonById(lessonId);
              history.push(
                `/modules/${lesson.moduleId}/subjects/${lesson?.subjectId}/lessons/${lessonId}`
              );
            }}
          />
        ) : null}
      </div>
    </UserDashboardSidebar>
  );
}
