import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { ModuleForm } from "./ModuleForm";
import { Sidebar } from "../../Sidebar";
import { getModule, Module } from "../../api/ModulesApi";

export function EditModule() {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [module, setModule] = useState<Module | null>(null);

  useEffectOnce(() => {
    getModule(moduleId).then((r) => {
      setModule(r);
    });
  });

  if (!module) {
    return null;
  }

  return (
    <Sidebar>
      <ModuleForm module={module} />
    </Sidebar>
  );
}
