import React, { useEffect, useState } from "react";
import "./About.css";
import iCGT from "./img/icgt.png";
import Logo from "./img/Mindlab by BloomUp.svg";
import Bloomup from "./img/Logo - no tagline.svg";
import { useTranslation } from "react-i18next";
import { getCmsItem } from "./api/CMSApi";
import { Footer } from "./Footer";

export function About({ authenticated }: { authenticated: boolean }) {
  const { t, i18n } = useTranslation("general");

  const [data, setData] = useState<any>({});

  useEffect(() => {
    getCmsItem("about-us").then((result) => {
      try {
        const parsed = JSON.parse(result.data);

        setData(parsed);
      } catch (e) {
        console.log(e);
      }
    });
  }, []);

  return (
    <>
      <div className="container" style={{ marginBottom: "30px" }}>
        <div>
          <div className="about-item">
            <img className="top" src={Logo} />
            <div className="up">
              <h5>
                <b className="break-spaces">
                  {(i18n.language === "nl"
                    ? data.title2NL
                    : i18n.language === "en"
                    ? data.title2EN
                    : data.title2FR) || t("about.about2")}
                </b>
              </h5>
              <div className="break-spaces">
                {(i18n.language === "nl"
                  ? data.text2NL
                  : i18n.language === "en"
                  ? data.text2EN
                  : data.text2FR) ||
                  `${t("about.text3")}\n\n${t("about.text4")}`}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <div className="about-item">
            <img className="top" src={Bloomup} />
            <div className="up">
              <h5>
                <b className="break-spaces">{t("about.about4")}</b>
              </h5>
              <div className="break-spaces">
                {`${t("about.text8")}\n\n${t("about.text9")}\n\n${t(
                  "about.text10"
                )}`}
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <div className="about-item">
            <img className="top" src={iCGT} />
            <div>
              <h5>
                <b className="break-spaces">
                  {(i18n.language === "nl"
                    ? data.title3NL
                    : i18n.language === "en"
                    ? data.title3EN
                    : data.title3FR) || t("about.about3")}
                </b>
              </h5>
              <div className="break-spaces">
                {(i18n.language === "nl"
                  ? data.text3NL
                  : i18n.language === "en"
                  ? data.text3EN
                  : data.text3FR) ||
                  `${t("about.text5")}\n${t("about.text6")}\n${t(
                    "about.text7"
                  )}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer authenticated={authenticated} />
    </>
  );
}
