import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "./img/logo.jpg";
import { TranslationResponseType } from "./i18n";

export function TimeReport({
  name,
  result,
  date,
  translationResponse,
}: {
  name: string;
  result: string;
  date: string;
  translationResponse: TranslationResponseType;
}) {
  const { t } = translationResponse;

  const styles = StyleSheet.create({
    page: {
      display: "flex",
      flexDirection: "column",
    },
    image: {
      width: "100px",
      height: "200px",
      objectFit: "contain",
      position: "absolute",
      top: "-80px",
      left: "10px",
    },
    generalView: {
      padding: "70px",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    titleWrapper: {
      borderBottom: "3px",
      borderColor: "#FFBA49",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      marginTop: "20px",
      marginBottom: "20px",
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    date: {
      marginLeft: "auto",
      fontSize: "13px",
      fontWeight: "bold",
    },
    line: {
      borderBottom: "1px",
      borderColor: "black",
      marginBottom: "5px",
      marginTop: "5px",
    },
    imageText: {
      width: "200px",
      objectFit: "contain",
    },
    bottomLine: {
      marginTop: "auto",
      borderBottom: "3px",
      borderColor: "#FFBA49",
    },
    normalContainer: {
      display: "flex",
      flexDirection: "row",
    },
    mlAuto: {
      marginLeft: "auto",
    },
    normalFont: {
      fontSize: "12px",
    },
    margin: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  });

  return (
    <Document>
      <Page style={styles.page} orientation={"portrait"}>
        <Image style={styles.image} src={Logo} />
        <View style={styles.generalView}>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{t("times.title")}</Text>
          </View>
          <View>
            <View style={[styles.normalContainer, styles.normalFont]}>
              <Text>{name}</Text>
              <Text style={styles.mlAuto}>{date}</Text>
            </View>
            <Text style={[styles.normalFont, styles.margin]}>
              {t("times.spend")}
            </Text>
            <Image style={styles.imageText} src={result} />
          </View>
          <View style={styles.bottomLine} />
        </View>
      </Page>
    </Document>
  );
}
