import { API_ENDPOINT, getHeaders } from "./AuthApi";
import { QuarterAndYear } from "../QuarterlyReport/QuarterAndYear";
import { quarterAndYearToFilterQueryString } from "../Utils";
import { Quarter } from "../QuarterlyReport/Quarter";
import { Lesson } from "./LessonsApi";
import { Module } from "./ModulesApi";

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  admin: boolean;
  newsletter: boolean;
  language: string;
  twoFactorAuthEnabled: boolean;
  isHr: boolean;
  createdAt: string;
};

export type ModuleProgress = {
  id: string;
  userId: string;
  finished: string;
  moduleId: string;
  subjectId: string;
  lessonId: string;
};

export type ModuleExerciseResult = {
  id: string;
  lessonId: string;
  key: string;
  result: string;
};

export type Sessions = {
  sessionsPerDay: {
    day: string;
    sessions: number;
  }[];
};

export type QuarterTime = {
  year: number;
  quarter: Quarter;
  tests: number;
  podcasts: number;
  modules: number;
};

export async function getCurrentUser(): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/user`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (result.token) {
    localStorage.setItem("token", result.token);
  }

  return result.user;
}

export async function getUser(userId: string): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users/${userId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result.user;
}

export async function deleteUser(userId: string): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users/${userId}`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result.user;
}

export async function deleteCurrentUser(): Promise<void> {
  await fetch(`${API_ENDPOINT}/users/me`, {
    method: "DELETE",
    headers: getHeaders(),
  }).then((r) => r.json());
}

export async function updateUser(
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
  language: string
): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users/${userId}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      id: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      language: language,
    }),
  }).then((r) => r.json());

  return result.user;
}

export async function updateCurrentUser(
  firstName: string,
  lastName: string,
  newsletter: boolean,
  language: string
): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/user`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      newsletter: newsletter,
      language: language,
    }),
  }).then((r) => r.json());

  return result.user;
}

export async function createUser(
  firstName: string,
  lastName: string,
  email: string,
  language: string
): Promise<User> {
  const result = await fetch(`${API_ENDPOINT}/users`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      email: email,
      language: language,
    }),
  }).then((r) => r.json());

  return result.user;
}

export async function addConnectedBusiness(
  businessId: string,
  userId: string
): Promise<void> {
  await fetch(`${API_ENDPOINT}/businesses/connect`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      userId: userId,
      businessId: businessId,
    }),
  }).then((r) => r.json());
}

export async function deleteConnectedBusiness(
  businessId: string,
  userId: string
): Promise<void> {
  await fetch(`${API_ENDPOINT}/businesses/connect`, {
    method: "DELETE",
    headers: getHeaders(),
    body: JSON.stringify({
      userId: userId,
      businessId: businessId,
    }),
  }).then((r) => r.json());
}

export async function getModuleExerciseResult(
  lessonId: string
): Promise<ModuleExerciseResult[]> {
  const result = await fetch(`${API_ENDPOINT}/users/exercises/${lessonId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.results) {
    return [];
  }

  return result.results;
}

export async function addModuleExerciseResult(
  lessonId: string,
  key: string,
  result: string
): Promise<ModuleExerciseResult> {
  const response = await fetch(`${API_ENDPOINT}/users/exercises`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      key: key,
      result: result,
      lessonId: lessonId,
    }),
  }).then((r) => r.json());

  return response;
}

export async function getModuleProgress(
  moduleId: string
): Promise<ModuleProgress[]> {
  const result = await fetch(`${API_ENDPOINT}/users/progress/${moduleId}`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.progress) {
    return [];
  }

  return result.progress;
}

export async function getModuleProgressForUser(
  moduleId: string,
  userId: string
): Promise<ModuleProgress[]> {
  const result = await fetch(
    `${API_ENDPOINT}/users/${userId}/progress/${moduleId}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((r) => r.json());

  if (!result.progress) {
    return [];
  }

  return result.progress;
}

export async function getModuleCount(): Promise<Record<string, number>> {
  const result = await fetch(`${API_ENDPOINT}/users/progress/count`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.count) {
    return {};
  }

  return result.count;
}

export async function getTotalTimes(): Promise<{
  tests: number;
  podcasts: number;
  modules: number;
}> {
  return await fetch(`${API_ENDPOINT}/users/times`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());
}

export async function getTotalTimesPerQuarter(): Promise<QuarterTime[]> {
  const response = await fetch(`${API_ENDPOINT}/users/times-per-quarter`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());
  return response.timesPerQuarter;
}

export async function getRecentVisited(): Promise<string[]> {
  const result = await fetch(`${API_ENDPOINT}/users/latest-visit`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.visited) {
    return [];
  }

  return result.visited;
}

export async function addModuleTime(
  moduleId: string,
  spend: number
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/time`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      moduleId: moduleId,
      spend: spend,
    }),
  }).then((r) => r.json());

  return result.ok;
}

export async function getUserPodcastTimes(): Promise<
  { id: string; time: number }[]
> {
  const result = await fetch(`${API_ENDPOINT}/users/podcast-time`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.times) {
    return [];
  }

  return result.times;
}

export async function addPodcastTime(
  podcastId: string,
  spend: number
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/podcast-time`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      podcastId: podcastId,
      spend: spend,
    }),
  }).then((r) => r.json());

  return result.ok;
}

export async function addTestTime(
  testId: string,
  spend: number
): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/test-time`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      testId: testId,
      spend: spend,
    }),
  }).then((r) => r.json());

  return result.ok;
}

export async function addModuleProgress(
  moduleId: string,
  subjectId: string,
  lessonId: string
): Promise<ModuleProgress> {
  const result = await fetch(`${API_ENDPOINT}/users/progress`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      moduleId: moduleId,
      subjectId: subjectId,
      lessonId: lessonId,
    }),
  }).then((r) => r.json());

  return result;
}

export async function getCheckoutUrl(
  moduleId: string,
  discount: string | undefined
): Promise<{ url: string }> {
  const result = await fetch(`${API_ENDPOINT}/checkout`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ moduleId: moduleId, discount: discount }),
  }).then((r) => r.json());

  return result;
}

export async function listUsers(): Promise<User[]> {
  const result = await fetch(`${API_ENDPOINT}/users`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result.users;
}

export async function getAmountOfUsers(): Promise<number> {
  const result = await fetch(`${API_ENDPOINT}/users/amount`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  return result.amount;
}

export async function getUserToken(userId: string): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/${userId}/view`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (result.token) {
    localStorage.setItem("token", result.token);
    return true;
  }

  return false;
}

export async function addUserToModules(
  moduleId: string,
  userId: string
): Promise<void> {
  await fetch(`${API_ENDPOINT}/modules/user`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      userId: userId,
      moduleId: moduleId,
    }),
  }).then((r) => r.json());
}

export async function getSessions(
  businessId: string,
  quarterAndYear?: QuarterAndYear
): Promise<Sessions> {
  const filterQueryString = quarterAndYear
    ? quarterAndYearToFilterQueryString(
        quarterAndYear.quarter,
        quarterAndYear.year
      )
    : "";

  const result = await fetch(
    `${API_ENDPOINT}/businesses/${businessId}/users/sessions?${filterQueryString}`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((r) => r.json());

  return result;
}

export async function storeSession(userId: string): Promise<void> {
  await fetch(`${API_ENDPOINT}/users/session`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      userId: userId,
    }),
  }).then((r) => r.json());
}

export async function listLessonsAndModulesWithExercises(): Promise<{
  lessons: Lesson[];
  modules: Module[];
}> {
  const result = await fetch(
    `${API_ENDPOINT}/users/exercises/lessons-and-modules`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  ).then((r) => r.json());

  if (!result.lessons || !result.modules) {
    return { lessons: [], modules: [] };
  }

  return result;
}

export async function getHasSkills(): Promise<boolean> {
  const result = await fetch(`${API_ENDPOINT}/users/has_skills`, {
    method: "GET",
    headers: getHeaders(),
  }).then((r) => r.json());

  if (!result.hasSkills) {
    return false;
  }

  return true;
}
