import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import "./Table.css";
import { SortDirection } from "ag-grid-community/dist/lib/entities/colDef";

export type RowData<T1, T2, T3, T4> = {
  col1Value: T1;
  col2Value: T2;
  buttonColValue: T3;
  type: T4;
}[];

type ColData<T> = {
  headerName: string;
  cellRenderer?: ({ value }: { value: T }) => string;
};

/*
 * Component used to display a table with two columns and a button column.
 * It takes 3 generic types:
 * - T1: the type of the first column
 * - T2: the type of the second column
 * - T3: the type of the button column
 * - T4: the type of the type attribute
 */
export function Table<T1, T2, T3, T4>({
  rowData,
  col1Data,
  col2Data,
  buttonIcon,
  onButtonClick,
}: {
  rowData: RowData<T1, T2, T3, T4>;
  col1Data: ColData<T1>;
  col2Data: ColData<T2>;
  buttonIcon: IconDefinition;
  onButtonClick: (value: T3, type: T4) => void;
}) {
  const { t } = useTranslation("user-dashboard");

  function TableButtonRenderer({
    value,
    getType,
  }: {
    value: T3;
    getType: (value: T3) => T4;
  }) {
    return (
      <FontAwesomeIcon
        className="table-button"
        icon={buttonIcon}
        onClick={() => onButtonClick(value, getType(value))}
      />
    );
  }

  const columnDefs = [
    {
      headerName: col1Data.headerName,
      cellRenderer: col1Data.cellRenderer || undefined,
      field: "col1Value",
      cellClass: "text-cell",
      filter: true,
      sortable: true,
      flex: 2,
    },
    {
      headerName: col2Data.headerName,
      cellRenderer: col2Data.cellRenderer || undefined,
      field: "col2Value",
      cellClass: "text-cell",
      filter: true,
      sortable: true,
      sort: "desc" as SortDirection,
      flex: 3,
    },
    {
      headerName: "",
      field: "buttonColValue",
      cellRenderer: TableButtonRenderer,
      cellRendererParams: {
        getType: (id: T3) => {
          return rowData.find((r) => r.buttonColValue === id)?.type || "";
        },
      },
      cellClass: "button-cell",
      flex: 1,
    },
  ];

  return (
    <div className="table ag-theme-alpine">
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        gridOptions={{ rowHeight: 70 }}
        suppressMovableColumns
        onGridReady={(params: any) => params.api.sizeColumnsToFit()} // TODO use correct type instead of any
        overlayNoRowsTemplate={t("noData")}
      />
    </div>
  );
}
