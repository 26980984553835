import React, { useState } from "react";
import { Sidebar } from "../Sidebar";
import { useEffectOnce } from "react-use";
import { Redirect } from "react-router-dom";
import { FaPlus, FaPen } from "react-icons/fa";
import { getPodcasts, Podcast } from "../api/PodcastApi";

export function Podcasts() {
  const [podcasts, setPodcasts] = useState<Podcast[]>([]);
  const [addingPodcast, setAddingPodcast] = useState(false);
  const [editingPodcast, setEditingPodcast] = useState<Podcast | null>(null);
  const [filter, setFilter] = useState("");

  useEffectOnce(() => {
    getPodcasts().then((r) => {
      setPodcasts(r);
    });
  });

  if (addingPodcast) {
    return <Redirect to="/dashboard/podcasts/new" push />;
  }

  if (editingPodcast) {
    return <Redirect to={`/dashboard/podcasts/${editingPodcast.id}`} push />;
  }

  return (
    <Sidebar>
      <div className="container">
        <div className="flex">
          <h2>Podcasts</h2>
          <div
            className="button small ml-auto"
            onClick={() => {
              setAddingPodcast(true);
            }}
          >
            <FaPlus />
          </div>
        </div>

        <input
          className="input"
          type="text"
          placeholder={"Naam"}
          value={filter}
          onChange={(input) => {
            setFilter(input.target.value);
          }}
        />

        {podcasts.length === 0 ? (
          "Momenteel nog geen podcasts beschikbaar"
        ) : (
          <table className="table ">
            <thead>
              <tr>
                <th>Naam</th>
                <th>Beschrijving</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {podcasts
                .filter((b) =>
                  b.name.toLowerCase().includes(filter.toLowerCase())
                )
                .map((business) => {
                  return (
                    <tr key={business.id}>
                      <td>{business.name}</td>
                      <td>{business.description}</td>
                      <td>
                        <div className="flex">
                          <div
                            className="button small w-fit ml-5 yellow"
                            onClick={() => {
                              setEditingPodcast(business);
                            }}
                          >
                            <FaPen />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </Sidebar>
  );
}
