import React, { useEffect, useState } from "react";
import { getModuleExerciseResult } from "../api/UserApi";
import { useEffectOnce } from "react-use";
import { getListItems, ListItem } from "../api/ListApi";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { uniqueId } from "../uniqueId";

export function ItemSelectionResults({ match }: { match: string }) {
  const regex = /id=&quot;(.*)&quot; lesson_id=&quot;(.*)&quot;/gm;
  const result = regex.exec(match);
  const id = result && result[1];
  const lessonId = result && result[2];
  const [selected, setSelected] = useState<string[]>([]);
  const [items, setItems] = useState<ListItem[]>([]);

  const { i18n } = useTranslation("general");

  useEffectOnce(() => {
    if (id && lessonId) {
      getListItems(id).then((result) => {
        setItems(result);
      });
      getModuleExerciseResult(lessonId).then((result) => {
        const currentResult = result.find(
          (r) => r.key === `ItemSelection-${id}`
        );

        if (currentResult) {
          setSelected(JSON.parse(currentResult.result));
        }
      });
    }
  });

  useEffect(() => {
    setItems(_.shuffle(items));
  }, [i18n]);

  return (
    <div key={uniqueId()} className="flex wrap">
      {items.map((item, index) => {
        if (!selected.includes(item.id)) {
          return null;
        }

        return (
          <div key={index} className="selected-item">
            {i18n.language === "nl"
              ? item.nameNL
              : i18n.language === "en"
              ? item.nameEN
              : item.nameFR}
          </div>
        );
      })}
    </div>
  );
}
