import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

export function UserDashboardSidebarMobile({
  handleClick,
  routes,
}: {
  handleClick: () => void;
  routes: { url: string; name: string; icon: string }[];
}) {
  function unMountHamburger() {
    document.body.style.overflow = "auto";
  }

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => unMountHamburger();
  }, []);

  return (
    <div className="hamburger-menu">
      <RxCross2
        className="user-sidebar-cross mr-auto"
        size={30}
        onClick={handleClick}
      />
      {routes.map((item) => {
        return (
          <NavLink
            to={item.url}
            className="sidebar-item"
            key={item.url}
            onClick={handleClick}
          >
            <img src={item.icon} alt="icon" className="icon" />
            <div>{item.name}</div>
          </NavLink>
        );
      })}
    </div>
  );
}
