import React, { useRef } from "react";
import { BusinessStatistics } from "../api/BusinessApi";
import { Line } from "react-chartjs-2";
import TooltipIcon from "../img/tooltipIcon.svg";
import PremiumIcon from "../img/Premium.svg";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { ChartArea, ChartData } from "chart.js";

export function getUsersGraphData(
  stats: BusinessStatistics,
  gradient: CanvasGradient | string = "#FFBA4980"
): ChartData<"line", number[], number> {
  const datasets = [
    {
      label: "",
      data: stats.newUsersPerMonth.map((item, index) =>
        stats.newUsersPerMonth
          .slice(0, index + 1)
          .reduce((sum, item) => sum + item.count, 0)
      ),
      backgroundColor: gradient,
      borderColor: gradient,
      fill: "start",
    },
  ];

  const labels = stats ? stats.newUsersPerMonth.map((item) => item.month) : [];

  return {
    labels,
    datasets,
  };
}

export function UsersGraph({
  stats,
  premium = false,
}: {
  stats: BusinessStatistics;
  premium?: boolean;
}) {
  const chartRef = useRef<any>(null);
  const { t } = useTranslation("hr-dashboard");

  function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
    const gradient = ctx.createLinearGradient(
      area.left,
      area.bottom,
      area.right,
      area.top
    );

    gradient.addColorStop(0, "#ffba4980");
    gradient.addColorStop(1, "#fea17c80");

    return gradient;
  }

  const gradient = chartRef.current
    ? createGradient(chartRef.current.ctx, chartRef.current.chartArea)
    : "#ffba4980";

  const usersGraphData = getUsersGraphData(stats, gradient);
  const options = {
    aspectRatio: 1,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#000000",
        displayColors: false,
        bodyFont: {
          size: 12,
          weight: "700",
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x" as const,
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: "x" as const,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="graph">
      <div className={"graph-title flex items-center"}>
        {t("usersGraph.title")}
        <div
          className={"flex items-center"}
          data-tooltip-id="graph-explanation"
          data-tooltip-content={t("usersGraph.tooltipContent")}
        >
          <img className={"tooltip-icon"} src={TooltipIcon} alt={""} />
        </div>
        <Tooltip id={"graph-explanation"} className={"tooltip"} />
      </div>
      {!stats || stats.newUsersPerMonth.length <= 1 ? (
        <div className={"ml-5 mt-4 mb-4"}>{t("usersGraph.noDataMessage")}</div>
      ) : (
        <div className="graph-wrapper">
          <Line
            style={{ maxHeight: "420px" }}
            ref={chartRef}
            data={usersGraphData}
            options={options}
          />
        </div>
      )}
      {premium ? (
        <img className={"graph-premium"} src={PremiumIcon} alt={""} />
      ) : null}
    </div>
  );
}
