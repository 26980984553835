import { Sidebar } from "../Sidebar";
import React from "react";
import { BusinessForm } from "./BusinessForm";

export function CreateBusiness() {
  return (
    <Sidebar>
      <BusinessForm business={null} />
    </Sidebar>
  );
}
