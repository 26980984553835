import { getTokenExpiry } from "./getTokenExpiry";

export const getValidMindlabToken = () => {
  const mindlabToken = localStorage.getItem("token");
  const mindlabTokenExpired = getTokenExpiry(mindlabToken) * 1000 <= Date.now();

  if (mindlabTokenExpired) {
    localStorage.removeItem("token");
    return null;
  }
  return mindlabToken;
};
