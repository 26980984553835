import React, { Fragment, useEffect, useState } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import {
  acceptChallenge,
  alreadyAccepted,
  comment,
  FeedItem,
  getGroupCurrentChallenge,
  getPlayers,
  getSystemFeed,
  getUserFeed,
  listComments,
  postUserFeed,
  stopGroupGame,
} from "./api/GroupGameApi";
import { Challenge } from "./api/GameApi";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Dialog } from "./Dialog";

export function GameStarted() {
  const [post, setPost] = useState("");
  const [users, setUsers] = useState<string[]>([]);
  const [timeline, setTimeline] = useState<FeedItem[]>([]);
  const [currentChallenge, setCurrentChallenge] = useState<Challenge | null>(
    null
  );
  const [hasAccepted, setHasAccepted] = useState(false);
  const [invite, setInvite] = useState(false);
  const [comments, setComments] = useState<Record<string, FeedItem[]>>({});
  const [openComment, setOpenComment] = useState<string | null>(null);
  const [commentValue, setCommentValue] = useState("");
  const { t, i18n } = useTranslation("general");

  useEffect(() => {
    getPlayers().then((result) => {
      setUsers(result);
    });
    getGroupCurrentChallenge().then((result) => {
      setCurrentChallenge(result);
    });
    alreadyAccepted().then((result) => {
      setHasAccepted(result);
    });
    getSystemFeed().then((result) => {
      getUserFeed().then((r) => {
        setTimeline(
          result
            .concat(r)
            .sort((a, b) =>
              DateTime.fromISO(a.timestamp) < DateTime.fromISO(b.timestamp)
                ? 1
                : -1
            )
        );
      });
    });
  }, []);

  return (
    <div className="game-started">
      <div className="left">
        <h2>Andere gebruikers</h2>
        {users.map((item) => {
          return <div style={{ color: "white" }}>{item}</div>;
        })}
        <b
          className="pointer"
          onClick={() => {
            setInvite(true);
          }}
        >
          Nodig iemand uit
        </b>
      </div>
      <div className="middle">
        <div className="white-box">
          <input
            className="input"
            type="text"
            placeholder="Start een post"
            value={post}
            onChange={(e) => {
              setPost(e.target.value);
            }}
          />
          <div
            className="button w-fit"
            onClick={async () => {
              if (post.trim()) {
                await postUserFeed(post.trim());
              }
              window.location.href = "/game/group";
            }}
          >
            Plaatsen
          </div>
        </div>
        {timeline.map((item) => {
          return (
            <div className="white-box mt-4">
              <div>
                {item.name ? `${item.name} heeft gepost:` : null} {item.text}
              </div>
              {comments[item.id] ? (
                <div className="comment-box">
                  {comments[item.id].map((comment) => {
                    return <div>{comment.text}</div>;
                  })}
                </div>
              ) : (
                <div
                  className="pointer"
                  style={{ fontSize: "13px" }}
                  onClick={async () => {
                    const coms = await listComments(item.id);
                    const copy = { ...comments };
                    copy[item.id] = coms;
                    setComments(copy);
                  }}
                >
                  <b>Toon comments</b>
                </div>
              )}
              <div
                className="flex items-center mt-4 pointer"
                onClick={() => {
                  setOpenComment(item.id);
                }}
              >
                {openComment === item.id ? (
                  <Fragment>
                    <input
                      type="text"
                      className="input"
                      value={commentValue}
                      onChange={(e) => {
                        setCommentValue(e.target.value);
                      }}
                    />
                    <div
                      className="button w-fit ml-5"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await comment(commentValue, item.id);
                        window.location.href = "/game/group";
                      }}
                    >
                      Comment
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <HiOutlineChatBubbleBottomCenterText />{" "}
                    <div className="ml-5 mr-5">Comment</div>
                  </Fragment>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="right flex flex-column items-center">
        <h2>Jouw uitdaging:</h2>
        <div>
          {i18n.language === "nl" &&
            currentChallenge &&
            currentChallenge.nameNL}
          {i18n.language === "en" &&
            currentChallenge &&
            currentChallenge.nameEN}
          {i18n.language === "fr" &&
            currentChallenge &&
            currentChallenge.nameFR}
        </div>
        {hasAccepted ? null : (
          <div className="flex justify-center">
            <div
              className="accept-button mr-5"
              onClick={async () => {
                await acceptChallenge();
                setHasAccepted(true);
                window.location.href = "/game/group";
              }}
            >
              <FaCheck />
            </div>
            <div
              className="deny-button"
              onClick={async () => {
                await acceptChallenge();
                setHasAccepted(true);
                window.location.href = "/game/group";
              }}
            >
              <FaTimes />
            </div>
          </div>
        )}
        <b className="mt-4">Wil je het spel stoppen?</b>
        <div
          className="button pink w-fit"
          onClick={async () => {
            await stopGroupGame();
            window.location.href = "/game";
          }}
        >
          Stoppen
        </div>
      </div>
      <Dialog
        isOpen={invite}
        onRequestClose={() => {
          setInvite(false);
        }}
        title={"Gebruiker uitnodigen"}
        size={"small"}
        padding={true}
      >
        <div>
          <label>Email</label>
          <input className="input" type="email" />
          <div className="button mt-4 w-fit">Versturen</div>
        </div>
      </Dialog>
    </div>
  );
}
